<template>
  <a
    class="cookie__pref"
    href="#"
    onclick="window.displayPreferenceModal();return false;"
    id="termly-consent-preferences"
    ><img
      class="cookie__img"
      src="../assets/images/office/cookie-icon.png"
      alt=""
  /></a>
  <div id="top" class="section section__matching">
    <div class="filter"></div>
    <NavVue></NavVue>
    <div class="work__content work__content-first">
      <TitleVue
        class="title-lower work__tofix"
        content="Unique community matching"
      ></TitleVue>
      <p class="community__text text community__text-tofix">
        This is what makes us the best! Workdistrict is more than just a place
        to work. It is a place to spend time, enjoy and meet others, bring up
        new connections and network. Therefore, we developed a unique matching
        software to bring you in contact with other Workdistrict members. But
        that’s not all: We evaluate which contact would bring the most effort
        for you and your business or who is the most interesting for you
        according to your own set parameters.
      </p>
      <ButtonVue
        @click="redirectToContactPage"
        class="footer__btn community__btn-tofix"
        text="Get in touch today!"
      ></ButtonVue>
    </div>
  </div>
  <section class="section__work">
    <div class="work__content">
      <TitleVue
        class="title-lower community__title"
        content="How does it work?"
      ></TitleVue>
      <p class="community__text text">
        As soon as you sign up to become a member of Workdistrict, you will have
        the possibility to submit to us certain parameters for people or
        companies that you are interested in. According to your priorizations of
        topics, industries and much more, we select out of the wide range of
        Workdistrict members the perfect fit for you and your business.
      </p>
      <p class="community__text text">
        That way, besides an amazing place to work, we can offer you an amazing
        community with beneficial connections for you and your business. Because
        a network brings real value. That is why we encourage network building
        and community exchange.
      </p>
    </div>
  </section>
  <section class="section__more">
    <div class="filter"></div>
    <div class="work__content">
      <TitleVue
        class="title-lower community__title"
        content="Even more community"
      ></TitleVue>
      <p class="community__text text">
        As it is part of our mission to encourage networking within the
        Workdistrict community, we don’t only offer our unique and individual
        matching between tenants. Workdistrict also takes care of a lot of
        community-driving activities.
      </p>
      <p class="community__text text">
        Firstly, as our input for your focus throughout the week, we will give
        you a small bonus every week. This can be tasty waffles, fresh smoothies
        or fun cocktails, be surprised.
      </p>
      <p class="community__text text">
        Moreover, we as workspace provider organize internal community events
        specially and exclusively for you, our tenants. Here, you not only have
        great possibilities to learn and experience but also to connect with
        each other, find new business partners, colleagues or friends.
      </p>
    </div>
  </section>
  <FooterVue></FooterVue>
</template>

<script>
import NavVue from "../components/NavVue.vue";
import FooterVue from "../components/FooterVue.vue";
import TitleVue from "../components/TitleVue.vue";
import ButtonVue from "../components/ButtonVue.vue";

export default {
  name: "CommunityPage",
  components: {
    NavVue,
    FooterVue,
    TitleVue,
    ButtonVue,
  },
  methods: {
    redirectToContactPage() {
      this.$router.push("/Contact-Page");
    },
  },
  mounted() {
    document.body.style.overflowX = "hidden";
    document.getElementById("top").scrollIntoView();
  },
};
</script>

<style>
.section__matching {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/office/workdistrict-coworking-living-room-big.webp");
  background-position: top 20%;
  background-size: cover;
  backdrop-filter: brightness(0.4);
  padding: 2rem 8rem;
  min-height: auto;
  z-index: 499;
}

.section__work {
  width: 100%;
  background-color: var(--color-timber);
  padding: 0rem 8rem;
  max-height: auto;
  padding: 4rem 0 5rem 0;
  display: flex;
  flex-direction: column;
  z-index: 499;
  position: relative;
}

.section__more {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/office/workdistrict-coworking-community.webp");
  background-size: cover;
  backdrop-filter: brightness(0.4);
  padding: 2rem 8rem;
  padding: 2rem 0 2rem 0;
  min-height: auto;
  position: relative;
}

.community__text {
  width: 55%;
}

.work__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  height: 100%;
  text-align: center;
}

.work__tofix {
  margin-top: -10rem;
}

/*********/
/* MEDIA */
/*********/

@media (min-width: 1200px) {
  .community__text {
    width: 47%;
  }
}

@media (max-width: 1000px) {
  .community__text {
    width: 80%;
  }
}

@media (max-width: 750px) {
  .community__text {
    width: 90%;
    text-align: left;
  }

  .community__btn-tofix {
    align-self: flex-start;
    margin-left: 3rem !important;
  }

  .work__tofix {
    text-align: left;
    align-self: flex-start;
    padding-left: 3rem;
  }

  .work__content-first {
    padding-left: 1rem !important;
  }

  .community__title {
    align-self: flex-start;
  }

  .section__more {
    height: min-content;
    padding-top: 3rem;
    padding-left: 4rem;
    padding-bottom: 4rem;
  }

  .section__work {
    padding-top: 3rem;
    padding-left: 4rem;
  }

  .work__content {
    align-items: flex-start;
  }

  .work__content-first {
    top: 15%;
    left: 0;
    padding: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .section__matching {
    height: 70rem;
    background-attachment: initial;
  }

  .section__more {
    background-attachment: initial;
    background-position: center;
  }

  .community__text-tofix {
    padding-left: 3rem !important;
  }
}

@media (min-width: 1920px) {
  .section__more {
    background-image: url("../assets/images/office/workdistrict-coworking-community-big.webp");
  }
}

@media (max-width: 650px) {
  .work__tofix {
    padding-left: 2.4rem !important;
  }
}

@media (max-width: 520x) {
  .community__btn-tofix {
    align-self: flex-start;
  }

  .community__btn-tofix {
    transform: translateX(-2rem);
  }
}

@media (max-width: 430x) {
  .work__tofix {
    padding-left: 2.2rem !important;
  }
}

@media (max-width: 430x) {
  .work__tofix {
    padding-left: 2rem !important;
  }
}
</style>
