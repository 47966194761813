<template>
  <a
    class="cookie__pref"
    href="#"
    onclick="window.displayPreferenceModal();return false;"
    id="termly-consent-preferences"
    ><img
      class="cookie__img"
      src="../assets/images/office/cookie-icon.png"
      alt=""
  /></a>
  <section id="top" class="section__office">
    <NavVue></NavVue>
    <div class="office__box">
      <div class="office__photo-box">
        <div class="office__photo-row">
          <img
            v-on:mouseover="isHovering1"
            v-on:mouseleave="isHovering1"
            v-bind:class="{ grayscale__helper: isHover1 }"
            class="office__photo grayscale"
            src="..\assets\images\office\workdistrict-coworking-office-women.webp"
            alt="Workdistrict woman painting decoration"
          />
          <img
            v-on:mouseover="isHovering2"
            v-on:mouseleave="isHovering2"
            v-bind:class="{ grayscale__helper: isHover2 }"
            class="office__photo grayscale"
            src="..\assets\images\office\workdistrict-coworking-office-asia.webp"
            alt="Workdistrict asian wall decoration"
          />
        </div>
        <div class="office__photo-row">
          <img
            v-on:mouseover="isHovering3"
            v-on:mouseleave="isHovering3"
            v-bind:class="{ grayscale__helper: isHover3 }"
            class="office__photo grayscale"
            src="..\assets\images\office\workdistrict-coworking-office-painting.webp"
            alt="Workdistrict wall painting decoration"
          />
          <img
            v-on:mouseover="isHovering4"
            v-on:mouseleave="isHovering4"
            v-bind:class="{ grayscale__helper: isHover4 }"
            class="office__photo grayscale"
            src="..\assets\images\office\workdistrict-coworking-office-monkey.webp"
            alt="Workdistrict luxurious monkey decoration"
          />
        </div>
      </div>
      <div class="office__content">
        <h1 class="office__title">One price - no hidden costs</h1>
        <p class="text-right office__text">
          With Workdistrict, you have the flexibility to create the workspace
          that just fits your needs. You can rather choose to book your own
          private office for you or your team or if you want to sit in a shared
          environment and just book a table space.
        </p>
        <p class="text-right office__text">
          However you decide, in the base price included are the free use of our
          kitchens and bars including coffee, tea and snacks as well as the use
          of all of Workdistrict’s other amenities. Moreover, you will get a
          certain amount of hours you can spend on reservations of meeting rooms
          to assure you have a place when needed. And last but not least, of
          course you have access to the weekly happenings, which we offer to
          keep your focus.
        </p>
        <ButtonVue
          @click="redirectToContactPage"
          class="align__end office__btn"
          text="Send an inquiry"
        ></ButtonVue>
      </div>
    </div>
  </section>
  <section class="section__offer">
    <h1 class="offer__tofix offer__title">Our offers</h1>
    <p class="offer__tofix text">
      The perfect surrounding for freelancers, creative minds and
      business-starters: You just book a table in one of our shared spaces and
      sit together with various, interesting people, just like you. You can also
      book a range of tables for your flexible team or, if you want more
      privacy, a whole office for yourself or your team. With our team suites we
      offer solutions on enterprise level, you and your team are completely
      separated from the rest of the office spaces, you have your personal
      access and even your own meeting rooms and special amenities. If you are
      only in the city for one day, maybe our daily pass would be something for
      you to spend this one day as efficient as possible. If you are returning
      from time to time or you just need an office once in a while, have a look
      at our multi passes and save money on every visit!
    </p>
    <p class="offer__tofix text">
      Our open design with luxurious furniture and interiors as well as our
      usage of glass walls make the whole space very open and unique which not
      only makes you feel better going to work but also enhance creativity and
      concentration. Because working in a nice surrounding is more productive.
    </p>

    <!-- CARDS -->
    <div class="cards__container">
      <div class="cards__item">
        <div
          @click="navigateToContact('singleDesk')"
          v-on:mouseover="isFiltering1"
          v-on:mouseleave="isFiltering1"
          class="cards__content cards__content1"
        >
          <div class="cards__content_helper"></div>
          <div
            v-bind:class="{
              cards__filter: !isFiltered1,
              cards__filter_helper: isFiltered1,
            }"
          ></div>
          <h3 class="cards__title">Single Desk</h3>
          <p class="cards__text">
            Book a single desk and enjoy the flair of a coworking membership at
            Workdistrict. Here, you can sit together with industry peers,
            colleagues and friends to collaborate, discuss, and develop great
            ideas. Building new connections has never been easier!
          </p>
          <span class="cards__btn">Send an inquiry</span>
        </div>
      </div>
      <div class="cards__item">
        <div
          @click="navigateToContact('privateOffice')"
          v-on:mouseover="isFiltering2"
          v-on:mouseleave="isFiltering2"
          class="cards__content cards__content2"
        >
          <div class="cards__content_helper"></div>
          <div
            v-bind:class="{
              cards__filter: !isFiltered2,
              cards__filter_helper: isFiltered2,
            }"
          ></div>
          <h3 class="cards__title">Private Office</h3>
          <p class="cards__text">
            You need some space for yourself or your team? Then, a private
            office is the perfect solution for you! From 2-5 desks, we have
            offices in flexible sizes that exactly fit your needs. Moreover, you
            can lock your office to keep your sensitive data safe and just leave
            everything for the next day.
          </p>
          <span class="cards__btn">Send an inquiry</span>
        </div>
      </div>
      <div @click="navigateToContact('teamSuite')" class="cards__item">
        <div
          v-on:mouseover="isFiltering3"
          v-on:mouseleave="isFiltering3"
          class="cards__content cards__content3"
        >
          <div class="cards__content_helper"></div>
          <div
            v-bind:class="{
              'cards__filter-3': !isFiltered3,
              cards__filter_helper_suite: isFiltered3,
            }"
          ></div>
          <h3 class="cards__title">Team Suite</h3>
          <p class="cards__text">
            You have a bigger team to accommodate? No problem in the
            Workdistrict because we offer exclusive team suites. In other words,
            a collection of offices with community areas and meeting rooms
            privately for your company. Get your own access and keep your team
            together!
          </p>
          <span class="cards__btn">Send an inquiry</span>
        </div>
      </div>
      <div @click="navigateToContact('dailyPass')" class="cards__item">
        <div
          v-on:mouseover="isFiltering4"
          v-on:mouseleave="isFiltering4"
          class="cards__content cards__content4"
        >
          <div class="cards__content_helper"></div>
          <div
            v-bind:class="{
              cards__filter: !isFiltered4,
              cards__filter_helperbis: isFiltered4,
            }"
          ></div>
          <h3 class="cards__title">Daily Pass</h3>
          <p class="cards__text">
            You only need a space for one day? For example when you have an
            in-person meeting with your clients or need an office while stopping
            by Amsterdam? No problem, come to the Workdistrict and get your
            daily pass, allowing you all-access for one day and surprise with an
            outstanding office in one of the best locations in the city centre
            of Amsterdam.
          </p>
          <span class="cards__btn">Send an inquiry</span>
        </div>
      </div>
      <div @click="navigateToContact('multiPass')" class="cards__item">
        <div
          v-on:mouseover="isFiltering5"
          v-on:mouseleave="isFiltering5"
          class="cards__content cards__content5"
        >
          <div class="cards__content_helper"></div>
          <div
            v-bind:class="{
              cards__filter: !isFiltered5,
              cards__filter_helperbisbis: isFiltered5,
            }"
          ></div>
          <h3 class="cards__title">Multi Pass</h3>
          <p class="cards__text">
            You are a hybrid worker and booking a fixed space is not suitable
            with the way you work but still you need a workspace from time to
            time? Save money with our Multi-pass, allowing you 10 times
            discount-access to all our facilities. You can keep your card and
            just re-load it when your balance is all used.
          </p>
          <span class="cards__btn">Send an inquiry</span>
        </div>
      </div>
    </div>
    <!-- CARDS -->

    <h1 class="offer__tofix request__title">
      Do you want to visit the Workdistrict? Request a tour:
    </h1>
    <div class="offer__tofix offer__cta-box">
      <form
        class="form__helper-desktop office__form"
        id="bookatour"
        method="post"
        action="https://hockus.com/contactform_handling/bookatour_2.php"
      >
        <input
          ref="book"
          type="text"
          name="datetime"
          id="datetime"
          class="formfield form__date"
          placeholder="Choose a date and time"
          @focus="changeType"
          required
        />
        <input
          type="submit"
          id="submit"
          class="form__btn btn__modern"
          value="Book a tour!"
        />
        <input
          type="text"
          name="name"
          id="name"
          class="formfield conditionalhide form__name"
          placeholder="What is your name?"
          data-show-if="datetime"
          required
        />
        <input
          type="email"
          name="email"
          id="email"
          class="formfield conditionalhide form__email"
          placeholder="Your email address?"
          required
        />
        <input
          type="tel"
          name="tel"
          id="tel"
          class="formfield conditionalhide form__tel"
          placeholder="Tel incl. country code"
          required
        />
      </form>

      <form
        class="form__helper-mobile office__cta-tofix"
        id="bookatour"
        method="post"
        action="https://hockus.com/contactform_handling/bookatour_2.php"
      >
        <input
          type="datetime-local"
          name="datetime"
          id="datetime-test"
          class="formfield form__date"
          placeholder="Choose a date and time"
          required
        />
        <input
          type="submit"
          id="submit-test"
          class="form__btn btn__modern"
          value="Book a tour!"
        />
        <input
          type="text"
          name="name"
          id="name"
          class="formfield conditionalhide form__name"
          placeholder="Your name?"
          data-show-if="datetime"
          required
        />
        <input
          type="email"
          name="email"
          id="email"
          class="formfield conditionalhide form__email"
          placeholder="Your email?"
          required
        />
        <input
          type="tel"
          name="tel"
          id="tel"
          class="formfield conditionalhide form__tel"
          placeholder="Tel + country code"
          required
        />
      </form>
    </div>
  </section>
  <FooterVue></FooterVue>
</template>

<script>
import NavVue from "../components/NavVue.vue";
import FooterVue from "../components/FooterVue.vue";
import ButtonVue from "../components/ButtonVue.vue";

export default {
  name: "OfficePage",
  data() {
    return {
      isHover1: false,
      isHover2: false,
      isHover3: false,
      isHover4: false,
      isFiltered1: false,
      isFiltered2: false,
      isFiltered3: false,
      isFiltered4: false,
      isFiltered5: false,
    };
  },
  components: {
    NavVue,
    FooterVue,
    ButtonVue,
  },
  methods: {
    isHovering1() {
      this.isHover1 == true ? (this.isHover1 = false) : (this.isHover1 = true);
    },
    isHovering2() {
      this.isHover2 == true ? (this.isHover2 = false) : (this.isHover2 = true);
    },
    isHovering3() {
      this.isHover3 == true ? (this.isHover3 = false) : (this.isHover3 = true);
    },
    isHovering4() {
      this.isHover4 == true ? (this.isHover4 = false) : (this.isHover4 = true);
    },
    isFiltering1() {
      this.isFiltered1 == true
        ? (this.isFiltered1 = false)
        : (this.isFiltered1 = true);
    },
    isFiltering2() {
      this.isFiltered2 == true
        ? (this.isFiltered2 = false)
        : (this.isFiltered2 = true);
    },
    isFiltering3() {
      this.isFiltered3 == true
        ? (this.isFiltered3 = false)
        : (this.isFiltered3 = true);
    },
    isFiltering4() {
      this.isFiltered4 == true
        ? (this.isFiltered4 = false)
        : (this.isFiltered4 = true);
    },
    isFiltering5() {
      this.isFiltered5 == true
        ? (this.isFiltered5 = false)
        : (this.isFiltered5 = true);
    },
    redirectToContactPage() {
      this.$router.push("/Contact-Page");
    },
    navigateToContact(preSelectedOption) {
      this.$router.push({
        name: "Contact-Page",
        query: { preSelected: preSelectedOption },
      });
    },
    changeType() {
      this.$refs.book.type = "datetime-local";
    },
  },
  mounted() {
    document.body.style.overflowX = "hidden";
    document.getElementById("top").scrollIntoView();
  },
};
</script>

<style>
.section__office {
  width: 100%;
  height: auto;
  background-color: var(--color-bg);
  padding: 2rem 8rem 6rem 8rem;
  position: relative;
}

.office__box {
  margin-top: 10rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.office__content {
  display: flex;
  flex-direction: column;
  width: 55%;
  gap: 3rem;
}

.office__title {
  font-size: 3.6rem;
  align-self: flex-end;
}

.office__photo-box {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.office__photo-row {
  display: flex;
  gap: 1.2rem;
}

.office__photo {
  max-width: 18rem;
}

.section__offer {
  background-color: var(--color-timber);
  padding: 5rem 0rem;
  padding-bottom: 10rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.offer__title {
  font-size: 3.8rem;
}

.request__title {
  font-size: 3.8rem;
  margin-bottom: 5rem;
  line-height: 1.1;
  width: 90%;
}

.offer__img-box {
  display: flex;
  width: 100%;
}

.offer__img-box > * {
  cursor: pointer;
}

.offer__cta-box {
  display: flex;
  align-items: center;
}

.offer__date {
  font-size: 2.7rem;
  border-radius: 6px;
}

.offer__photo-click {
  font-size: 1.4rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
  padding: 2rem 0 2.4rem 4rem;
}

.grayscale {
  filter: grayscale(0.8);
}

.grayscale__helper {
  filter: grayscale(0.01);
}

.offer__tofix {
  padding: 0rem 10rem;
}

.offer__photo-40 {
  width: 40%;
}

.offer__photo-15 {
  width: 15%;
}

.cards__container {
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-top: 2rem;
}

.cards__content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  padding-top: 33.4rem;
  transition: all 0.5s;
  height: 100%;
  position: relative;
  backdrop-filter: brightness(0.1);
  z-index: 5;
  cursor: pointer;
}

.cards__content:hover {
  padding-top: 4rem;
}

.cards__item {
  height: 40rem;
  position: relative;
  width: 20%;
}

.cards__title {
  font-size: 3rem;
  font-weight: 500;
}

.cards__text {
  font-size: 1.4rem;
  text-align: center;
}

.cards__btn {
  background-color: var(--color-magenta);
  padding: 0.4rem 1.2rem;
  color: white;
  font-size: 2rem;
  border-radius: 4px;
}

.cards__content_helper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 500;
  background-color: transparent;
}

.cards__filter {
  position: absolute;
  top: 0;
  left: 0;
  height: 105%;
  width: 105%;
  backdrop-filter: brightness(0.45);
  z-index: -1;
}

.cards__filter-3 {
  position: absolute;
  top: 0;
  left: 0;
  height: 105%;
  width: 105%;
  backdrop-filter: brightness(0.6);
  z-index: -1;
}

.cards__filter_helper {
  position: absolute;
  top: 0;
  left: 0;
  height: 105%;
  width: 105%;
  backdrop-filter: brightness(0.2);
  z-index: -1;
}

.cards__filter_helper_suite {
  position: absolute;
  top: 0;
  left: 0;
  height: 105%;
  width: 105%;
  backdrop-filter: brightness(0.3);
  z-index: -1;
}

.cards__filter_helperbis {
  position: absolute;
  top: 0;
  left: 0;
  height: 105%;
  width: 105%;
  backdrop-filter: brightness(0.2);
  z-index: -1;
}

.cards__filter_helperbisbis {
  position: absolute;
  top: 0;
  left: 0;
  height: 105%;
  width: 105%;
  backdrop-filter: brightness(0.2);
  z-index: -1;
}

.cards__content1 {
  background-image: url("../assets/images/cards/workdistrict-coworking-single-desk.webp");
  background-size: cover;
  background-position: bottom;
  backdrop-filter: brightness(0.5);
}

.cards__content2 {
  background-image: url("../assets/images/cards/workdistrict-coworking-private-office.webp");
  background-size: cover;
  backdrop-filter: brightness(0.1);
}

.cards__content3 {
  background-image: url("../assets/images/cards/workdistrict-coworking-team-office.webp");
  background-size: cover;
  background-position: top;
  backdrop-filter: brightness(0.1);
}

.cards__content4 {
  background-image: url("../assets/images/cards/workdistrict-coworking-daily-pass.webp");
  background-size: cover;
  background-position: 25% 90%;
  background-position: center;
  padding-top: 33rem;
}

.cards__content5 {
  background-image: url("../assets/images/cards/workdistrict-coworking-multi-pass.webp");
  background-size: cover;
  background-position: 55% 60%;
  padding-top: 33.4rem;
}

.cards__content4:hover {
  padding-top: 3.4rem;
}

.cards__content5:hover {
  padding-top: 3.8rem;
}

/*****************/
/* MEDIA QUERIES */
/*****************/

@media (max-width: 1020px) {
  .offer__img-box {
    display: none;
  }

  .request__title {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  .offer__tofix {
    text-align: left;
    padding-left: 6rem;
  }

  .office__photo-box {
    padding-right: 2rem;
  }

  .office__box {
    flex-direction: column;
    gap: 6rem;
  }

  .office__photo-row {
    width: 100%;
  }

  .office__photo {
    min-width: 40rem;
  }

  .office__content {
    width: 100%;
  }
}

@media (max-width: 900px) {
  .request__title {
    width: 100%;
  }

  .office__photo {
    min-width: 35rem;
  }
}

@media (max-width: 780px) {
  .request__title {
    text-align: left;
  }

  .offer__cta-box {
    margin: 0 auto;
  }

  .office__text {
    text-align: left;
    padding-right: 0rem !important;
  }

  .office__title {
    align-self: flex-start;
    line-height: 1;
  }

  .office__btn {
    align-self: flex-start;
  }

  .office__photo {
    min-width: 30rem;
  }

  .office__box {
    margin-left: -2.5rem;
  }

  .section__offer {
    padding-right: 0rem;
    margin-left: -1.2rem;
  }

  .offer__tofix {
    padding-right: 5rem;
    align-self: left;
    text-align: left;
  }

  .office__photo-box {
    margin-right: -2.6rem;
  }
}

@media (max-width: 680px) {
  .office__photo {
    min-width: 25rem;
  }
}

@media (max-width: 580px) {
  .office__photo {
    min-width: 20rem;
  }

  .office__content {
    width: 110%;
  }

  .request__title {
    width: 100%;
    font-size: 3rem;
  }

  .offer__cta-box {
    flex-direction: column;
    max-width: 100vw;
    padding-left: 8rem;
    align-self: flex-start;
    padding-right: 0 !important;
  }

  .offer__tofix {
    margin-left: -0.8rem;
    align-self: left;
    text-align: left;
  }

  .office__form {
    transform: translateX(-2rem);
  }
}

@media (min-width: 1400px) {
  .cards__content {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}

@media (min-width: 1500px) {
  .cards__content {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
}

@media (min-width: 1600px) {
  .cards__content {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
}

@media (max-width: 1100px) {
  .cards__container {
    display: none;
  }
}

@media (max-width: 1300px) {
  .cards__content4 {
    background-position: center;
  }
}

@media (max-width: 480px) {
  .office__photo {
    min-width: 14rem;
  }
}

@media (max-width: 480px) {
  .office__cta-box {
    min-width: 14rem;
    background-color: red;
  }
}

@media (max-width: 400px) {
  .offer__title {
    line-height: 1.1;
    width: 100%;
  }

  .office__photo {
    max-width: 11rem;
  }
}

@media only screen and (max-width: 1020px) and (min-width: 580px) {
  .office__photo {
    max-width: 10rem !important;
    width: 10rem !important;
    min-width: 20rem !important;
  }

  .office__photo-box {
    height: auto;
  }
}

@media (max-width: 800px) {
  .offer__cta-box {
    align-self: flex-start;
    margin-left: 0;
  }

  .office__cta-tofix {
    margin-left: 4.8rem;
  }
}

.section__offer {
  padding-left: 0rem;
}

.section__workspace,
.section__coworking,
.section__community,
.section__meeting {
  padding-left: 8rem;
}

@media (max-width: 850px) {
  .section__workspace,
  .section__coworking,
  .section__community,
  .section__meeting {
    padding-left: 4rem !important;
  }

  .weare__box {
    padding-left: 0 !important;
  }

  .section__community {
    align-items: flex-start;
  }

  .section__meeting {
    padding-left: 4rem !important;
  }
}

.weare__box {
  padding-left: 0;
}

@media (max-width: 1024px) {
  #meeting-text {
    text-align: left;
  }

  #meeting-btn {
    align-self: flex-start;
  }

  #meeting-title {
    text-align: left;
  }

  .section__meeting {
    padding-left: 8rem !important;
  }
}

@media (min-width: 1020px) {
  .offer__tofix {
    padding-left: 8rem;
  }
}

@media (max-width: 800px) {
  #submit {
    align-self: flex-start;
    margin-left: 8rem;
  }
}

@media (max-width: 850px) {
  .section__meeting {
    padding-left: 4rem !important;
  }
}

@media (max-width: 520px) {
  .offer__cta-box {
    padding-left: 7rem !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
}

@media (max-width: 460px) {
  .offer__cta-box {
    padding-left: 6rem !important;
  }
}
</style>
