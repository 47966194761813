<template>
  <a
    class="cookie__pref"
    href="#"
    onclick="window.displayPreferenceModal();return false;"
    id="termly-consent-preferences"
    ><img
      class="cookie__img"
      src="../assets/images/office/cookie-icon.png"
      alt=""
  /></a>

  <!-- SECTION HERO -->
  <section id="top" class="section__hero">
    <div class="filter"></div>
    <NavVue></NavVue>
    <h1 class="hero__title martop hero__title-desktop">
      Would you like to work
    </h1>
    <h1 class="hero__title marbot hero__title-desktop">
      in a unique location in Amsterdam?
    </h1>
    <h1 class="hero__title martop marbot hero__title-mobile">
      Would you like to work beyond standarts?
    </h1>

    <form
      class="form__helper-desktop"
      id="bookatour"
      method="post"
      action="https://hockus.com/contactform_handling/bookatour.php"
    >
      <input
        ref="book"
        type="text"
        name="datetime"
        id="datetime"
        class="formfield form__date"
        placeholder="Choose a date and time"
        @focus="changeType"
        required
      />
      <input
        type="submit"
        id="submit"
        class="form__btn btn__modern"
        value="Book a tour!"
      />
      <input
        type="text"
        name="name"
        id="name"
        class="formfield conditionalhide form__name"
        placeholder="What is your name?"
        data-show-if="datetime"
        required
      />
      <input
        type="email"
        name="email"
        id="email"
        class="formfield conditionalhide form__email"
        placeholder="Your email address?"
        required
      />
      <input
        type="tel"
        name="tel"
        id="tel"
        class="formfield conditionalhide form__tel"
        placeholder="Tel incl. country code"
        required
      />
    </form>

    <form
      class="form__helper-mobile"
      id="bookatour"
      method="post"
      action="https://hockus.com/contactform_handling/bookatour.php"
    >
      <input
        type="datetime-local"
        name="datetime"
        id="datetime-test"
        class="formfield form__date"
        placeholder="Choose a date and time"
        required
      />
      <input
        type="submit"
        id="submit-test"
        class="form__btn btn__modern"
        value="Book a tour!"
      />
      <input
        type="text"
        name="name"
        id="name"
        class="formfield conditionalhide form__name"
        placeholder="Your name?"
        data-show-if="datetime"
        required
      />
      <input
        type="email"
        name="email"
        id="email"
        class="formfield conditionalhide form__email"
        placeholder="Your email?"
        required
      />
      <input
        type="tel"
        name="tel"
        id="tel"
        class="formfield conditionalhide form__tel"
        placeholder="Tel + country code"
        required
      />
    </form>

    <p id="hero-1" class="hero__text">Ready-to-use office & meetings spaces</p>
    <p class="hero__address">Hekelveld 8-10, 1012 SN Amsterdam</p>
    <ion-icon
      @click.prevent="scrollToWeare"
      class="hero__circle"
      name="chevron-down-outline"
    ></ion-icon>
  </section>

  <div v-bind:class="{ hide: !isModalOpen }" class="mmodal__container">
    <span @click="changeLeft" class="arr arrLeft">&#10095;</span>
    <span @click="changeRight" class="arr arrRight">&#10095;</span>
    <div class="mmodal">
      <div class="mmodal__box">
        <img class="mmodal__img" :src="currentImage" alt="This is an image" />
        <span class="mmodal__tab"
          >{{ currentIndex + 1 }}/{{ images.length }}</span
        >
      </div>
      <div @click="changeLeft" class="mmodal__left"></div>
      <div @click="changeRight" class="mmodal__right"></div>
    </div>
  </div>

  <span
    v-bind:class="{ hide: !isModalOpen }"
    @click="modalHandler"
    class="mmodal__closer"
    >&#10006;</span
  >

  <!-- SECTION WORKSPACE  -->
  <div class="home__title-box">
    <h2 class="home__title">Your Coworking Space in the Heart of Amsterdam</h2>
  </div>
  <section id="scroll-to-weare">
    <div class="home__left">
      <div ref="workspace__photo" class="home__left-photos">
        <div @click="modalHandler0" class="home__photo-box">
          <img
            class="home__photo"
            src="..\assets\images\office\workdistrict-coworking-hekelveld-building.webp"
            alt=""
          />
        </div>
        <div @click="modalHandler1" class="home__photo-box">
          <img
            class="home__photo"
            src="..\assets\images\office\workdistrict-coworking-living-room.webp"
            alt=""
          />
        </div>
        <div @click="modalHandler2" class="home__photo-box workspace__photo5">
          <img
            class="home__photo"
            src="..\assets\images\office\workdistrict-coworking-sofa-room.webp"
            alt=""
          />
        </div>
      </div>
      <div class="home__content">
        <h2>WE ARE WORKDISTRICT</h2>
        <p>
          Founded in 2021 in the heart of Amsterdam, our mission is to provide
          the best combined experience of working, connecting and easing up the
          mind. Therefore, we strive to always be the most special provider of
          not only flexible Coworking spaces and offices but of a whole new
          working lifestyle. So, come and visit us and get inspired by
          high-class interior, a unique tenant-matching and a great atmosphere,
          encouraging creative processes, concentration and ease in your work
          life. This is coworking Amsterdam style.
        </p>
        <ButtonVue
          id="weare-btn"
          @click="redirectToAboutPage"
          text="About WorkDistrict"
        ></ButtonVue>
      </div>
    </div>

    <div class="home__left">
      <div ref="workspace__photo_bis" class="home__left-photos">
        <div @click="modalHandler3" class="home__photo-box">
          <img
            class="home__photo"
            src="..\assets\images\office\workdistrict-coworking-space-room.webp"
            alt=""
          />
        </div>
        <div @click="modalHandler4" class="home__photo-box">
          <img
            class="home__photo"
            src="..\assets\images\office\workdistrict-meeting-room-earth.webp"
            alt=""
          />
        </div>
        <div class="home__photo-box workspace__photo5">
          <img
            class="home__photo"
            src="..\assets\images\office\workdistrict-meeting-room-water.webp"
            alt=""
          />
        </div>
      </div>
      <div @click="modalHandler5" class="weare__video">
        <video
          id="video"
          class="weare__video video__first"
          title="WorkDistrict, coworking space in the heart of Amsterdam"
          src="https://workdistrict-video102642-staging.s3.eu-west-1.amazonaws.com/video+med+WEBm.webm"
          autoplay
          muted
          loop
        ></video>
      </div>
    </div>
  </section>
  <!--SECTION WORKSPACE-->
  >

  <!-- SECTION COWORKING -->
  <section class="section section__coworking">
    <div class="filter"></div>
    <div class="text-box" id="coworking-box">
      <TitleVue
        id="coworking-title"
        class="co__title"
        content="Our coworking & shared office solutions"
      ></TitleVue>
      <p id="coworking-text" class="text co__text">
        At Workdistrict, you can choose from a variety of office spaces because
        we want you to create your workplace as special as you are. For
        instance, if you need a table in the open space area, if you want an
        office all for your own or if you want to get your teams together in a
        team office – we have the ideal flexible solution for you.
      </p>
      <ButtonVue
        id="coworking-btn"
        @click="redirectToOfficePage"
        text="Our office solutions"
      ></ButtonVue>
    </div>
    <!-- CARDS -->
    <div class="cards__container_helper">
      <div class="cards__container_home">
        <div class="cards__item">
          <div
            @click="navigateToContact('singleDesk')"
            v-on:mouseover="isFiltering1"
            v-on:mouseleave="isFiltering1"
            class="cards__content cards__content1"
          >
            <div class="cards__content_helper"></div>
            <div
              v-bind:class="{
                cards__filter: !isFiltered1,
                cards__filter_helper: isFiltered1,
              }"
            ></div>
            <h3 class="cards__title">Single Desk</h3>
            <p class="cards__text">
              Book a single desk and enjoy the flair of a coworking membership
              at Workdistrict. Here, you can sit together with industry peers,
              colleagues and friends to collaborate, discuss, and develop great
              ideas. Building new connections has never been easier!
            </p>
            <span class="cards__btn">Send an inquiry</span>
          </div>
        </div>
        <div class="cards__item">
          <div
            @click="navigateToContact('privateOffice')"
            v-on:mouseover="isFiltering2"
            v-on:mouseleave="isFiltering2"
            class="cards__content cards__content2"
          >
            <div class="cards__content_helper"></div>
            <div
              v-bind:class="{
                cards__filter: !isFiltered2,
                cards__filter_helper: isFiltered2,
              }"
            ></div>
            <h3 class="cards__title">Private Office</h3>
            <p class="cards__text">
              You need some space for yourself or your team? Then, a private
              office is the perfect solution for you! From 2-5 desks, we have
              offices in flexible sizes that exactly fit your needs. Moreover,
              you can lock your office to keep your sensitive data safe and just
              leave everything for the next day.
            </p>
            <span class="cards__btn">Send an inquiry</span>
          </div>
        </div>
        <div @click="navigateToContact('teamSuite')" class="cards__item">
          <div
            v-on:mouseover="isFiltering3"
            v-on:mouseleave="isFiltering3"
            class="cards__content cards__content3"
          >
            <div class="cards__content_helper"></div>
            <div
              v-bind:class="{
                'cards__filter-3': !isFiltered3,
                cards__filter_helper_suite: isFiltered3,
              }"
            ></div>
            <h3 class="cards__title">Team Suite</h3>
            <p class="cards__text">
              You have a bigger team to accommodate? No problem in the
              Workdistrict because we offer exclusive team suites. In other
              words, a collection of offices with community areas and meeting
              rooms privately for your company. Get your own access and keep
              your team together!
            </p>
            <span class="cards__btn">Send an inquiry</span>
          </div>
        </div>
        <div @click="navigateToContact('dailyPass')" class="cards__item">
          <div
            v-on:mouseover="isFiltering4"
            v-on:mouseleave="isFiltering4"
            class="cards__content cards__content4"
          >
            <div class="cards__content_helper"></div>
            <div
              v-bind:class="{
                cards__filter: !isFiltered4,
                cards__filter_helperbis: isFiltered4,
              }"
            ></div>
            <h3 class="cards__title">Daily Pass</h3>
            <p class="cards__text">
              You only need a space for one day? For example when you have an
              in-person meeting with your clients or need an office while
              stopping by Amsterdam? No problem, come to the Workdistrict and
              get your daily pass, allowing you all-access for one day and
              surprise with an outstanding office in one of the best locations
              in the city centre of Amsterdam.
            </p>
            <span class="cards__btn">Send an inquiry</span>
          </div>
        </div>
        <div @click="navigateToContact('multiPass')" class="cards__item">
          <div
            v-on:mouseover="isFiltering5"
            v-on:mouseleave="isFiltering5"
            class="cards__content cards__content5"
          >
            <div class="cards__content_helper"></div>
            <div
              v-bind:class="{
                cards__filter: !isFiltered5,
                cards__filter_helperbisbis: isFiltered5,
              }"
            ></div>
            <h3 class="cards__title">Multi Pass</h3>
            <p class="cards__text">
              You are a hybrid worker and booking a fixed space is not suitable
              with the way you work but still you need a workspace from time to
              time? Save money with our Multi-pass, allowing you 10 times
              discount-access to all our facilities. You can keep your card and
              just re-load it when your balance is all used.
            </p>
            <span class="cards__btn">Send an inquiry</span>
          </div>
        </div>
      </div>
    </div>
    <!-- CARDS -->
  </section>

  <!-- SECTION COMMUNITY -->
  <section class="section section__community">
    <div class="filter__white"></div>
    <div class="text-box community__box-tofix">
      <TitleVue id="community-title" content="Encouraging Community"></TitleVue>
      <p id="community-text" class="co__text text">
        Workdistrict is designed for an active community and encourages it
        actively. Above all, we know, that in todays times, exchange, sharing of
        experience and working with like-minded people is crucial for good
        business outcome, specially in a vibrant city like Amsterdam. Therefore,
        we are more than just another coworking space: besides of a variety of
        community activities that Workdistrict organizes for you, we come up
        with an inhouse-built matching software to connect you to the best
        fitting members within your office to network with, partner up or even
        do business with. That, we leave open to you.
      </p>
      <ButtonVue
        id="community-btn"
        @click="redirectToCommunityPage"
        text="Workdistrict community"
      ></ButtonVue>
    </div>
    <!-- ORIGIN PLACE COMMUNITY PHOTOS-->
    <div class="weare__video-box">
      <video
        id="video"
        class="weare__video video__second"
        title="WorkDistrict, coworking space in the heart of Amsterdam"
        src="https://workdistrict-video102642-staging.s3.eu-west-1.amazonaws.com/workdistrict-coworking-amsterdam-office-final.webm"
        autoplay
        muted
        loop
      ></video>
    </div>
    <!-- ORIGIN PLACE COMMUNITY PHOTOS-->
  </section>

  <!-- SECTION MEETING -->
  <section class="section section__meeting">
    <div class="filter"></div>
    <!-- COMMUNITY PHOTO -->
    <div class="community__photo-box display-none-helper">
      <div class="community__photo-row">
        <picture @click="modalHandler1" class="community__photo">
          <source
            srcset="
              ..\assets\images\office\workdistrict-coworking-living-room-mod.webp
            "
            media="(min-width: 1600px)"
          />
          <img
            src="..\assets\images\office\workdistrict-coworking-living-room.webp"
            alt="WorkDistrict office living room"
            class="border-fix"
          />
        </picture>
        <picture @click="modalHandler3" class="community__photo">
          <source
            srcset="
              ..\assets\images\office\workdistrict-coworking-space-room-mod.webp
            "
            media="(min-width: 1600px)"
          />
          <img
            src="..\assets\images\office\workdistrict-coworking-space-room.webp"
            alt="Workdistrict meeting room on space"
            class="border-fix"
          />
        </picture>
      </div>
      <div class="community__photo-row">
        <picture @click="modalHandler2" class="community__photo">
          <source
            srcset="
              ..\assets\images\office\workdistrict-coworking-sofa-room-mod.webp
            "
            media="(min-width: 1600px)"
          />
          <img
            src="..\assets\images\office\workdistrict-coworking-sofa-room.webp"
            alt="Workdistrict office cosy sofa room"
            class="border-fix"
          />
        </picture>
        <picture @click="modalHandler4" class="community__photo">
          <source
            srcset="
              ..\assets\images\office\workdistrict-meeting-room-earth-mod.webp
            "
            media="(min-width: 1600px)"
          />
          <img
            src="..\assets\images\office\workdistrict-meeting-room-earth.webp"
            alt="Workdistrict meeting room on earth"
            class="border-fix"
          />
        </picture>
      </div>
    </div>
    <!-- COMMUNITY PHOTO -->
    <div class="text-box home__meeting-box">
      <TitleVue
        id="meeting-title"
        class="align__end home__meeting-title"
        content="High-end meeting rooms"
      ></TitleVue>
      <p id="meeting-text" class="text text-right home__meeting-text">
        We pay a lot of attention to only using the highest quality materials
        and interior facilities in all of our spaces. Therefore, besides
        excellent Nespresso coffee free of charge, you can enjoy a variety of
        luxurious furnished meeting rooms, all equipped with smart-TVs, cozy
        chairs and wooden tables. Because we want to create a collaborative,
        creative and productive atmosphere so you can use your work time as
        efficient as possible.
      </p>
      <p id="meeting-text" class="text text-right home__meeting-text">
        Our meeting rooms are available for all our members without extra costs
        on top of your subscription. So, for your meeting you can freely select
        between our meeting rooms, that vary in terms of theme and number of
        available chairs. Moreover, some rooms you can even combine to one big
        room for a meeting with a larger group.
      </p>
      <p id="meeting-text" class="text text-right home__meeting-text">
        You can even rent out our high-end boutique spaces for your special
        events, even if you haven’t booked any of Workdistrict’s coworking
        spaces. To do so, give us a call or simply get in touch with us via our
        contact form to discuss together with us what we can do for you to make
        your events even more special.
      </p>
      <ButtonVue
        id="meeting-btn"
        @click="redirectToMeetingPage"
        class="align__end home__meeting-btn"
        text="Our meeting rooms"
      ></ButtonVue>
    </div>
  </section>
  <FooterVue></FooterVue>
</template>

<script>
import NavVue from "../components/NavVue.vue";
import ButtonVue from "../components/ButtonVue.vue";
import FooterVue from "../components/FooterVue.vue";
import TitleVue from "../components/TitleVue.vue";

import image1 from "@/assets/images/office/workdistrict-coworking-hekelveld-building-big.webp";
import image2 from "@/assets/images/office/workdistrict-coworking-living-room-big.webp";
import image3 from "@/assets/images/office/workdistrict-coworking-sofa-room-big.webp";
import image4 from "@/assets/images/office/workdistrict-coworking-space-room-big.webp";
import image5 from "@/assets/images/office/workdistrict-meeting-room-earth-big.webp";
import image6 from "@/assets/images/office/workdistrict-meeting-room-water-big.webp";
import image7 from "@/assets/images/office/workdistrict-meeting-room-jungle-big.webp";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "HomePage",
  data() {
    return {
      showMessage1: false,
      showMessage2: false,
      showMessage3: false,
      showMessage4: false,
      showMessage5: false,
      isModalOpen: false,
      disableScroll: false,
      isFiltered1: false,
      isFiltered2: false,
      isFiltered3: false,
      isFiltered4: false,
      isFiltered5: false,
      images: [image1, image2, image3, image4, image5, image6, image7],
      currentIndex: 0,
      arr: [],
    };
  },
  components: {
    NavVue,
    ButtonVue,
    FooterVue,
    TitleVue,
  },
  methods: {
    modalHandler() {
      this.isModalOpen == true
        ? (this.isModalOpen = false)
        : (this.isModalOpen = true);
      if (this.isModalOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    modalHandler0() {
      this.currentIndex = 0;
      this.isModalOpen == true
        ? (this.isModalOpen = false)
        : (this.isModalOpen = true);
      if (this.isModalOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    modalHandler1() {
      this.currentIndex = 1;
      this.isModalOpen == true
        ? (this.isModalOpen = false)
        : (this.isModalOpen = true);
      if (this.isModalOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    modalHandler2() {
      this.currentIndex = 2;
      this.isModalOpen == true
        ? (this.isModalOpen = false)
        : (this.isModalOpen = true);
      if (this.isModalOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    modalHandler3() {
      this.currentIndex = 3;
      this.isModalOpen == true
        ? (this.isModalOpen = false)
        : (this.isModalOpen = true);
      if (this.isModalOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    modalHandler4() {
      this.currentIndex = 4;
      this.isModalOpen == true
        ? (this.isModalOpen = false)
        : (this.isModalOpen = true);
      if (this.isModalOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    modalHandler5() {
      this.currentIndex = 5;
      this.isModalOpen == true
        ? (this.isModalOpen = false)
        : (this.isModalOpen = true);
      if (this.isModalOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    onMouseOver1() {
      this.showMessage1 = true;
      this.isFiltered1 = true;
    },
    onMouseLeave1() {
      this.showMessage1 = false;
      this.isFiltered1 = false;
    },
    onMouseOver2() {
      this.showMessage2 = true;
      this.isFiltered2 = true;
    },
    onMouseLeave2() {
      this.showMessage2 = false;
      this.isFiltered2 = false;
    },
    onMouseOver3() {
      this.showMessage3 = true;
      this.isFiltered3 = true;
    },
    onMouseLeave3() {
      this.showMessage3 = false;
      this.isFiltered3 = false;
    },
    onMouseOver4() {
      this.showMessage4 = true;
      this.isFiltered4 = true;
    },
    onMouseLeave4() {
      this.showMessage4 = false;
      this.isFiltered4 = false;
    },
    onMouseOver5() {
      this.showMessage5 = true;
      this.isFiltered5 = true;
    },
    onMouseLeave5() {
      this.showMessage5 = false;
      this.isFiltered5 = false;
    },
    scrollToWeare() {
      const weare = document.querySelector("#scroll-to-weare");
      weare.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    redirectToAboutPage() {
      this.$router.push("About-Page");
    },
    redirectToOfficePage() {
      this.$router.push("Office-Page");
    },
    redirectToMeetingPage() {
      this.$router.push("Meeting-Page");
    },
    redirectToCommunityPage() {
      this.$router.push("Community-Page");
    },
    changeType() {
      this.$refs.book.type = "datetime-local";
    },
    isFiltering1() {
      this.isFiltered1 == true
        ? (this.isFiltered1 = false)
        : (this.isFiltered1 = true);
    },
    isFiltering2() {
      this.isFiltered2 == true
        ? (this.isFiltered2 = false)
        : (this.isFiltered2 = true);
    },
    isFiltering3() {
      this.isFiltered3 == true
        ? (this.isFiltered3 = false)
        : (this.isFiltered3 = true);
    },
    isFiltering4() {
      this.isFiltered4 == true
        ? (this.isFiltered4 = false)
        : (this.isFiltered4 = true);
    },
    isFiltering5() {
      this.isFiltered5 == true
        ? (this.isFiltered5 = false)
        : (this.isFiltered5 = true);
    },
    changeLeft() {
      if (this.currentIndex == 0) {
        this.currentIndex = this.images.length - 1;
      } else {
        this.currentIndex = this.currentIndex - 1;
      }
    },
    changeRight() {
      if (this.currentIndex == this.images.length - 1) {
        this.currentIndex = 0;
      } else {
        this.currentIndex = this.currentIndex + 1;
      }
    },
    navigateToContact(preSelectedOption) {
      this.$router.push({
        name: "Contact-Page",
        query: { preSelected: preSelectedOption },
      });
    },
  },

  computed: {
    currentImage() {
      return this.images[this.currentIndex];
    },
  },

  mounted() {
    document.body.style.overflowX = "hidden";
    gsap.registerPlugin(ScrollTrigger);

    document.getElementById("top").scrollIntoView();

    gsap.from(this.$refs.workspace__photo, {
      scrollTrigger: {
        trigger: this.$refs.workspace__photo,
      },
      duration: 1.1,
      x: -300,
      opacity: 0,
    });

    gsap.from(this.$refs.workspace__photo_bis, {
      scrollTrigger: {
        trigger: this.$refs.workspace__photo,
      },
      duration: 1.1,
      x: 300,
      opacity: 0,
    });
  },
};
</script>

<style>
/***********/
/* SECTION */
/***********/

.section {
  padding: 2rem 6rem 6rem 6rem;
  position: relative;
  min-height: 90vh;
}

.section__hero {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/office/workdistrict-coworking-hekelveld-building-big.webp");
  background-position: top 20%;
  background-size: cover;
  filter: brightness(1);
  backdrop-filter: brightness(0.4);
  padding: 2rem 8rem;
  font-weight: 500;
  position: relative;
}

.section__workspace {
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  gap: 15rem;
  padding-top: 4rem;
}

.section__coworking {
  background-image: url("../assets/images/office/workdistrict-coworking-sofa-room-big.webp");
  background-size: cover;
  background-position: center;
  backdrop-filter: brightness(0.4);
  display: flex;
  flex-direction: column;
  gap: 10rem;
  padding-top: 6rem;
}

.section__community {
  background: rgba(255, 255, 255, 0.796);
  background-image: url("../assets/images/office/workdistrict-coworking-community.webp");
  background-size: cover;
  background-position: center;
  backdrop-filter: grayscale(0) contrast(3) blur(1px);
  color: var(--color-black);
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  gap: 4rem;
}

.section__meeting {
  color: var(--color-white);
  background-size: cover;
  background-position: center;
  backdrop-filter: brightness(0.4);
  background-image: url("../assets/images/office/workdistrict-coworking-jungle-gold.webp");
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 6rem;
}

/****************/
/* SECTION HERO */
/****************/

.mmodal__closer {
  position: fixed;
  right: 12rem;
  top: 7rem;
  z-index: 9999;
  cursor: pointer;
  font-size: 2rem;
  color: rgb(156, 146, 146);
  border: 1px solid rgb(202, 190, 190);
  border-radius: 100px;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  background-color: rgb(255, 255, 255);
}

.hero__title {
  font-size: 4.6rem;
  font-weight: 600;
  width: 100%;
  line-height: 1;
}

.martop {
  margin-top: 7rem;
}

.marbot {
  margin-top: 0.6rem;
  margin-bottom: 6rem;
  line-height: 1.2;
}

.hero__text {
  font-size: 3rem;
  width: 30%;
  line-height: 1;
  margin-top: 5rem;
}

.hero__address {
  font-size: 2rem;
  position: absolute;
  bottom: 3%;
  right: 3%;
  font-weight: 300;
}

.hero__circle {
  background-color: var(--color-magenta);
  border-radius: 200px;
  font-size: 6rem;
  position: absolute;
  bottom: -3rem;
  cursor: pointer;
  z-index: 40;
  display: block;
  left: 1rem;
}

.input__box {
  display: flex;
  align-items: center;
  gap: 2rem;
}

/*********************/
/* SECTION WORKSPACE */
/*********************/

.home__title-box {
  padding-left: 8rem;
  margin-top: 4.8rem;
  margin-bottom: 4rem;
}

.home__title {
  font-size: 3rem;
  color: var(--color-black);
  font-weight: 500;
  line-height: 1;
}

.home__content h2 {
  font-size: 3.6rem;
  line-height: 1.2;
}

.home__content p {
  font-size: 1.7rem;
  width: 85%;
}

#scroll-to-weare {
  display: flex;
  color: var(--color-black);
  padding-left: 8rem;
  padding-right: 8rem;
  gap: 2rem;
}

.home__left {
  min-width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10rem;
}

.home__left-photos {
  display: flex;
  gap: 2rem;
}

.home__photo-box {
  width: 50%;
  overflow: hidden;
}

.home__photo {
  width: 100%;
  min-height: max-content;
  overflow: hidden;
  transition: 0.3s;
  /*
  */
  object-fit: cover;
}

.home__photo:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.home__content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

/*********************/
/* SECTION WOORKSPACE */
/*********************/
/*********************/
/* SECTION WORKSPACE */
/*********************/

.workspace__box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.4rem;
  align-self: center;
  width: 99%;
}

.workspace__photo-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  gap: 2rem;
  max-width: 100%;
  align-items: center;
  align-self: center;
}

.workspace__photo {
  max-width: 27rem;
  height: auto;
  cursor: pointer;
  object-fit: cover;
}

.workspace__title {
  font-size: 3.2rem;
  width: 100%;
  justify-self: center;
}

.weare__box {
  display: flex;
  align-items: center;
  gap: 6rem;
}

.weare__video-box {
  width: 50%;
  margin-top: -4rem;
  position: relative;
}

.video__mask {
  position: absolute;
  width: 100%;
  background-color: red;
  background-color: transparent;
  background-color: white;
  height: 3.1vw;
  display: none;
}

.video__mask-1 {
  top: 0;
}

.video__mask-2 {
  bottom: 0;
}

.video__first {
  border: 1px solid white;
}

.video__second {
  transform: translateY(4rem);
}

.weare__title {
  font-size: 3rem;
}

/*********************/
/* SECTION COWORKING */
/*********************/

.coworking__photo-box {
  width: 20%;
  position: relative;
  z-index: 20;
}

.coworking__photo {
  width: 100%;
  height: 35rem;
  object-fit: cover;
  z-index: -1;
}

.coworking__photos {
  display: flex;
}

.coworking__photo-title {
  font-size: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  width: max-content;
}

.coworking__photo__title {
  font-size: 2.4rem;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 500;
  width: max-content;
}

.coworking__photo-text {
  font-size: 1.3rem;
  position: absolute;
  transform: translate(-50%, -40%);
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 2rem;
}

.coworking__filter {
  backdrop-filter: brightness(0.2);
  width: 100%;
  height: 101%;
  position: absolute;
  top: 0;
  left: 0;
}

.coworking__filter__helper {
  filter: brightness(0.6);
}

/*********************/
/* SECTION COMMUNITY */
/*********************/

.community__photo-box {
  display: flex;
  gap: 2rem;
}

.community__photo-row {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: hidden;
}

.community__photo {
  max-width: 20rem;
  transition: all 0.3s;
  cursor: pointer;
}

.community__photo:hover {
  transform: scale(1.08);
}

.btn__modern {
  display: inline-block;
  font-size: 2rem;
  color: #000;
  background-color: transparent;
  text-decoration: none;
  padding: 1rem 1.6rem;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  border: 2px solid white;
  color: white;
  border-radius: 3px;
  transition: 0.4s;
  cursor: pointer;
  font-weight: 500;
}

.btn__modern:hover {
  background: var(--color-magenta);
  color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.7);
  transform: scale(0.9);
  border: 2px solid var(--color-magenta);
}

.btn__helper {
  width: 20rem;
  align-self: center;
  transform: scale(1) translateX(-40%) !important;
  margin-top: 2rem;
}

.btn__helper:hover {
  transform: scale(0.9) translateX(-40%) !important;
}

.cards__container_home {
  width: 100vw;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-top: 0rem;
  position: absolute;
  transform: translate(-8rem, -4rem);
  height: 40rem;
}

.cards__container_helper {
  margin-top: 0rem;
  height: 40rem;
  width: 100%;
  position: relative;
  z-index: 2;
}

/********/
/* FORM */
/********/

.formfield,
.control {
  height: 50px;
  background-color: #ffffff !important;
  border: none;
  border-radius: 5px;
  padding-left: 3%;
  padding-right: 3%;
  font-size: 1.4rem;
}

.submitbutton {
  height: 50px;
  color: #ffffff;
  background-color: var(--color-magenta);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding-left: 3%;
  padding-right: 3%;
  font-size: 1.4rem;
}

:invalid ~ .conditionalhide {
  display: none;
}

.formfield:focus {
  outline: none;
  border-color: var(--color-magenta);
  box-shadow: 0 0 0 0.2rem var(--color-magenta);
}

#bookatour {
  display: grid;
  grid-template-columns: repeat(7, 7rem);
  grid-template-rows: 1fr 1fr;
  align-items: center;
  grid-gap: 2.4rem;
  width: 62.4rem;
}

.form__date {
  grid-area: 1 / 1 / 2 / 6;
}

.form__name {
  grid-area: 2 / 1 / 3 / 3;
}

.form__email {
  grid-area: 2 / 3 / 3 / 6;
}

.form__tel {
  grid-area: 2 / 6 / 3 / 8;
}

.form__btn {
  grid-area: 1 / 6 / 2 / 8;
}

.weare__text {
  color: var(--color-black);
  width: 80%;
}

.weare__box {
  gap: 0;
}

/*****************/
/* MEDIA QUERIES */
/*****************/

@media (min-width: 1280px) {
  .video__mask {
    height: 3.3vw;
  }
}

@media (min-width: 1920px) {
  .section__community {
    background-image: url("../assets/images/office/workdistrict-coworking-community-big.webp");
  }
}

@media (max-width: 1480px) {
  .workspace__photo5 {
    display: none;
  }
}

@media (min-width: 1480px) {
  .workspace__photo-box {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 1740px) {
  .workspace__photo6 {
    display: none;
  }
}

@media (min-width: 1740px) {
  .workspace__photo-box {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 1300px) {
  .community__photo {
    min-width: 25rem;
  }

  .section__meeting {
    min-height: auto;
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

@media (min-width: 1400px) {
  .community__photo {
    min-width: 27rem;
  }

  .cards__content {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .section__meeting {
    padding-top: 15rem !important;
    padding-bottom: 14rem !important;
  }
}

@media (min-width: 1500px) {
  .community__photo {
    min-width: 27rem;
  }

  .cards__content {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .section__meeting {
    padding-top: 20rem !important;
    padding-bottom: 18rem !important;
  }
}

@media (min-width: 1600px) {
  .community__photo {
    min-width: 38rem;
  }

  .cards__content {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
}

@media (max-width: 1280px) {
  .display-none-helper {
    display: none;
  }
}

@media (max-width: 1150px) {
  .workspace__photo-box {
    gap: 1rem;
  }

  .workspace__photo {
    max-width: 24.4rem;
  }
}

@media (max-width: 900px) {
  #scroll-to-weare,
  .home__left-photos {
    gap: 1rem;
  }
}

@media (max-width: 1020px) {
  .weare__video,
  .video__mask {
    display: none;
  }

  .weare__video-box {
    display: none !important;
  }

  .home__content h2 {
    width: 200%;
  }

  .home__content p {
    width: 182%;
  }

  .co__text {
    text-align: left !important;
  }

  .text-box {
    width: 90%;
  }

  .coworking__photos {
    display: none;
  }

  .align-end {
    align-self: center !important;
  }

  .section__meeting {
    justify-content: center;
  }

  .section__meeting {
    justify-content: center;
  }

  .text {
    text-align: center;
  }

  .section__community {
    flex-direction: column;
  }

  .community__photo {
    min-width: 27rem;
  }
}

@media (max-width: 1200px) {
  .workspace__photo {
    width: 28rem;
  }

  .home__meeting-title {
    text-align: right;
    width: 100%;
  }
}

@media (min-width: 1350px) {
  .martop {
    margin-top: 16rem;
  }

  .hero__title {
    font-size: 5rem;
  }

  .hero__text {
    font-size: 4rem;
    width: 40%;
    margin-top: 4.2rem;
  }

  .marbot {
    margin-bottom: 8rem;
  }
}

@media (min-width: 1600px) {
  .martop {
    margin-top: 20rem;
  }
  .hero__title {
    font-size: 6rem;
  }

  .hero__text {
    font-size: 4.2rem;
    width: 30%;
    margin-top: 4.6rem;
  }

  .marbot {
    margin-bottom: 10rem;
  }
}

@media (max-width: 1400px) {
  .mmodal__closer {
    right: 10rem;
  }
}

@media (max-width: 1200px) {
  .mmodal__closer {
    right: 8rem;
  }
}

@media (max-width: 800px) {
  .mmodal__closer {
    right: 7rem;
  }
}

@media (max-width: 700px) {
  .mmodal__closer {
    top: 22rem;
    right: 6rem;
  }
}

@media (max-width: 600px) {
  .mmodal__closer {
    right: 5.4rem;
  }
}

@media (max-width: 500px) {
  .mmodal__closer {
    right: 5rem;
  }
}

@media (max-width: 420px) {
  .mmodal__closer {
    top: 25.4rem;
    right: 3.8rem;
    font-size: 1.4rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media (max-width: 1000px) {
  .workspace__photo {
    width: 22rem;
  }

  .home__meeting-title {
    text-align: center;
  }

  .home__meeting-btn {
    align-self: center;
  }

  .home__meeting-box {
    width: 100%;
  }

  .section__community {
    gap: 4rem;
    padding-top: 6rem;
  }

  .section__coworking {
    padding-top: 19rem;
    padding-left: 10.6rem;
  }

  .weare__box {
    padding-left: 2rem;
    width: 100% !important;
  }

  .weare__text-box {
    max-width: 100rem !important;
  }

  #weare-text {
    margin: 0;
  }

  .weare__text {
    margin: 0 auto;
  }

  .section__workspace {
    gap: 8rem;
  }
}

@media (max-width: 900px) {
  .workspace__title {
    line-height: 1.1;
  }

  .workspace__photo {
    width: 19rem;
  }
}

@media (min-width: 800px) {
  .hero__title-mobile {
    display: none;
  }
}

@media (max-width: 800px) {
  .workspace__photo {
    width: 17rem;
  }

  .hero__title {
    text-align: center;
    font-size: 3.8rem;
    font-weight: 500;
    width: 120%;
    transform: translateX(-8%);
  }

  .martop {
    margin-top: 5rem;
  }

  .hero__title-desktop {
    display: none;
  }

  .input__box {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    transform: translateX(-4rem);
  }

  .hero__text {
    transform: translateX(-4rem);
    width: 100%;
    font-size: 2.4rem;
  }

  .hero__address {
    font-size: 1.4rem;
  }

  .hero__circle {
    left: 7%;
  }

  .section__hero {
    background-position: center;
  }
}

@media (max-width: 720px) {
  .workspace__photo {
    min-width: 30rem;
  }

  .community__photo {
    min-width: 25rem !important;
  }

  .community__photo-box {
    gap: 1rem;
  }

  .community__photo-row {
    gap: 1rem;
  }

  .home__left-photos {
    flex-direction: column;
  }

  .home__photo-box {
    width: 99%;
  }

  .workspace__photo-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
  }

  .workspace__title {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 1rem;
  }
}

@media (max-width: 645px) {
  .section__workspace {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .workspace__photo {
    min-width: 25rem;
  }

  .workspace__title {
    display: none;
  }

  .workspace__photo-box {
    display: none;
  }
}

@media (max-width: 560px) {
  .section__workspace {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .section__community {
    padding-bottom: 4rem;
  }

  .weare__box {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .section__coworking {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 15rem;
  }

  .workspace__photo {
    min-width: 22rem;
  }

  .community__photo {
    min-width: 10rem !important;
  }
}

@media (max-width: 460px) {
  .section__community {
    padding-bottom: 2.2rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .martop {
    margin-top: 10rem;
  }

  .marbot {
    margin-bottom: 4rem;
  }

  .weare__box {
    margin-left: 1.4rem;
  }

  .section__meeting {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }

  .weare__box {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .workspace__photo {
    min-width: 15rem;
  }

  .community__photo {
    max-width: 15rem !important;
  }

  .section__coworking {
    padding-top: 14rem;
  }
}

@media (max-width: 420px) {
  .section__coworking {
    padding-top: 6rem;
    padding-bottom: 2rem;
  }
}

@media (max-width: 400px) {
  .hero__title {
    font-size: 3.1rem;
  }

  .hero__text {
    font-size: 2rem;
  }
}

@media (max-width: 1100px) {
  .cards__container_helper {
    display: none;
  }

  .section__coworking {
    padding-bottom: 0;
  }
}

.form__helper-mobile {
  display: none !important;
}

@media (max-width: 800px) {
  #bookatour {
    grid-gap: 1.5rem !important;
    width: 48rem !important;
    transform: translateX(-5rem);
    grid-template-columns: 15rem 15rem 15rem;
    grid-template-rows: 1fr 1fr 1fr;
  }

  .form__date {
    grid-area: 1 / 1 / 2 / 4;
    width: 100%;
  }

  .form__name {
    grid-area: 2 / 1 / 3 / 2;
  }

  .form__email {
    grid-area: 2 / 2 / 3 / 4;
  }

  .form__tel {
    grid-area: 3 / 3 / 4 / 4;
  }

  .form__btn {
    grid-area: 3 / 1 / 4 / 3;
  }

  .hero__address {
    bottom: 1%;
  }

  .form__helper-mobile {
    display: grid !important;
    margin: 0 20%;
    width: 100%;
  }

  .form__helper-desktop {
    display: none !important;
  }

  .marbot {
    margin-bottom: 4rem;
  }

  .section__coworking,
  .section__meeting,
  .section__community {
    background-attachment: initial;
  }
}

@media (max-width: 600px) {
  #bookatour {
    grid-gap: 1rem !important;
    width: 32rem !important;
    transform: translateX(-4rem);
    grid-template-columns: 10rem 10rem 10rem;
    grid-template-rows: 1fr 1fr 1fr;
  }
}

@media (max-width: 720px) {
  .form__helper-mobile {
    margin: 0 15%;
  }
}

@media (max-width: 640px) {
  .form__helper-mobile {
    margin: 0 10%;
  }
}

@media (max-width: 580px) {
  .form__helper-mobile {
    margin: 0 20%;
  }
}

@media (max-width: 520px) {
  .form__helper-mobile {
    margin: 0 12%;
  }
}

@media (max-width: 460px) {
  .form__helper-mobile {
    margin: 0 4%;
  }
}

@media (max-width: 400px) {
  .form__helper-mobile {
    margin: 0 0;
  }
}

.mmodal__tab {
  position: absolute;
  bottom: 1.8rem;
  right: 2.1rem;
  font-size: 1.6rem;
  z-index: 500;
  color: rgb(182, 172, 172);
  font-weight: 500;
}

.mmodal__box {
  border: 3px solid rgba(78, 72, 72, 0.166);
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  display: flex;
  justify-content: center;
}

.mmodal__container {
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  backdrop-filter: blur(6px);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.mmodal {
  width: 90%;
  height: 90%;
  display: flex;
  position: relative;
}

.mmodal__img {
  width: 99%;
  height: 92%;
  object-fit: cover;
  margin-top: 0.5%;
}

.mmodal__left,
.mmodal__right {
  z-index: 99;
}

.mmodal__left {
  background-color: transparent;
  width: 100%;
}

.mmodal__right {
  background-color: transparent;
  width: 100%;
}

.arr {
  position: absolute;
  top: 47%;
  color: rgba(181, 170, 170, 0.688);
  font-size: 4.8rem;
  z-index: 100;
  cursor: pointer;
}

.arrLeft {
  transform: rotate(180deg);
  left: 1.4%;
}

.arrRight {
  right: 1.4%;
}

@media (max-width: 700px) {
  .mmodal {
    height: 50%;
  }

  .mmodal__tab {
    bottom: 0.6rem;
    font-size: 1.2rem;
  }

  .arr {
    font-size: 3.6rem;
  }

  .arrLeft {
    left: 0.9%;
  }

  .arrRight {
    right: 0.9%;
  }
}

@media (max-width: 420px) {
  .mmodal {
    height: 40%;
  }

  .mmodal__tab {
    bottom: 0.3rem;
    font-size: 1.1rem;
  }

  .arr {
    font-size: 2.8rem;
  }

  .arrLeft {
    left: 1%;
  }

  .arrRight {
    right: 1%;
  }
}

@media (max-width: 850px) {
  #scroll-to-weare,
  .home__title-box {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .home__title {
    margin-top: 6rem;
    margin-bottom: 2rem !important;
  }
}

@media (max-height: 600px) and (min-width: 900px) {
  .mmodal__tab {
    transform: translateY(1rem);
  }
}

@media only screen and (max-width: 1024px) and (min-width: 850px) {
  .section__meeting {
    padding-left: 8rem !important;
  }
}

@media (max-width: 1024px) {
  .section__community {
    height: auto !important;
    min-height: auto !important;
    max-height: auto !important;
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .community__box-tofix {
    align-self: flex-start;
  }
}

@media (max-width: 1024px) {
  .section__community {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
}

@media (min-width: 1030px) {
  .community__box-tofix,
  #coworking-box {
    width: 40% !important;
  }
}

@media (max-width: 420px) {
  .home__left-photos,
  .home__title-box {
    display: none;
  }

  .home__content h2 {
    margin-top: 4rem;
  }
}

@media (max-width: 1440px) {
  #scroll-to-weare {
    padding-bottom: 6rem !important;
  }
}

@media (max-width: 655px) {
  .section__meeting {
    padding-top: 4rem;
  }
}

@media (max-width: 420px) {
  .home__content h2 {
    margin-top: 6rem !important;
  }
}

@media (max-width: 1080px) {
  .about__text-tofix {
    width: 90%;
  }

  .mission__box,
  .mission__title,
  .mission__text {
    align-items: flex-start !important;
    align-self: flex-start !important;
    text-align: left !important;
    transform: translateX(0.2rem);
  }
}

#datetime-test {
  grid-area: 1 / 1 / 2 / 4;
}

.btn__this {
  align-self: left !important;
  margin-left: 4rem;
}
</style>
