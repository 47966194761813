<template>
  <!-- SECTION HERO -->
  <section id="top" class="section__hero notFound__tofix">
    <div class="filter"></div>
    <NavVue></NavVue>
    <div class="notFound__content">
      <h1 class="notFound__title">Thank you!</h1>
      <p class="notFound__text">
        Thank you for contacting us. We appreciate your message and would like
        to confirm that we have received it. Our team will review your inquiry
        and respond shortly.
      </p>
      <div class="notFound__links">
        <ButtonVue
          class="notFound__link"
          @click="redirectToHomePage"
          text="Home Page"
        ></ButtonVue>
        <ButtonVue
          class="notFound__link"
          @click="redirectToAboutPage"
          text="About us"
        ></ButtonVue>
        <ButtonVue
          class="notFound__link"
          @click="redirectToOfficePage"
          text="Our offices"
        ></ButtonVue>
        <ButtonVue
          class="notFound__link"
          @click="redirectToCommunityPage"
          text="Community"
        ></ButtonVue>
      </div>
    </div>
  </section>
  <FooterVue></FooterVue>
</template>

<script>
import NavVue from "../components/NavVue.vue";
import ButtonVue from "../components/ButtonVue.vue";
import FooterVue from "../components/FooterVue.vue";

export default {
  name: "ThanksPage",
  components: {
    NavVue,
    ButtonVue,
    FooterVue,
  },
  methods: {
    redirectToHomePage() {
      this.$router.push("/");
    },
    redirectToAboutPage() {
      this.$router.push("About-Page");
    },
    redirectToOfficePage() {
      this.$router.push("Office-Page");
    },
    redirectToCommunityPage() {
      this.$router.push("Community-Page");
    },
  },
  mounted() {
    document.body.style.overflowX = "hidden";
    window.scrollTo(0, 0);
  },
};
</script>

<style></style>
