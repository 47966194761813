import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { IonIcon } from '@ionic/vue';
import { Amplify } from 'aws-amplify'
import awsconfig from './aws-exports'
import { Auth }  from 'aws-amplify'
import "@aws-amplify/ui-vue/styles.css"
import { DataStore } from 'aws-amplify'






Amplify.configure(awsconfig)
Auth.configure(awsconfig);

DataStore.configure(awsconfig)









createApp(App).component('ion-icon', IonIcon).use(router).mount('#app');


