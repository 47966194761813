<template>
<h2 class="title">{{content}}</h2>
</template>



<script>
export default {
  props : {
    content : {
      type: String,
      required: true
    }
  }
 
  
}
</script>

<style>


.title {
  font-size: 3.6rem;
  text-transform: uppercase;
  color: inherit;
  line-height: 1.1;
}

.title-lower {
  text-transform: none;
  margin-bottom: 2rem;
}


</style>