<template>
  <div class="footer">
    <div class="footer__section footer__section-first">
      <h3 class="footer__title">Let's stay in contact</h3>
      <div class="footer__logos">
        <ion-icon
          @click="redirectToFacebook"
          class="footer__logo footer__fbk"
          name="logo-facebook"
        ></ion-icon>
        <ion-icon
          @click="redirectToInstagram"
          class="footer__logo"
          name="logo-instagram"
        ></ion-icon>
        <ion-icon
          @click="redirectToLinkedin"
          class="footer__logo"
          name="logo-linkedin"
        ></ion-icon>
      </div>
      <li class="footer__contact-wrap">
        <a class="footer__contact" :href="mailToLink"
          >contact@workdistrict.com
        </a>
      </li>
      <li class="footer__contact-wrap">
        <a class="footer__contact" :href="telLink">+31 20 722 4010 </a>
      </li>
      <span @click="redirectToContactPage" class="footer__btn btn__modern"
        >Get in touch today!</span
      >
    </div>
    <div class="footer__section footer__section-mid">
      <h3 class="footer__title">Visit us!</h3>
      <p class="footer__address">Workdistrict Netherlands B.V.</p>
      <p class="footer__address">Hekelveld 8-10</p>
      <p @click="redirectToBookingVue" class="footer__address">
        1012SN Amsterdam
      </p>
      <p class="footer__address">The Netherlands</p>

      <!--<iframe class="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9742.35829423001!2d4.8952683!3d52.3778617!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c60936ade4498f%3A0x4a59350491852e87!2sWorkdistrict!5e0!3m2!1sfr!2snl!4v1681295392375!5m2!1sfr!2snl&hl=en" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>-->
      <iframe
        class="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2435.589394483286!2d4.892693376985359!3d52.37786494674263!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c60936ade4498f%3A0x4a59350491852e87!2sWorkdistrict!5e0!3m2!1sen!2snl!4v1681804056677!5m2!1sen!2snl"
        width="600"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      <!-- <MapVue/> -->
    </div>
    <div class="footer__section footer__section-last">
      <h3 class="footer__title">Navigate</h3>
      <ul class="footer__links">
        <li class="footer__link-wrap">
          <p @click="redirectToHomePage" class="footer__link">WorkDistrict</p>
        </li>
        <li class="footer__link-wrap">
          <router-link to="About-Page" class="footer__link">About</router-link>
        </li>
        <li class="footer__link-wrap">
          <p @click="redirectToOfficePage" class="footer__link">
            Office solutions
          </p>
        </li>
        <li class="footer__link-wrap">
          <p @click="redirectToMeetingPage" class="footer__link">
            Meeting Rooms
          </p>
        </li>
        <li class="footer__link-wrap">
          <p @click="redirectToCommunityPage" class="footer__link">Community</p>
        </li>
        <li class="footer__link-wrap">
          <p @click="redirectToContactPage" class="footer__link">Contact</p>
        </li>
        <li class="footer__link-wrap footer__link-tofix">
          <p @click="redirectToImprintPage" class="footer__link">Imprint</p>
        </li>
        <li class="footer__link-wrap">
          <p @click="redirectToPrivacyPage" class="footer__link">
            Privacy Policy
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
//import MapVue from '../components/MapVue.vue'
import { IonIcon } from "@ionic/vue";

export default {
  data() {
    return {
      emailAddress: "contact@workdistrict.com",
      emailSubject: "Hello",
      emailBody: "Write your message here",
      phoneNumber: +31207224010,
    };
  },

  computed: {
    mailToLink() {
      return `mailto:${this.emailAddress}?subject=${this.emailSubject}&body=${this.emailBody}`;
    },
    telLink() {
      return `tel:${this.phoneNumber}`;
    },
  },
  components: {
    //MapVue,
    IonIcon,
  },
  methods: {
    redirectToLinkedin() {
      const newWindow = window.open(
        "https://www.linkedin.com/company/work-district-global",
        "_blank"
      );

      if (newWindow) {
        newWindow.opener = null;
      } else {
        window.location.href =
          "https://www.linkedin.com/company/work-district-global";
      }
    },
    redirectToInstagram() {
      const newWindow = window.open(
        "https://www.instagram.com/workdistrictglobal/",
        "_blank"
      );

      if (newWindow) {
        newWindow.opener = null;
      } else {
        window.location.href = "https://www.instagram.com/workdistrictglobal/";
      }
    },
    redirectToFacebook() {
      const newWindow = window.open(
        "https://m.facebook.com/profile.php?id=100083791110329",
        "_blank"
      );

      if (newWindow) {
        newWindow.opener = null;
      } else {
        window.location.href =
          "https://m.facebook.com/profile.php?id=100083791110329";
      }
    },
    redirectToHomePage() {
      this.$router.push("/");
    },
    redirectToAboutPage() {
      this.$router.push("About-Page");
    },
    redirectToOfficePage() {
      this.$router.push("Office-Page");
    },
    redirectToMeetingPage() {
      this.$router.push("Meeting-Page");
    },
    redirectToCommunityPage() {
      this.$router.push("Community-Page");
      window.scrollTo(0, 0);
    },
    redirectToContactPage() {
      this.$router.push("Contact-Page");
    },
    redirectToImprintPage() {
      this.$router.push("Imprint-Page");
    },
    redirectToPrivacyPage() {
      this.$router.push("Privacy-Page");
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
.footer {
  display: flex;
  background-color: var(--color-bg);
  gap: 2rem;
  justify-content: space-between;
  padding: 3rem;
  padding-left: 8rem;
  padding-right: 8rem;
  height: 45rem;
  z-index: 499999;
}

.footer__section {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.footer__section-mid {
  width: 35%;
}

.footer__section-last {
  width: 28%;
}

.footer__title {
  font-size: 3.6rem;
  margin-bottom: 3rem;
  line-height: 1;
}

.footer__links {
  font-size: 1.8rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  list-style: none;
  margin-left: 1.4rem;
}

.footer__link {
  color: inherit;
  padding-bottom: 2.2px;
  transition: all 0.2s;
}

.footer__link-tofix {
  margin-top: 2rem;
}

/*
.footer__link:hover {
  color: var(--color-magenta);
}
*/

.footer__address {
  font-size: 1.6rem;
}

.footer__logos {
  display: flex;
  gap: 3rem;
  margin-left: 2rem;
  margin-bottom: 3rem;
}

.footer__logo {
  font-size: 3rem;
  cursor: pointer;
  transition: all 0.3s;
}

.footer__logo:hover {
  color: var(--color-magenta);
  transform: scale(1.12);
}

.footer__fbk {
  border-radius: 30%;
}

.footer__contact {
  color: inherit;
  font-size: 2rem;
  margin-bottom: 0.3rem;
  margin-top: 0.7rem;
}

.footer__btn {
  flex-grow: 0;
  width: max-content;
  margin-top: 3rem;
}

.footer__link:before {
  content: "";
  position: absolute;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50px;
  background-color: var(--color-white);
  transition: all 0.3s;
  top: 1rem;
  left: -1.5rem;
}

.footer__link:after,
.footer__contact:after {
  content: "";
  position: absolute;
  width: 103%;
  height: 2px;
  bottom: 0.3rem;
  left: 0;
  background-color: var(--color-magenta);
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.4s;
  border-radius: 3px;
}

.footer__link:hover:after,
.footer__contact:hover:after {
  transform-origin: bottom left;
  transform: scaleX(1);
}

.footer__link:hover:before {
  background-color: var(--color-magenta);
}

.footer__contact {
  list-style: none;
}

.footer__link-wrap {
  position: relative;
  width: max-content;
  cursor: pointer;
}

.footer__contact-wrap {
  cursor: pointer;
  list-style: none;
  width: max-content;
  position: relative;
  margin-bottom: 0.7rem;
}

@media (max-width: 850px) {
  .footer {
    flex-direction: column;
    height: auto;
    gap: 6.4rem;
    padding: 5rem 4rem;
    padding-bottom: 8rem;
    z-index: 499;
  }

  .footer__section {
    width: 100%;
  }
}
</style>
