<template>

  <div class="tempino">
    <NavVue></NavVue>


    <form class="login__form">
        <h2 class="login__title">Login</h2>
        <input class="login__input" type="text" v-model="username" placeholder="Username" />
        <input class="login__input" type="password" v-model="password" placeholder="Password" />
        <button type="submit" class="login__btn">Login</button>
    </form>



 </div>
</template>


<script>


import NavVue from '../components/NavVue.vue'



export default {
  name: 'LoginViews',
  
  components: {
    NavVue,
  }



}




</script>

<style>

  .tempino {

    align-items: center;
    height: 100vh;
  }


.login__form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: var(--color-bg);
  background-color: var(--color-white);
  align-items: center;
  height: 35rem;
  width: 40%;
  border-radius: 8px;
  padding: 2rem 3rem 4rem 3rem;
  margin: 5rem auto;
  justify-content: center;
}

.login__title {
  align-self: flex-start;
  font-weight: 600;
  font-size: 4rem;
  color: var(--color-bg);
}

.login__input {
  height: 5rem;
  width: 100%;
  outline: none;
  font-size: 2rem;
  color: inherit;
  border: 1px solid grey;
  border-radius: 6px;
  padding-left: 1rem;
}

.login__input:focus {
  border: 2px solid var(--color-magenta-light);
  border-radius: 6px;
}

.login__btn {
  width: 100%;
  padding: 1rem 0;
  background-color: var(--color-magenta);
  border: none;
  border-radius: 2px;
  color: var(--color-white);
  font-size: 2.4rem;
  cursor: pointer;
}

.login__btn:hover {
  background-color: var(--color-magenta-light);
}




</style>