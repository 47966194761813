<template>
  <router-view />
</template>

<script>
export default {};
</script>

<style>
/*************/
/* VARIABLES */
/*************/

:root {
  /* COLOR PRIMARY */
  --color-black: #000000;
  --color-white: #ffffff;
  --color-magenta: #bb2649;
  --color-magenta-light: #cb385a;
  --color-timber: #13322b;

  /* COLOR SECONDARY  */
  --color-dark: #1c262e;
  --color-lavender: #ccd1e0;
  --color-tobacco: #7a5736;
  --color-bg: rgb(22, 22, 21);
}

/*************/
/*   FONTS   */
/*************/

/* Light */
@font-face {
  font-family: "HK Grotesk";
  font-weight: 300;
  src: url("assets/fonts/HKGrotesk-Light.otf") format("opentype");
  font-display: swap;
}

/* Regular */
@font-face {
  font-family: "HK Grotesk";
  font-weight: 400;
  src: url("assets/fonts/HKGrotesk-Regular.otf") format("opentype");
  font-display: swap;
}

/* Semi Bold */
@font-face {
  font-family: "HK Grotesk";
  font-weight: 500;
  src: url("assets/fonts/HKGrotesk-Medium.otf") format("opentype");
  font-display: swap;
}

/* Bold */
@font-face {
  font-family: "HK Grotesk";
  font-weight: 600;
  src: url("assets/fonts/HKGrotesk-Bold.otf") format("opentype");
  font-display: swap;
}

/*************/
/* CSS RESET */
/*************/
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font: inherit;
  font-family: "HK Grotesk", sans-serif;
}

html {
  font-size: 62.5%;
  height: 100%;
  overflow-x: hidden;
}

body {
  height: 100%;
  line-height: 1.5;
  text-rendering: optimizeSpeed;
  background-color: var(--color-white);
  color: var(--color-white);
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

.container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/*****************/
/* GENERAL STYLE */
/*****************/

.text {
  font-size: 1.7rem;
  color: inherit;
}

.text-right {
  font-size: 1.7rem;
  color: inherit;
  text-align: right;
}

.text-center {
  font-size: 1.7rem;
  color: inherit;
  text-align: center;
}

.text-left {
  font-size: 1.7rem;
  color: inherit;
  text-align: left;
}

.text-box {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.strong {
  font-weight: 600;
}

.center {
  text-align: center;
}

/****************/
/* HELPER CLASS */
/****************/

.align__end {
  align-self: flex-end;
}

.hide {
  display: none;
  visibility: hidden;
}

.align__start {
  justify-self: flex-start;
}

.align__center {
  align-items: center;
}

.no-scroll {
  overflow-y: hidden;
}

#submit {
  background-color: var(--color-magenta);
}

.cookie__pref {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 9999999;
  padding: 0.4rem;
  font-size: 1rem;
  text-align: center;
  color: var(--color-magenta);
  background-color: var(--color-magenta);
  border-radius: 50px;
  font-weight: 600;
  line-height: 1;
}

.cookie__pref:hover {
  background-color: var(--color-magenta-light);
}

.cookie__img {
  width: 3rem !important;
  height: 3rem !important;
  display: block;
}

/************/
/*  FILTER  */
/************/

.filter {
  min-width: 100%;
  height: 101%;
  filter: brightness(1);
  backdrop-filter: brightness(0.3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
}

.filter__meeting {
  min-width: 100%;
  min-height: 102%;
  backdrop-filter: brightness(0.4);
  position: absolute;
  z-index: -1;
  left: 0;
  top: -1%;
}

.filter-light {
  width: 100%;
  height: 100%;
  backdrop-filter: brightness(0.55);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.filter__white {
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.map {
  height: 20rem;
  width: 90%;
  margin-top: 3rem;
  border: 1px solid white;
  border-radius: 4px;
}

@media (max-width: 850px) {
  .map {
    height: 22rem;
    width: 104%;
  }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
