import { createWebHistory, createRouter } from "vue-router";

import HomePage from "@/views/HomePage.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import AboutPage from "@/views/AboutPage.vue";
import OfficePage from "@/views/OfficePage.vue";
import MeetingPage from "@/views/MeetingPage.vue";
import CommunityPage from "@/views/CommunityPage.vue";
import ContactPage from "@/views/ContactPage.vue";
import ImprintPage from "@/views/ImprintPage.vue";
import PrivacyPage from "@/views/PrivacyPage.vue";
import ThanksPage from "@/views/ThanksPage.vue";
import BookingPage from "@/views/BookingPage.vue";
import LoginPage from "@/views/LoginPage.vue";

const routes = [
  {
    path: "/",
    name: "Home-Page",
    component: HomePage,
    meta: [
      {
        name: "title",
        content: "WorkDistrict Coworking Amsterdam - Where connections thrive.",
      },
      {
        name: "description",
        content:
          "Founded in 2021 in the heart of Amsterdam, our mission is to provide the best combined experience of working, connecting and easing up the mind. Therefore, we strive to always be the most special provider of not only flexible Coworking spaces and offices but of a whole new working lifestyle.",
      },
      {
        name: "keywords",
        content:
          "WorkDistrict, Coworking, Networking, Office, Community, Meeting-rooms, Connexion, Coworking-Amsterdam, Hekelveld",
      },
      {
        property: "og:title",
        content: "WorkDistrict Coworking Amsterdam - Where connections thrive.",
      },
      {
        property: "og:description",
        content:
          "Founded in 2021 in the heart of Amsterdam, our mission is to provide the best combined experience of working, connecting and easing up the mind. Therefore, we strive to always be the most special provider of not only flexible Coworking spaces and offices but of a whole new working lifestyle.",
      },
      {
        property: "og:site_name",
        content: "WorkDistrict",
      },
    ],
  },
  {
    path: "/About-Page/",
    name: "About-Page",
    component: AboutPage,
    meta: [
      {
        name: "title",
        content: "About - WorkDistrict coworking space",
      },
      {
        name: "description",
        content:
          "Workdistrict is the new way of working! A workspace beyond standards, where you can create your work time as special as you are.",
      },
      {
        name: "keywords",
        content:
          "WorkDistrict, Coworking, Networking, Office, Community, Meeting-rooms, Connexion, Coworking-Amsterdam, Hekelveld",
      },
      {
        property: "og:title",
        content: "About - WorkDistrict coworking space",
      },
      {
        property: "og:description",
        content:
          "Workdistrict is the new way of working! A workspace beyond standards, where you can create your work time as special as you are.",
      },
      {
        property: "og:site_name",
        content: "WorkDistrict",
      },
    ],
  },
  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
    meta: [
      {
        name: "title",
        content: "WorkDistrict coworking Amsterdam",
      },
      {
        name: "description",
        content:
          "This page is still under construction. We are giving our best, in the meantime, maybe you want to explore some other pages in the digital world of Workdistrict? Here are some suggestions that might bet interesting for you:",
      },
      {
        name: "keywords",
        content:
          "WorkDistrict, Coworking, Networking, Office, Community, Meeting-rooms, Connexion, Coworking-Amsterdam, Hekelveld",
      },
      {
        property: "og:title",
        content: "WorkDistrict coworking Amsterdam",
      },
      {
        property: "og:description",
        content:
          "This page is still under construction. We are giving our best, in the meantime, maybe you want to explore some other pages in the digital world of Workdistrict? Here are some suggestions that might bet interesting for you:",
      },
      {
        property: "og:site_name",
        content: "WorkDistrict",
      },
    ],
  },
  {
    path: "/Office-Page",
    name: "Office-Page",
    component: OfficePage,
    meta: [
      {
        name: "title",
        content: "Our office solutions - WorkDistrict coworking space",
      },
      {
        name: "description",
        content:
          "With Workdistrict, you have the flexibility to create the workspace that just fits your needs. You can rather choose to book your own private office for you or your team or if you want to sit in a shared environment and just book a table space.",
      },
      {
        name: "keywords",
        content:
          "WorkDistrict, Coworking, Networking, Office, Community, Meeting-rooms, Connexion, Coworking-Amsterdam, Hekelveld",
      },
      {
        property: "og:title",
        content: "Our office solutions - WorkDistrict coworking space",
      },
      {
        property: "og:description",
        content:
          "With Workdistrict, you have the flexibility to create the workspace that just fits your needs. You can rather choose to book your own private office for you or your team or if you want to sit in a shared environment and just book a table space.",
      },
      {
        property: "og:site_name",
        content: "WorkDistrict",
      },
    ],
  },
  {
    path: "/Meeting-Page",
    name: "Meeting-Page",
    component: MeetingPage,
    meta: [
      {
        name: "title",
        content: "Meeting rooms - WorkDistrict coworking space",
      },
      {
        name: "description",
        content:
          "Just as Workdistrict in total, also all our meeting rooms are equipped with highest quality materials and exclusive furniture. We have a variety of meeting rooms available, differing in size and specialities.",
      },
      {
        name: "keywords",
        content:
          "WorkDistrict, Coworking, Networking, Office, Community, Meeting-rooms, Connexion, Coworking-Amsterdam, Hekelveld",
      },
      {
        property: "og:title",
        content: "Meeting rooms - WorkDistrict coworking space",
      },
      {
        property: "og:description",
        content:
          "Just as Workdistrict in total, also all our meeting rooms are equipped with highest quality materials and exclusive furniture. We have a variety of meeting rooms available, differing in size and specialities.",
      },
      {
        property: "og:site_name",
        content: "WorkDistrict",
      },
    ],
  },
  {
    path: "/Community-Page",
    name: "Community-Page",
    component: CommunityPage,
    meta: [
      {
        name: "title",
        content: "Community matching - WorkDistrict coworking space",
      },
      {
        name: "description",
        content:
          "This is what makes us the best! Workdistrict is more than just a place to work. It is a place to spend time, enjoy and meet others, bring up new connections and network. Therefore, we developed a unique matching software to bring you in contact with other Workdistrict members.",
      },
      {
        name: "keywords",
        content:
          "WorkDistrict, Coworking, Networking, Office, Community, Meeting-rooms, Connexion, Coworking-Amsterdam, Hekelveld",
      },
      {
        property: "og:title",
        content: "Community matching - WorkDistrict coworking space",
      },
      {
        property: "og:description",
        content:
          "This is what makes us the best! Workdistrict is more than just a place to work. It is a place to spend time, enjoy and meet others, bring up new connections and network. Therefore, we developed a unique matching software to bring you in contact with other Workdistrict members.",
      },
      {
        property: "og:site_name",
        content: "WorkDistrict",
      },
    ],
  },
  {
    path: "/Contact-Page",
    name: "Contact-Page",
    component: ContactPage,
    meta: [
      {
        name: "title",
        content: "Contact - WorkDistrict coworking space",
      },
      {
        name: "description",
        content: "Feel free to reach out",
      },
      {
        name: "keywords",
        content:
          "WorkDistrict, Coworking, Networking, Office, Community, Meeting-rooms, Connexion, Coworking-Amsterdam, Hekelveld",
      },
      {
        property: "og:title",
        content: "Contact - WorkDistrict coworking space",
      },
      {
        property: "og:description",
        content: "Feel free to reach out",
      },
      {
        property: "og:site_name",
        content: "WorkDistrict",
      },
    ],
  },
  {
    path: "/Imprint-Page",
    name: "Imprint-Page",
    component: ImprintPage,
    meta: [
      {
        name: "title",
        content: "Imprint - WorkDistrict coworking space",
      },
      {
        name: "description",
        content: "Provider for Workdistrict contents",
      },
      {
        name: "keywords",
        content:
          "WorkDistrict, Coworking, Networking, Office, Community, Meeting-rooms, Connexion, Coworking-Amsterdam, Hekelveld",
      },
      {
        property: "og:title",
        content: "Imprint - WorkDistrict coworking space",
      },
      {
        property: "og:description",
        content: "Provider for Workdistrict contents",
      },
      {
        property: "og:site_name",
        content: "WorkDistrict",
      },
    ],
  },
  {
    path: "/Privacy-Page",
    name: "Privacy-Page",
    component: PrivacyPage,
    meta: [
      {
        name: "title",
        content: "Privacy Policy - WorkDistrict coworking space",
      },
      {
        name: "description",
        content:
          "Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection.",
      },
      {
        name: "keywords",
        content:
          "WorkDistrict, Coworking, Networking, Office, Community, Meeting-rooms, Connexion, Coworking-Amsterdam, Hekelveld",
      },
      {
        property: "og:title",
        content: "Privacy Policy - WorkDistrict coworking space",
      },
      {
        property: "og:description",
        content:
          "Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection.",
      },
      {
        property: "og:site_name",
        content: "WorkDistrict",
      },
    ],
  },
  {
    path: "/thankyou/",
    name: "Thanks-Page",
    component: ThanksPage,
    meta: [
      {
        name: "title",
        content: "Thank you - WorkDistrict coworking space",
      },
      {
        name: "description",
        content:
          "Thank you for contacting us. We appreciate your message and would like to confirm that we have received it. Our team will review your inquiry and respond shortly.",
      },
      {
        name: "keywords",
        content:
          "WorkDistrict, Coworking, Networking, Office, Community, Meeting-rooms, Connexion, Coworking-Amsterdam, Hekelveld",
      },
      {
        property: "og:title",
        content: "Thank you - WorkDistrict coworking space",
      },
      {
        property: "og:description",
        content:
          "Thank you for contacting us. We appreciate your message and would like to confirm that we have received it. Our team will review your inquiry and respond shortly.",
      },
      {
        property: "og:site_name",
        content: "WorkDistrict",
      },
    ],
  },
  {
    path: "/Booking-Page",
    name: "Booking-Page",
    component: BookingPage,
    meta: [
      {
        name: "title",
        content: "Meeting room - WorkDistrict coworking space",
      },
      {
        name: "description",
        content:
          "Book your slot and enjoy the unique atmosphere of our meeting rooms.",
      },
      {
        name: "keywords",
        content:
          "WorkDistrict, Coworking, Networking, Office, Community, Meeting-rooms, Connexion, Coworking-Amsterdam, Hekelveld",
      },
      {
        property: "og:title",
        content: "Meeting room - WorkDistrict coworking space",
      },
      {
        property: "og:description",
        content:
          "Book your slot and enjoy the unique atmosphere of our meeting rooms.",
      },
      {
        property: "og:site_name",
        content: "WorkDistrict",
      },
    ],
  },
  {
    path: "/Login-Page",
    name: "Login-Page",
    component: LoginPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "WorkDistrict";
  next();
});

export default router;
