<template>
  <div v-on:mouseleave="hoverLeave" class="nav__box">
    <ion-icon
      v-on:click="openMenu"
      class="mobile__menu-icon"
      name="menu-outline"
    ></ion-icon>
    <div
      v-on:click="openMenu"
      v-bind:class="{ hide: !isMenuOpen, 'mobile__menu-animate': isMenuOpen }"
      class="mobile__menu"
    >
      <ion-icon class="mobile__menu-close" name="close-outline"></ion-icon>
      <div class="mobile__menu-links">
        <span
          @click="redirectToHomePage"
          v-bind:class="{ hide: !isMenuOpen }"
          class="mobile__menu-link"
          >Home</span
        >
        <span
          @click="redirectToAboutPage"
          v-bind:class="{ hide: !isMenuOpen }"
          class="mobile__menu-link"
          >About</span
        >
        <span
          @click="redirectToOfficePage"
          v-bind:class="{ hide: !isMenuOpen }"
          class="mobile__menu-link"
          >Office solutions</span
        >
        <span
          @click="redirectToMeetingPage"
          v-bind:class="{ hide: !isMenuOpen }"
          class="mobile__menu-link"
          >Meeting rooms</span
        >
        <span
          @click="redirectToCommunityPage"
          v-bind:class="{ hide: !isMenuOpen }"
          class="mobile__menu-link mobile__menu-link-tofix"
          >Community matching</span
        >
        <span
          @click="redirectToContactPage"
          v-bind:class="{ hide: !isMenuOpen }"
          class="mobile__menu-link"
          >Contact</span
        >
        <span
          @click="redirectToBookingPage"
          v-bind:class="{ hide: !isMenuOpen }"
          class="mobile__menu-link"
          >Login</span
        >
      </div>
    </div>
    <img
      @click="redirectToHomePage"
      class="nav__logo"
      src="..\assets\logo\WD_Logo_White.png"
      alt="Workdistrict Logo"
      style="width: 150px; height: 76px"
    />
    <li class="nav__item">
      <router-link class="nav__link padleft" to="About-Page">About</router-link>
    </li>
    <ul class="nav">
      <li v-bind:class="{ align__start: isHovering }" class="nav__item">
        <span
          v-on:mouseover="hoverOn"
          v-bind:class="{ services__hovered: isHovering }"
          class="nav__services"
          >Services</span
        >
        <div
          v-if="isHovering"
          v-on:mouseleave="hoverLeave"
          class="services__hover"
        >
          <div class="hover__item-wrap">
            <router-link class="hover__item" to="Office-Page"
              >Office solutions</router-link
            >
          </div>
          <div class="hover__item-wrap hover__tofix2">
            <router-link class="hover__item" to="Meeting-Page"
              >Meeting rooms</router-link
            >
          </div>
          <div class="hover__item-wrap hover__tofix3">
            <router-link class="hover__item" to="Community-Page"
              >Community matching</router-link
            >
          </div>
        </div>
      </li>
      <li class="nav__item u__flex">
        <router-link
          class="nav__link"
          to="Booking-Page"
          text="Login"
        ></router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isHovering: false,
      isMenuOpen: false,
    };
  },
  components: {},
  methods: {
    hoverOn() {
      this.isHovering = true;
    },
    hoverLeave() {
      this.isHovering = false;
    },
    redirectToHomePage() {
      this.$router.push("/");
    },
    redirectToAboutPage() {
      this.$router.push("About-Page");
    },
    redirectToOfficePage() {
      this.$router.push("Office-Page");
    },
    redirectToMeetingPage() {
      this.$router.push("Meeting-Page");
    },
    redirectToCommunityPage() {
      this.$router.push("Community-Page");
    },
    redirectToContactPage() {
      this.$router.push("Contact-Page");
    },
    redirectToBookingPage() {
      this.$router.push("Booking-Page");
    },
    openMenu() {
      this.isMenuOpen == true
        ? (this.isMenuOpen = false)
        : (this.isMenuOpen = true);
      if (this.isMenuOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
.nav__box {
  display: flex;
  align-items: center;
  font-weight: 400;
}

.nav {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 6.2rem;
  align-items: center;
}

.nav__logo {
  width: 15rem;
  cursor: pointer;
}

.nav__item {
  list-style: none;
  padding-right: -10rem;
}

.nav__item > * {
  font-size: 3.2rem;
  color: inherit;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s;
}

.services__hover {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 8rem;
  z-index: 10;
  transition: all 0.3s;
  cursor: auto;
}

.hover__item {
  color: inherit;
  font-size: 1.8rem;
  transition: all 0.2s;
}

.padleft {
  padding-left: 7rem;
}

.mobile__menu {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  background-color: var(--color-bg);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 101vh;
  z-index: 9999;
  overflow: hidden;
}

.mobile__menu-icon {
  font-size: 4rem;
  position: absolute;
  right: 3rem;
  top: 3rem;
  cursor: pointer;
}

.mobile__menu-close {
  font-size: 5rem;
  position: absolute;
  top: 2.4rem;
  right: 2.4rem;
  --ionicon-stroke-width: 62px;
  cursor: pointer;
}

.mobile__menu-links {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: center;
}

.mobile__menu-link {
  font-size: 3.4rem;
  text-transform: uppercase;
  font-weight: 500;
  transition: all 0.2s;
  cursor: pointer;
}

.mobile__menu-link:hover {
  color: var(--color-magenta);
  transform: scale(1.08);
}

.mobile__menu-link-tofix {
  width: min-content;
  text-align: center;
  line-height: 1;
}

.mobile__menu-animate {
  animation: menu__animate;
  animation-duration: 0.8s;
}

.hover__item:after {
  content: "";
  position: absolute;
  width: 103%;
  height: 2px;
  bottom: 0.5rem;
  left: 0;
  background-color: var(--color-magenta);
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.4s;
  border-radius: 3px;
}

.hover__item:hover:after {
  transform-origin: bottom left;
  transform: scaleX(1);
}

.hover__item-wrap {
  width: max-content;
  position: relative;
}

.hover__tofix2 {
  transform: translateY(-1.1rem);
}

.hover__tofix3 {
  transform: translateY(-2.2rem);
}

.nav__link:hover {
  color: var(--color-magenta);
}

.services__hovered {
  color: var(--color-magenta);
}

@keyframes menu__animate {
  from {
    top: -100rem;
  }
  to {
    top: 0;
  }
}

@media (max-width: 780px) {
  .nav {
    display: none;
  }

  .nav__logo {
    transform: translate(-5rem, 0.7rem);
    width: 13rem;
  }

  .nav__item {
    display: none;
  }
}

@media (min-width: 780px) {
  .mobile__menu-icon {
    display: none;
  }

  .mobile__menu {
    display: none;
  }
}
</style>
