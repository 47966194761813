// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Booking, Slot, User, Company, Room } = initSchema(schema);

export {
  Booking,
  Slot,
  User,
  Company,
  Room
};