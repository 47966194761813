<template>
  <a
    class="cookie__pref"
    href="#"
    onclick="window.displayPreferenceModal();return false;"
    id="termly-consent-preferences"
    ><img
      class="cookie__img"
      src="../assets/images/office/cookie-icon.png"
      alt=""
  /></a>
  <!-- SECTION HERO -->
  <div id="top" class="section__about">
    <div class="filter"></div>
    <NavVue></NavVue>
    <div class="about__content">
      <TitleVue class="about__title" content="Who is Workdistrict?"></TitleVue>
      <p class="about__text text">
        Workdistrict is the new way of working! A workspace beyond standards,
        where you can create your work time as special as you are.
      </p>
    </div>
  </div>
  <section class="section section__mission">
    <div class="mission__box">
      <TitleVue
        class="mission__title"
        content="our mission & vision"
      ></TitleVue>
      <p class="mission__text text">
        We want to provide the next level of workplaces. Our mission is to make
        you feel home at work and to ease up your day by providing the workplace
        of the future, where you can focus without the need to take care of
        organizational things. Our vision is to spread out our unique
        tenant-matching and becoming the first network-based workplace.
      </p>
    </div>
  </section>

  <div v-bind:class="{ hide: !isModalOpen }" class="mmodal__container">
    <span @click="changeLeft" class="arr arrLeft">&#10095;</span>
    <span @click="changeRight" class="arr arrRight">&#10095;</span>
    <div class="mmodal">
      <div class="mmodal__box">
        <img class="mmodal__img" :src="currentImage" alt="This is an image" />
        <span class="mmodal__tab"
          >{{ currentIndex + 1 }}/{{ images.length }}</span
        >
      </div>
      <div @click="changeLeft" class="mmodal__left"></div>
      <div @click="changeRight" class="mmodal__right"></div>
    </div>
  </div>

  <span
    v-bind:class="{ hide: !isModalOpen }"
    @click="modalHandler"
    class="mmodal__closer"
    >&#10006;</span
  >

  <section class="section section__concept">
    <div class="text-box about__text-tofix">
      <TitleVue content="Our Concept"></TitleVue>
      <p class="text concept__text">
        Our concept is as easy as flexible: You become a member of the
        Workdistrict for a fixed price per month – no hidden costs and
        everything inclusive: You can choose an office that suits your space-
        and table-needs, will have access to the community areas, free coffee
        and snacks and a fixed amount of meeting room bookings per month. Of
        course, your plan is individually customizable according to your needs.
        Additionally, Workdistrict comes along with our unique tenant-matching:
        Make new contacts and widen your business network by finding the most
        interesting peers just in the office next door.
      </p>
    </div>

    <!--
  <div class="concept__photo-box">
    <div class="concept__photo-row">
    <img @click="modalHandler0" class="concept__photo" src="..\assets\images\office\workdistrict-coworking-hekelveld-building.webp" alt="Workdistrict office in the earth of Amsterdam">
    <img @click="modalHandler6" class="concept__photo" src="../assets/images/office/workdistrict-meeting-room-jungle.webp" alt="Workdistrict meeting room on the jungle">
  </div>
  <div class="concept__photo-row">
    <img @click="modalHandler2" class="concept__photo" src="..\assets\images\office\workdistrict-coworking-sofa-room.webp" alt="Workdistrict office cosy sofa room">
    <img @click="modalHandler1" class="concept__photo" src="..\assets\images\office\workdistrict-coworking-living-room.webp" alt="Workdistrict office living room">
    </div>
  </div>
-->

    <div class="community__photo-box">
      <div class="community__photo-row">
        <picture @click="modalHandler0" class="community__photo">
          <source
            srcset="
              ..\assets\images\office\workdistrict-coworking-hekelveld-building-mod.webp
            "
            media="(min-width: 1600px)"
          />
          <img
            src="..\assets\images\office\workdistrict-coworking-hekelveld-building.webp"
            alt="Workdistrict office in the earth of Amsterdam"
          />
        </picture>
        <picture @click="modalHandler6" class="community__photo">
          <source
            srcset="
              ../assets/images/office/workdistrict-meeting-room-jungle-mod.webp
            "
            media="(min-width: 1600px)"
          />
          <img
            src="../assets/images/office/workdistrict-meeting-room-jungle.webp"
            alt="Workdistrict meeting room on the jungle"
          />
        </picture>
      </div>
      <div class="community__photo-row">
        <picture @click="modalHandler2" class="community__photo">
          <source
            srcset="
              ..\assets\images\office\workdistrict-coworking-sofa-room-mod.webp
            "
            media="(min-width: 1600px)"
          />
          <img
            src="..\assets\images\office\workdistrict-coworking-sofa-room.webp"
            alt="Workdistrict office cosy sofa room"
          />
        </picture>
        <picture @click="modalHandler1" class="community__photo">
          <source
            srcset="
              ..\assets\images\office\workdistrict-coworking-living-room-mod.webp
            "
            media="(min-width: 1600px)"
          />
          <img
            src="..\assets\images\office\workdistrict-coworking-living-room.webp"
            alt="Workdistrict office living room"
          />
        </picture>
      </div>
    </div>
  </section>

  <section class="section__location">
    <!-- <MapVue class="location__map"/> -->

    <!-- <iframe class="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9742.35829423001!2d4.8952683!3d52.3778617!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c60936ade4498f%3A0x4a59350491852e87!2sWorkdistrict!5e0!3m2!1sfr!2snl!4v1681295392375!5m2!1sfr!2snl&hl=en" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>-->
    <iframe
      class="map location__map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2435.589394483286!2d4.892693376985359!3d52.37786494674263!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c60936ade4498f%3A0x4a59350491852e87!2sWorkdistrict!5e0!3m2!1sen!2snl!4v1681804056677!5m2!1sen!2snl"
      width="600"
      height="450"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>

    <div class="location__content">
      <TitleVue content="Locations"></TitleVue>
      <p class="text location__text">
        Workdistrict is still growing! Our very first location is in Hekelveld
        8-10 in Amsterdam.
      </p>
      <p class="text location__text">
        An amazing, freshly renovated venue within 2 walking minutes from
        Amsterdam Main Station.
      </p>
      <p class="text location__text">
        We are already in the expansion phase, stay tuned for future locations.
      </p>
    </div>
  </section>
  <FooterVue></FooterVue>
</template>

<script>
import image1 from "@/assets/images/office/workdistrict-coworking-hekelveld-building-big.webp";
import image2 from "@/assets/images/office/workdistrict-coworking-living-room-big.webp";
import image3 from "@/assets/images/office/workdistrict-coworking-sofa-room-big.webp";
import image4 from "@/assets/images/office/workdistrict-coworking-space-room-big.webp";
import image5 from "@/assets/images/office/workdistrict-meeting-room-earth-big.webp";
import image6 from "@/assets/images/office/workdistrict-meeting-room-water-big.webp";
import image7 from "@/assets/images/office/workdistrict-meeting-room-jungle-big.webp";

import NavVue from "../components/NavVue.vue";
import FooterVue from "../components/FooterVue.vue";
import TitleVue from "../components/TitleVue.vue";
// import MapVue from '../components/MapVue.vue'

export default {
  data() {
    return {
      isModalOpen: false,
      images: [image1, image2, image3, image4, image5, image6, image7],
      currentIndex: 0,
    };
  },
  name: "AboutPage",
  components: {
    NavVue,
    FooterVue,
    TitleVue,
    // MapVue,
  },
  methods: {
    modalHandler() {
      this.isModalOpen == true
        ? (this.isModalOpen = false)
        : (this.isModalOpen = true);
      if (this.isModalOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    modalHandler0() {
      this.currentIndex = 0;
      this.isModalOpen == true
        ? (this.isModalOpen = false)
        : (this.isModalOpen = true);
      if (this.isModalOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    modalHandler1() {
      this.currentIndex = 1;
      this.isModalOpen == true
        ? (this.isModalOpen = false)
        : (this.isModalOpen = true);
      if (this.isModalOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    modalHandler2() {
      this.currentIndex = 2;
      this.isModalOpen == true
        ? (this.isModalOpen = false)
        : (this.isModalOpen = true);
      if (this.isModalOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    modalHandler6() {
      this.currentIndex = 6;
      this.isModalOpen == true
        ? (this.isModalOpen = false)
        : (this.isModalOpen = true);
      if (this.isModalOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    changeLeft() {
      if (this.currentIndex == 0) {
        this.currentIndex = this.images.length - 1;
      } else {
        this.currentIndex = this.currentIndex - 1;
      }
    },
    changeRight() {
      if (this.currentIndex == this.images.length - 1) {
        this.currentIndex = 0;
      } else {
        this.currentIndex = this.currentIndex + 1;
      }
    },
  },
  computed: {
    currentImage() {
      return this.images[this.currentIndex];
    },
  },
  mounted() {
    document.body.style.overflowX = "hidden";
    document.getElementById("top").scrollIntoView();
  },
};
</script>

<style>
.section__about {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/office/workdistrict-coworking-wall-neon.webp");
  background-position: center;
  background-size: cover;
  backdrop-filter: brightness(0.4);
  padding: 2rem 8rem;
  position: relative;
}

.section__mission {
  background-color: var(--color-timber);
  position: relative;
  padding: 6rem 6rem;
  min-height: 33rem;
}

.section__concept {
  color: var(--color-black);
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: auto;
  padding-top: 4rem;
}

.section__location {
  background-color: var(--color-timber);
  display: flex;
  min-height: auto;
  gap: 3rem;
  align-items: center;
  padding: 6rem 6rem 5rem 8rem;
}

.about__content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about__text {
  width: 70%;
}

.mission__text {
  width: 65%;
}

.mission__box {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.concept__photo-box {
  display: flex;
  gap: 1.2rem;
}

.concept__photo-row {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.concept__photo {
  max-width: 20rem;
  transition: all 0.3s;
  cursor: pointer;
}

.concept__photo:hover {
  transform: scale(1.08);
}

.location__content {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.location__map {
  width: 120rem !important;
  border-radius: 2px;
  height: 27rem;
}

/*********/
/* MEDIA */
/*********/

/*
@media (min-width: 1300px) {
  .section__concept {
    padding-right: 15rem;
  }
}

@media (min-width: 1400px) {
  .section__concept {
    padding-right: 25rem;
  }
}

@media (min-width: 1500px) {
  .section__concept {
    padding-right: 35rem;
  }
}
*/

@media (max-width: 1080px) {
  .section__concept {
    flex-direction: column;
    gap: 5rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .concept__text {
    width: 90%;
    text-align: left;
  }

  .concept__photo {
    max-width: 32rem;
  }

  .location__text {
    text-align: left;
  }

  .location__map {
    width: 80rem !important;
  }

  .about__text {
    width: 100%;
  }
}

@media (max-width: 980px) {
  .section__location {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .mission__title {
    width: 100%;
  }

  .concept__photo {
    max-width: 27rem;
  }

  .location__map {
    width: 70rem !important;
  }
}

@media (max-width: 800px) {
  .section__location {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .section__mission {
    padding-left: 5rem;
    padding-right: 4rem;
  }

  .section__concept {
    padding-left: 2rem;
    padding-right: 4rem;
  }

  .concept__photo {
    max-width: 24rem;
  }

  .mission__box {
    align-items: flex-start;
  }

  .mission__text {
    text-align: left;
    width: 80%;
  }

  .mission__title {
    text-align: left;
  }

  .about__title {
    width: 100%;
  }

  .about__text {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .section__location {
    padding-left: 3rem;
    padding-right: 2rem;
    padding-top: 4.4rem;
  }

  .section__mission {
    padding-left: 3rem;
    padding-right: 2rem;
  }

  .section__concept {
    padding-left: 3rem;
    padding-right: 2rem;
    align-items: flex-start;
    padding-bottom: 3rem;
    gap: 2rem;
  }

  .concept__photo {
    max-width: 20rem;
  }
}

@media (max-width: 600px) {
  .concept__photo {
    max-width: 20rem;
  }

  .section__mission {
    padding-top: 4rem;
  }
}

@media (max-width: 470px) {
  .concept__photo {
    max-width: 15rem;
  }
}

@media (max-width: 700px) {
  .location__map {
    display: none !important;
  }

  .section__about {
    background-attachment: initial;
  }
}

@media (min-width: 1200px) {
  .location__content {
    padding-right: 15rem;
  }
}

@media (max-width: 1400px) {
  .location__content {
    transform: translateY(1rem);
  }
}

@media screen and (min-width: 1020px) and (max-width: 1080px) {
  .community__photo-box {
    display: none;
  }
}
</style>
