<template>
  <router-link class="btn btn__grad" to="to">{{ text }}</router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
    },
    text: {
      type: String,
      required: true,
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
.btn {
  padding: 1.2rem 3.2rem;
  color: white;
  background-color: var(--color-magenta);
  cursor: pointer;
  display: inline-block;
  border-radius: 5px;
  font-size: 2rem;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 1.5rem;
  transition: all 0.3s;
}

.btn:hover {
  transform: scale(1.09);
  font-weight: 500;
}

.btn__this {
  align-self: left !important;
}
</style>
