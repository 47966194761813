<template>
  <a
    class="cookie__pref"
    href="#"
    onclick="window.displayPreferenceModal();return false;"
    id="termly-consent-preferences"
    ><img
      class="cookie__img"
      src="../assets/images/office/cookie-icon.png"
      alt=""
  /></a>
  <div id="top" class="section section__meeting-room">
    <div class="filter"></div>
    <NavVue></NavVue>
    <div class="about__content meeting__content">
      <TitleVue
        class="meeting__title"
        content="Discover our stunning and luxurious meeting rooms!"
      ></TitleVue>
      <p class="meeting__text text">
        Just as Workdistrict in total, also all our meeting rooms are equipped
        with highest quality materials and exclusive furniture. We have a
        variety of meeting rooms available, differing in size and specialities.
        Browse through the options and select the one that is best-fitting for
        you. If you have an important meeting, you can also book your favourite
        room in advance to make sure it will be available on the needed
        time-spot.
      </p>
    </div>
  </div>
  <section class="meeting__room-box">
    <!-- 1 - THE JUNGLE -->
    <div class="meeting__room-item meeting__room-item1">
      <div class="filter__meeting"></div>
      <div class="meeting__bar meeting__bar1">
        <p class="text-left">
          Inspired by the wilds, our Jungle room is the perfect event space for
          corporate events, internal incentive events or also our Workdistrict
          community events. Of course, it also fits perfectly for a large
          meeting with a big team included. It is fully equipped with a large
          table with power sources included, two TV screens for presentations
          and more as well as a Kitchen free to use.
        </p>
        <span
          @click="readMoreHandler1"
          v-if="!shouldDisplay1"
          class="meeting__readmore"
          >Read more</span
        >
        <span @click="readMoreHandler1" v-if="readMore1" class="meeting__close"
          >X</span
        >
        <ul class="meeting__room-ul" v-if="shouldDisplay1">
          <li class="text-left">Floor: Basement</li>
          <li class="text-left">
            Capacity: up to 70 people standing and 20 sitting
          </li>
          <li class="text-left">
            Fully furnished kitchen with power, warm water and refrigerators
          </li>
          <li class="text-left">
            Separate entrance directly from the street available
          </li>
          <li class="text-left">
            Customizable to fit your event, let us know what you need
          </li>
        </ul>
      </div>
      <h2 class="meeting__room-title translate__helper1">The Jungle</h2>
      <img
        class="meeting__room-img meeting__room-img1 translate__helper-img1"
        src="../assets/images/office/workdistrict-meeting-room-table-mid.webp"
        alt="workdistrict jungle meeting room furnished with a big table"
      />
    </div>
    <!-- 2 - IN SPACE -->
    <div
      v-bind:class="{ meeting__room_itemhelper: readMore2 }"
      class="meeting__room-item meeting__room-item2"
    >
      <div class="filter__meeting"></div>
      <div class="meeting__bar meeting__bar2">
        <p class="text-left">
          If standard meetings in standard rooms are too less of an experience
          for you, your team or your clients, fly up to space to hold your
          meeting. The space room lifts you in spherical moods and creates a
          special footprint in the minds of all attendees. Through its nice
          theme, the space room is perfectly for client meetings but also
          provides a nice atmosphere for team meetings or reviews.
        </p>
        <span
          @click="readMoreHandler2"
          v-if="!shouldDisplay2"
          class="meeting__readmore"
          >Read more</span
        >
        <span @click="readMoreHandler2" v-if="readMore2" class="meeting__close"
          >X</span
        >
        <ul class="meeting__room-ul" v-if="shouldDisplay2">
          <li class="text-left">Floor: Basement</li>
          <li class="text-left">
            Capacity: up to 8 people, extended to 14 people.
          </li>
          <li class="text-left">
            Long table, perfect for concentration meetings or negotiations.
          </li>
          <li class="text-left">
            Access to the partly roofed basement terrace.
          </li>
          <li class="text-left">
            Dynamic capacity, combinable with On Earth room for larger meetings.
          </li>
        </ul>
      </div>
      <h2 class="meeting__room-title translate__helper2">In Space</h2>
      <img
        class="meeting__room-img meeting__room-img2 translate__helper-img2"
        src="..\assets\images\office\workdistrict-coworking-space-monkey-mid.webp"
        alt="Workdistrict monkey decoration"
      />
    </div>
    <!-- 3 - ON EARTH -->
    <div
      v-bind:class="{ meeting__room_itemhelper: readMore3 }"
      class="meeting__room-item meeting__room-item3"
    >
      <div class="filter__meeting"></div>
      <div class="meeting__bar meeting__bar3">
        <p class="text-left">
          Did you ever experience the beautiful silence and atmosphere of a deep
          forest? In the forest room, you can combine this atmosphere with your
          next meeting. Do you want to control the setting of the meeting and
          set the atmosphere directly from start on? Then the forest with its
          overwhelming surrounding is perfect for you!
        </p>
        <span
          @click="readMoreHandler3"
          v-if="!shouldDisplay3"
          class="meeting__readmore"
          >Read more</span
        >
        <span @click="readMoreHandler3" v-if="readMore3" class="meeting__close"
          >X</span
        >
        <ul class="meeting__room-ul" v-if="shouldDisplay3">
          <li class="text-left">Floor: Basement</li>
          <li class="text-left">
            Capacity: up to 8 people, extended up to 14 people
          </li>
          <li class="text-left">
            Long table, perfect for concentration meetings or discussions
          </li>
          <li class="text-left">
            Direct access to the partly roofed basement terrace e.g. for
            fresh-air breaks
          </li>
          <li class="text-left">
            Dynamic capacity, combineable with the Space room for larger
            meetings
          </li>
        </ul>
      </div>
      <h2 class="meeting__room-title translate__helper3">On Earth</h2>
      <img
        class="meeting__room-img meeting__room-img3 translate__helper-img3"
        src="..\assets\images\office\workdistrict-meeting-room-earth-mid.webp"
        alt="Workdistrict meeting room on earth"
      />
    </div>
    <!-- 4 - UNDER WATER -->
    <div
      v-bind:class="{ meeting__room_itemhelper: readMore4 }"
      class="meeting__room-item meeting__room-item4"
    >
      <div class="filter__meeting"></div>
      <div class="meeting__bar meeting__bar4">
        <p class="text-left">
          Dive under water for your one-to-one meeting, a small team meeting or
          a calling session. The underwater world is located on second floor of
          the Workdistrict and is equipped with a round table and a mounted TV
          screen free for the use case you need.
        </p>
        <span
          @click="readMoreHandler4"
          v-if="!shouldDisplay4"
          class="meeting__readmore"
          >Read more</span
        >
        <span @click="readMoreHandler4" v-if="readMore4" class="meeting__close"
          >X</span
        >
        <ul class="meeting__room-ul" v-if="shouldDisplay4">
          <li class="text-left">Floor: second floor</li>
          <li class="text-left">Capacity: up to 4 people</li>
          <li class="text-left">roundtable for the best team communication</li>
          <li class="text-left">
            Perfect for smaller meeetings directly in second floor
          </li>
        </ul>
      </div>
      <h2 class="meeting__room-title translate__helper4">Under Water</h2>
      <img
        class="meeting__room-img meeting__room-img4 translate__helper-img4"
        src="..\assets\images\office\workdistrict-meeting-room-water-big.webp"
        alt="Workdistrict meeting room underwater"
      />
    </div>
  </section>
  <!-- FOOTER -->
  <FooterVue></FooterVue>
</template>

<script>
import NavVue from "../components/NavVue.vue";
import FooterVue from "../components/FooterVue.vue";
import TitleVue from "../components/TitleVue.vue";

export default {
  name: "MeetingPage",
  data() {
    return {
      readMore1: false,
      readMore2: false,
      readMore3: false,
      readMore4: false,
    };
  },
  components: {
    NavVue,
    FooterVue,
    TitleVue,
  },
  methods: {
    readMoreHandler1() {
      this.readMore1 == true
        ? (this.readMore1 = false)
        : (this.readMore1 = true);
    },
    readMoreHandler2() {
      this.readMore2 == true
        ? (this.readMore2 = false)
        : (this.readMore2 = true);
    },
    readMoreHandler3() {
      this.readMore3 == true
        ? (this.readMore3 = false)
        : (this.readMore3 = true);
    },
    readMoreHandler4() {
      this.readMore4 == true
        ? (this.readMore4 = false)
        : (this.readMore4 = true);
    },
  },
  computed: {
    shouldDisplay1() {
      return this.readMore1 || window.innerWidth > 1020;
    },
    shouldDisplay2() {
      return this.readMore2 || window.innerWidth > 1020;
    },
    shouldDisplay3() {
      return this.readMore3 || window.innerWidth > 1020;
    },
    shouldDisplay4() {
      return this.readMore4 || window.innerWidth > 1020;
    },
  },
  mounted() {
    document.body.style.overflowX = "hidden";
    document.getElementById("top").scrollIntoView();
  },
};
</script>

<style>
.section__meeting-room {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/office/workdistrict-coworking-space-room-big.webp");
  background-position: top 20%;
  background-size: cover;
  backdrop-filter: brightness(0.45);
  padding: 2rem 8rem;
  min-height: auto;
}

.meeting__room-box {
  position: relative;
  display: flex;
  flex-direction: column;
}

.meeting__room-title {
  font-size: 5.4rem;
  text-transform: uppercase;
  font-weight: 600;
  text-shadow: 2px 2px 3px rgb(0, 0, 0);
}

.meeting__room-img {
  width: 40rem;
  border: 7px solid var(--color-timber);
  z-index: 100;
}

.meeting__room-item {
  background-size: cover;
  height: auto;
  backdrop-filter: brightness(0.45);
  display: flex;
  gap: 2rem;
  flex-direction: column;
  padding: 3.4rem 4.4rem;
  position: relative;
}

.meeting__room-item1 {
  background-image: url("../assets/images/office/workdistrict-meeting-room-jungle.webp");
}

.meeting__room-item2 {
  background-image: url("../assets/images/office/workdistrict-coworking-space-area.webp");
}

.meeting__room-item3 {
  background-image: url("../assets/images/office/workdistrict-meeting-room-earth-big.webp");
}

.meeting__room-item4 {
  background-image: url("../assets/images/office/workdistrict-meeting-room-fish.webp");
}

.meeting__bar {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 101%;
  z-index: 8;
  backdrop-filter: brightness(0.35);
  display: flex;
  gap: 4rem;
  flex-direction: column;
  padding: 4rem 2rem;
  line-height: 1.6;
}

.meeting__close {
  background-color: #726d6d8c;
  padding: 0.2rem 0.9rem;
  font-weight: 600;
  font-size: 1.4rem;
  cursor: pointer;
  border-radius: 100px;
  font-weight: 600;
  z-index: 9999999;
  width: min-content;
  position: relative;
  transform: translateX(-1rem);
}

.meeting__close:after {
  color: #fff;
  content: "CLOSE";
  font-size: 1rem;
  position: absolute;
  z-index: -1;
  left: 4rem;
  top: 0.5rem;
  margin: auto;
  width: 1.1em;
  height: 1.1em;
}

.meeting__readmore {
  text-decoration: none;
  color: #ffffff;
  text-transform: uppercase;
  position: relative;
  margin-left: 2em;
  padding: 5px 23px 5px 10px;
  transition: all 0.6s ease;
  cursor: pointer;
}

.meeting__readmore:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: -3em;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #726d6d8c;
  width: 2.6em;
  height: 2.6em;
  border-radius: 5em;
  transition: all 0.6s cubic-bezier(0.615, 0, 0.07, 1);
}
.meeting__readmore:after {
  color: #fff;
  content: "+";
  font-size: 2.6em;
  position: absolute;
  z-index: -1;
  left: -0.96em;
  bottom: 0.6rem;
  margin: auto;
  width: 1.1em;
  height: 1.1em;
}

.meeting__readmore:hover {
  transition: all 0.6s ease;
  transition-delay: 0.3s;
  color: #fff;
}

.meeting__readmore:hover:before {
  transition: all 0.6s cubic-bezier(0.615, 0, 0.07, 1);
  width: 12.1rem;
}

.meeting__content {
  width: 50% !important;
}

/******************/
/*  MEDIA QUERIES */
/******************/

/*  - THAN 880 PX*/
@media (max-width: 880px) {
  .meeting__room-item {
    max-height: 20rem !important;
  }

  .meeting__bar {
    width: 100%;
    height: 100%;
    top: 50%;
    left: 0;
    padding-top: 1rem;
    padding-bottom: 8rem;
  }

  .meeting__room-img {
    min-width: 50rem;
    margin: 0 auto;
    margin-top: -2rem;
  }

  .meeting__room-title {
    margin: 0 auto;
    margin-top: -3rem;
  }
}

@media (max-width: 800px) {
  .meeting__content {
    width: 80%;
  }
}

@media (max-width: 680px) {
  .meeting__content {
    top: 55rem;
  }

  .meeting__content {
    width: 80% !important;
  }

  .meeting__room-ul {
    transform: translateY(-4rem);
  }

  .meeting__close {
    transform: translate(-1rem, -2rem);
  }

  .meeting__readmore {
    transform: translateY(-2rem);
  }
}

/* + THAN 1190 PX*/
@media (min-width: 1190px) {
  .meeting__title {
    width: 70%;
  }

  .meeting__room-item {
    flex-direction: row;
    gap: 0;
    align-items: center;
    justify-content: flex-start;
    height: 60rem;
  }

  .meeting__content {
    width: 100%;
  }

  .meeting__room-title {
    transform: rotate(-90deg);
    width: min-content;
    height: min-content;
    line-height: 1;
    text-align: center;
    flex-grow: revert;
    font-size: 8rem;
    padding: 0;
  }

  .meeting__bar {
    width: 30%;
    right: 5%;
    padding-top: 4rem;
  }

  .meeting__room-img {
    min-width: 52rem;
  }

  .translate__helper1 {
    transform: translateX(-10rem) rotate(-90deg);
  }

  .translate__helper2 {
    transform: translateX(-6.4rem) rotate(-90deg);
  }

  .translate__helper3 {
    transform: translateX(-7.1rem) rotate(-90deg);
  }

  .translate__helper4 {
    transform: translateX(-7.3rem) rotate(-90deg);
  }

  .translate__helper-img1 {
    transform: translateX(-9.7rem);
  }

  .translate__helper-img2 {
    transform: translateX(-3.1rem);
  }

  .translate__helper-img3 {
    transform: translateX(-4.5rem);
  }

  .translate__helper-img4 {
    transform: translateX(-5.7rem);
  }
}

/* + THAN 1590 PX*/

@media (min-width: 1590px) {
  .meeting__title {
    width: 60%;
  }

  .meeting__text {
    width: 70%;
  }

  .meeting__room-item {
    flex-direction: row;
    gap: 0;
    align-items: center;
    justify-content: flex-start;
    height: 65rem;
  }

  .meeting__room-title {
    transform: rotate(-90deg);
    width: min-content;
    height: min-content;
    line-height: 1;
    text-align: center;
    flex-grow: revert;
    font-size: 9rem;
    padding: 0;
  }

  .meeting__bar {
    width: 30%;
    right: 5%;
    padding-top: 16rem;
  }

  .meeting__room-img {
    min-width: 65rem;
  }

  .translate__helper1 {
    transform: translateX(-7rem) rotate(-90deg);
  }

  .translate__helper2 {
    transform: translateX(-3.4rem) rotate(-90deg);
  }

  .translate__helper3 {
    transform: translateX(-4.1rem) rotate(-90deg);
  }

  .translate__helper4 {
    transform: translateX(-4.3rem) rotate(-90deg);
  }

  .translate__helper-img1 {
    transform: translateX(0rem);
  }

  .translate__helper-img2 {
    transform: translateX(7.3rem);
  }

  .translate__helper-img3 {
    transform: translateX(5.7rem);
  }

  .translate__helper-img4 {
    transform: translateX(4.4rem);
  }
}

@media (max-width: 580px) {
  .meeting__room-img {
    min-width: 15rem !important;
  }
}

@media (max-width: 400px) {
  .meeting__room-title {
    line-height: 1;
  }
}

@media (max-width: 1080px) {
  .meeting__room-item {
    min-height: 80rem !important;
  }
}

@media (max-width: 879px) {
  .meeting__room-item {
    min-height: 90rem !important;
    padding-bottom: 7rem;
  }
}

@media (max-width: 700px) {
  .meeting__room-item {
    min-height: 95rem !important;
    padding-bottom: 10rem !important ;
  }
}

@media (max-width: 600px) {
  .meeting__bar {
    transform: translateY(-6rem);
  }

  .meeting__title {
    line-height: 1.1;
  }
}

@media (max-width: 500px) {
  .meeting__bar2,
  .meeting__bar3 {
    transform: translateY(-18rem);
  }

  .meeting__room-title {
    line-height: 1.1;
    text-align: center;
  }

  .meeting__room-photo {
    transform: translateY(18rem);
  }

  .meeting__room-box {
    overflow: hidden;
  }

  .meeting__room-item4 {
    max-height: 50rem !important;
  }

  .meeting__bar {
    padding-bottom: 50rem !important;
  }

  .meeting__bar1,
  .meeting__bar4 {
    transform: translateY(-9rem);
  }
}
</style>
