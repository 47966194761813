<template>
  <a
    class="cookie__pref"
    href="#"
    onclick="window.displayPreferenceModal();return false;"
    id="termly-consent-preferences"
    ><img
      class="cookie__img"
      src="../assets/images/office/cookie-icon.png"
      alt=""
  /></a>
  <div id="top" class="section section__contact">
    <NavVue></NavVue>
    <h1 class="contact__title">Feel free to reach out</h1>

    <form
      id="contactform"
      class="contact__form"
      method="post"
      action="https://hockus.com/contactform_handling/contact.php"
    >
      <label id="First name label" for="firstname">First name*</label>
      <input
        class="contact__helper-text"
        type="text"
        id="firstname"
        name="firstname"
        required
      />

      <label id="Last name label" for="lastname">Last name*</label>
      <input
        class="contact__helper-text"
        type="text"
        id="lastname"
        name="lastname"
        required
      />

      <label id="email label" for="email">Your email*</label>
      <input
        class="contact__helper-email"
        type="email"
        id="email"
        name="email"
        required
      />

      <label id="phone label" for="phonenumber">Phone number*</label>
      <input
        class="contact__helper-tel"
        type="tel"
        id="phonenumber"
        name="phonenumber"
        required
      />

      <label id="company label" for="company">Your company name*</label>
      <input
        class="contact__helper-text"
        type="text"
        id="company"
        name="company"
        required
      />

      <label id="inquiryabout labelemai" for="inquiryabout"
        >Inquiry about*</label
      >
      <select
        id="inquiryabout"
        name="inquiryabout"
        placeholder="Please select an option"
        v-model="selectedOption"
        required
      >
        <option value="select" selected disabled hidden>
          Please select an option
        </option>
        <option value="singleDesk">Single Desk</option>
        <option value="privateOffice">Private Office</option>
        <option value="teamSuite">Team Suite</option>
        <option value="dailyPass">Daily Pass</option>
        <option value="multiPass">Multi Pass</option>
        <option value="feedback">Feedback</option>
        <option value="other">Other</option>
      </select>

      <label id="yourmessage label" for="yourmessage"
        >Your message / additional information (optional)</label
      >
      <input type="textarea" id="yourmessage" name="yourmessage" />

      <div class="checkbox-box">
        <input
          value="I agree"
          class="checkbox"
          name="privacyconsent"
          id="privacyconsent"
          type="checkbox"
          required
        />
        <label id="consent" for="privacyconsent"
          >I agree to the use of my data according to the
          <span @click="redirectToPrivacyPage" class="contact__link"
            >privacy policy</span
          >
          in order to get back to me for my request*</label
        >
      </div>

      <input
        class="form__btn btn__modern btn__helper btn__this"
        type="submit"
        id="submit"
        value="Submit"
      />
    </form>
  </div>
  <FooterVue></FooterVue>
</template>

<script>
import NavVue from "../components/NavVue.vue";
import FooterVue from "../components/FooterVue.vue";

export default {
  name: "ContactPage",
  data() {
    return {
      selectedOption: "select",
    };
  },
  components: {
    NavVue,
    FooterVue,
  },
  methods: {
    redirectToPrivacyPage() {
      this.$router.push("Privacy-Page");
    },
  },
  created() {
    const preSelectedOption = this.$route.query.preSelected;
    if (preSelectedOption) {
      this.selectedOption = preSelectedOption;
    }
  },
  mounted() {
    document.body.style.overflowX = "hidden";
    document.getElementById("top").scrollIntoView();
  },
};
</script>

<style scoped>
.section__contact {
  background-color: var(--color-bg);
  padding-left: 8rem;
  padding-right: 8rem;
}

.contact__title {
  font-size: 3.6rem;
  margin-top: 8rem;
  margin-bottom: 2.2rem;
}

.contact__form {
  display: flex;
  flex-direction: column;
}

.contact__helper-text,
.contact__helper-tel,
.contact__helper-email,
select {
  height: 4.2rem;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  border-radius: 6px;
}

input[type="textarea"] {
  height: 8.4rem;
}

label {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.checkbox-box {
  display: flex;
  gap: 1.5rem;
  align-items: flex-start;
  margin-top: 2rem;
}

.checkbox {
  width: 2.2rem;
  height: 2.2rem;
}

.contact__link {
  cursor: pointer;
}

/*********/
/* MEDIA */
/*********/

@media (max-width: 800px) {
  input[type="text"],
  select,
  input[type="tel"],
  input[type="email"] {
    height: 5.2rem;
  }

  .contact__title {
    line-height: 1;
    transform: translateX(-4rem);
  }

  .contact__form {
    transform: translateX(-4rem);
    width: 105%;
  }

  .checkbox {
    transform: scale(3);
  }
}

@media (min-width: 1200px) {
  .contact__form {
    width: 110rem;
    align-self: center;
  }

  .section__contact {
    display: flex;
    flex-direction: column;
  }

  .contact__title {
    padding-left: 5rem;
    margin-top: 10rem;
    align-self: center;
  }
}

@media (max-width: 470px) {
  .contact__form {
    width: 120%;
  }
}
.btn__this {
  align-self: left !important;
}
</style>
