<template>
  <!-- SECTION HERO -->
  <section class="section__hero notFound__tofix">
    <div class="filter"></div>
    <NavVue></NavVue>
    <div class="notFound__content">
      <h1 class="notFound__title">Sorry!</h1>
      <p class="notFound__text">
        This page is still under construction. We're giving our best, in the
        meantime, maybe you want to explore some other pages in the digital
        world of Workdistrict? Here are some suggestions that might bet
        interesting for you:
      </p>
      <div class="notFound__links">
        <ButtonVue
          class="notFound__link"
          @click="redirectToHomePage"
          text="Home Page"
        ></ButtonVue>
        <ButtonVue
          class="notFound__link"
          @click="redirectToAboutPage"
          text="About us"
        ></ButtonVue>
        <ButtonVue
          class="notFound__link"
          @click="redirectToOfficePage"
          text="Our offices"
        ></ButtonVue>
        <ButtonVue
          class="notFound__link"
          @click="redirectToCommunityPage"
          text="Community"
        ></ButtonVue>
      </div>
    </div>
  </section>

  <FooterVue></FooterVue>
</template>

<script>
import NavVue from "../components/NavVue.vue";
import ButtonVue from "../components/ButtonVue.vue";
import FooterVue from "../components/FooterVue.vue";

export default {
  name: "PageNotFound",
  components: {
    NavVue,
    ButtonVue,
    FooterVue,
  },
  methods: {
    redirectToHomePage() {
      this.$router.push("/");
    },
    redirectToAboutPage() {
      this.$router.push("About-Page");
    },
    redirectToOfficePage() {
      this.$router.push("Office-Page");
    },
    redirectToCommunityPage() {
      this.$router.push("Community-Page");
    },
  },
  mounted() {
    document.body.style.overflowX = "hidden";
    window.scrollTo(0, 0);
  },
};
</script>

<style>
.notFound__content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  padding-top: 7rem;
}

.notFound__title {
  font-size: 4rem;
  font-weight: 600;
}

.notFound__text {
  font-size: 2.2rem;
  text-align: center;
}

.notFound__links {
  display: flex;
  justify-content: center;
  gap: 4rem;
}

.notFound__link {
  width: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 690px) {
  .notFound__links {
    display: flex;
    flex-direction: column;
  }

  .notFound__tofix {
    min-height: 120rem;
  }
}

@media (min-width: 1380px) {
  .notFound__content {
    margin-top: 12rem;
  }
  .notFound__text {
    width: 75%;
  }
}
</style>
