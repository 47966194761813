<template>
  <a
    class="cookie__pref"
    href="#"
    onclick="window.displayPreferenceModal();return false;"
    id="termly-consent-preferences"
    ><img
      class="cookie__img"
      src="../assets/images/office/cookie-icon.png"
      alt=""
  /></a>
  <div id="top" class="section section__imprint">
    <NavVue></NavVue>
    <div class="imprint__content">
      <h1 class="imprint__title">Provider for Workdistrict contents</h1>
      <div class="imprint__text-box">
        <p class="imprint__text">Workdistrict B.V.</p>
        <p class="imprint__text">Hekelveld 8-10</p>
        <p class="imprint__text">1012SN Amsterdam</p>
      </div>
      <div class="imprint__text-box">
        <p class="imprint__text">E-mail</p>
        <p class="imprint__text">contact@workdistrict.com</p>
      </div>
      <div class="imprint__text-box">
        <p class="imprint__text">Managing Directors:</p>
        <p class="imprint__text">Raphael Baumann</p>
        <p class="imprint__text">Luca Hennicker</p>
      </div>
      <div class="imprint__text-box">
        <p class="imprint__text">Court of Registry:</p>
        <p class="imprint__text">Amsterdam; commercial register no. 84201959</p>
      </div>
      <div class="imprint__text-box">
        <p class="imprint__text">VAT ID:</p>
        <p class="imprint__text">863130434B01</p>
      </div>
    </div>
  </div>
  <FooterVue></FooterVue>
</template>

<script>
import NavVue from "../components/NavVue.vue";
import FooterVue from "../components/FooterVue.vue";

export default {
  name: "ImprintPage",
  components: {
    NavVue,
    FooterVue,
  },
  mounted() {
    document.body.style.overflowX = "hidden";
    document.getElementById("top").scrollIntoView();
  },
};
</script>

<style>
.section__imprint {
  background-color: var(--color-bg);
  min-height: auto;
  display: flex;
  flex-direction: column;
  padding: 2rem 8rem;
}

.imprint__content {
  justify-content: center;
  padding-top: 12rem;
  padding-bottom: 12rem;
  padding-left: 0rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-left: -3rem;
}

.imprint__title {
  font-size: 3.6rem;
  margin-bottom: 2.4rem;
  line-height: 1;
}

.imprint__text {
  font-size: 1.4rem;
}

.imprint__text-box {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
</style>
