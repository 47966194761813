<template>
  <div class="temp">
    <NavVue></NavVue>
    <div class="booking__container">
      <div v-bind:class="this.userName == '' ? 'auth-container' : ''">
        <authenticator :hide-sign-up="true">
          <!--
<div @mouseover="myCompanyBooking">
  -->

          <div v-if="isModalBookingOpen" class="booking__modal">
            <div @click="bookingModalHandler" class="modal__close-box">
              <span class="modal__close">x</span>
            </div>
            <h3 class="modal__title">Please confirm your Booking details</h3>
            <div class="modal__review">
              <div class="modal__img-box">
                <img
                  v-if="onEarthActive"
                  class="modal__img"
                  src="../assets/images/office/workdistrict-meeting-room-earth-mid.webp"
                  alt=""
                />
                <img
                  v-if="underwaterActive"
                  class="modal__img"
                  src="../assets/images/office/workdistrict-meeting-room-water-mid.webp"
                  alt=""
                />
                <img
                  v-if="inSpaceActive"
                  class="modal__img"
                  src="../assets/images/office/workdistrict-coworking-space-room-mid.webp"
                  alt=""
                />
                <img
                  v-if="theJungleActive"
                  class="modal__img"
                  src="../assets/images/office/workdistrict-meeting-room-jungle-mid.webp"
                  alt=""
                />
              </div>
              <div class="modal__content">
                <p class="modal__company">{{ this.booking.company }}</p>
                <p class="modal__room">Meeting {{ this.booking.room }}</p>
                <p class="modal__date">Date: {{ this.booking.date }}</p>
                <div class="modal__startend-box">
                  <p class="modal__startend">
                    Start at: {{ this.booking.startTime }}
                  </p>
                  <p class="modal__startend">
                    End at: {{ this.booking.endTime }}
                  </p>
                </div>
                <p class="modal__location">
                  Location:
                  {{
                    this.booking.room == "Underwater" ? "2nd Floor" : "Basement"
                  }}
                </p>
                <div class="modal__price-box">
                  <p class="modal__price">
                    Price: {{ this.booking.price }} credit{{
                      this.booking.price > 1 ? "s" : null
                    }}
                  </p>
                  <p class="modal__price">
                    Current balance: {{ this.userCredit }}
                  </p>
                </div>
              </div>
            </div>
            <div class="modal__confirm">
              <div class="modal__yes-box">
                <p @click="createBookingBis">Book Now</p>
              </div>
              <div @click="bookingModalHandler" class="modal__no-box">
                <p>Cancel</p>
              </div>
            </div>
          </div>

          <!-- NEXT  Modal Cancel Booking  -->
          <div v-if="isModalNextOpen" class="booking__modal">
            <div @click="bookingNextHandler" class="modal__close-box">
              <span class="modal__close">x</span>
            </div>
            <h3 class="modal__title">
              Your booking {{ this.nextBooking.room }}
            </h3>
            <div class="modal__review">
              <div class="modal__img-box">
                <img
                  v-if="nextEarth"
                  class="modal__img"
                  src="../assets/images/office/workdistrict-meeting-room-earth-mid.webp"
                  alt=""
                />
                <img
                  v-if="nextWater"
                  class="modal__img"
                  src="../assets/images/office/workdistrict-meeting-room-water-mid.webp"
                  alt=""
                />
                <img
                  v-if="nextSpace"
                  class="modal__img"
                  src="../assets/images/office/workdistrict-coworking-space-room-mid.webp"
                  alt=""
                />
                <img
                  v-if="nextJungle"
                  class="modal__img"
                  src="../assets/images/office/workdistrict-meeting-room-jungle-mid.webp"
                  alt=""
                />
              </div>
              <div class="modal__content">
                <p class="modal__company">{{ this.nextBooking.company }}</p>
                <p class="modal__room">Meeting {{ this.nextBooking.room }}</p>
                <p class="modal__date">Date: {{ this.nextBooking.date }}</p>
                <div class="modal__startend-box">
                  <p class="modal__startend">
                    Start at: {{ this.nextBooking.start }}
                  </p>
                  <p class="modal__startend">
                    End at: {{ this.nextBooking.end }}
                  </p>
                </div>
                <p class="modal__location">
                  Location:
                  {{
                    this.nextBooking.room == "Underwater"
                      ? "2nd Floor"
                      : "Basement"
                  }}
                </p>
              </div>
            </div>
            <div class="modal__confirm">
              <div class="modal__yes-box">
                <p @click="cancelNextBooking">CANCEL BOOKING</p>
              </div>
              <div @click="bookingNextHandler" class="modal__no-box">
                <p>Back</p>
              </div>
            </div>
          </div>
          <!-- NEXT Modal Cancel Booking  -->

          <!-- UPCOMING  Modal Cancel Booking  -->
          <div v-if="isModalUpcomingOpen" class="booking__modal">
            <div @click="bookingUpcomingHandler" class="modal__close-box">
              <span class="modal__close">x</span>
            </div>
            <h3 class="modal__title">
              Your booking {{ this.allBooking[this.upcomingHelper + 1].room }}
            </h3>
            <div class="modal__review">
              <div class="modal__img-box">
                <img
                  v-if="
                    this.allBooking[this.upcomingHelper + 1].room == 'On Earth'
                  "
                  class="modal__img"
                  src="../assets/images/office/workdistrict-meeting-room-earth-mid.webp"
                  alt=""
                />
                <img
                  v-if="
                    this.allBooking[this.upcomingHelper + 1].room ==
                    'Underwater'
                  "
                  class="modal__img"
                  src="../assets/images/office/workdistrict-meeting-room-water-mid.webp"
                  alt=""
                />
                <img
                  v-if="
                    this.allBooking[this.upcomingHelper + 1].room == 'In Space'
                  "
                  class="modal__img"
                  src="../assets/images/office/workdistrict-coworking-space-room-mid.webp"
                  alt=""
                />
                <img
                  v-if="
                    this.allBooking[this.upcomingHelper + 1].room ==
                    'In the Jungle'
                  "
                  class="modal__img"
                  src="../assets/images/office/workdistrict-meeting-room-jungle-mid.webp"
                  alt=""
                />
              </div>
              <div class="modal__content">
                <p class="modal__company">
                  {{ this.allBooking[this.upcomingHelper + 1].company }}
                </p>
                <p class="modal__room">
                  Meeting {{ this.allBooking[this.upcomingHelper + 1].room }}
                </p>
                <p class="modal__date">
                  Date: {{ this.allBooking[this.upcomingHelper + 1].date }}
                </p>
                <div class="modal__startend-box">
                  <p class="modal__startend">
                    Start at:
                    {{ this.allBooking[this.upcomingHelper + 1].start }}
                  </p>
                  <p class="modal__startend">
                    End at: {{ this.allBooking[this.upcomingHelper + 1].end }}
                  </p>
                </div>
                <p class="modal__location">
                  Location:
                  {{
                    this.allBooking[this.upcomingHelper + 1].room ==
                    "Underwater"
                      ? "2nd Floor"
                      : "Basement"
                  }}
                </p>
              </div>
            </div>
            <div class="modal__confirm">
              <div class="modal__yes-box">
                <p @click="cancelUpcomingBooking()">CANCEL BOOKING</p>
              </div>
              <div @click="bookingUpcomingHandler" class="modal__no-box">
                <p>Back</p>
              </div>
            </div>
          </div>
          <!-- UPCOMING Modal Cancel Booking  -->

          <div class="booking__hero">
            <!-- BOOKING__HERO-LEFT -->
            <div class="booking__hero-left">
              <div class="booking__welcome">
                <!--   <button @click="readBooking">LOG ALL BOOKING</button>    -->
                <h1 class="booking__h1">Welcome {{ this.userName }}</h1>
                <p
                  v-if="
                    this.userRole == 'manager' ||
                    this.userRole == 'admin' ||
                    this.userRole == 'booker'
                  "
                  class="booking__credit"
                >
                  You have {{ this.userCredit }} credits left
                </p>
              </div>
              <div v-if="!nextEmpty" class="booking__next-meeting">
                <h3 class="booking__h3 h3_util">My next meeting</h3>
                <div @click="bookingNextHandler" class="booking__next-item">
                  <img
                    v-if="nextEarth"
                    class="booking__next-img"
                    src="../assets/images/office/workdistrict-meeting-room-earth-mid.webp"
                    alt=""
                  />
                  <img
                    v-if="nextWater"
                    class="booking__next-img"
                    src="../assets/images/office/workdistrict-meeting-room-water-mid.webp"
                    alt=""
                  />
                  <img
                    v-if="nextJungle"
                    class="booking__next-img"
                    src="../assets/images/office/workdistrict-meeting-room-jungle-mid.webp"
                    alt=""
                  />
                  <img
                    v-if="nextSpace"
                    class="booking__next-img"
                    src="../assets/images/office/workdistrict-coworking-space-room-mid.webp"
                    alt=""
                  />

                  <div class="booking__next-content">
                    <p class="booking__next-company">
                      {{ this.nextBooking.company }}
                    </p>
                    <p class="booking__next-room">
                      Meeting {{ this.nextBooking.room }}
                    </p>
                    <p class="booking__next-date">
                      Date: {{ this.nextBooking.date }}
                    </p>
                    <p class="booking__next-start">
                      Start at: {{ this.nextBooking.start }}
                    </p>
                    <p class="booking__next-end">
                      End at: {{ this.nextBooking.end }}
                    </p>
                    <p class="booking__next-duration">
                      Duration: {{ this.nextBooking.duration }}
                    </p>
                    <p class="booking__next-location">
                      Location: {{ this.nextBooking.location }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- BOOKING__HERO-RIGHT -->
            <div v-if="!allEmpty" class="booking__hero-right">
              <div class="booking__all-meeting">
                <p class="booking__h3">Upcoming meetings</p>
                <div class="booking__all-content">
                  <div class="booking__all-tags">
                    <div class="booking__all-tag-box">
                      <p class="booking__all-tag">Meeting</p>
                    </div>
                    <div class="booking__all-tag-box">
                      <p class="booking__all-tag">Date</p>
                    </div>
                    <div class="booking__all-tag-box">
                      <p class="booking__all-tag">Start Time</p>
                    </div>
                    <div class="booking__all-tag-box">
                      <p class="booking__all-tag">End time</p>
                    </div>
                    <div class="booking__all-tag-box tag__helper">
                      <p class="booking__all-tag">Duration</p>
                    </div>
                    <div class="booking__all-tag-box">
                      <p class="booking__all-tag">Location</p>
                    </div>
                  </div>
                  <div
                    class="booking__all-items"
                    v-for="(item, index) in allBooking.slice(1, 8)"
                    :key="item.id"
                    @click="bookingUpcomingHandler(index)"
                  >
                    <div class="booking__all-item">
                      <p class="booking__all-room">{{ item.room }}</p>
                    </div>
                    <div class="booking__all-item">
                      <p class="booking__all-date">{{ item.date }}</p>
                    </div>
                    <div class="booking__all-item">
                      <p class="booking__all-start">{{ item.start }}</p>
                    </div>
                    <div class="booking__all-item">
                      <p class="booking__all-location">{{ item.end }}</p>
                    </div>
                    <div class="booking__all-item tag__helper">
                      <p class="booking__all-location">
                        {{ item.slots.length * 15 }} minutes
                      </p>
                    </div>
                    <div class="booking__all-item">
                      <p class="booking__all-location">
                        {{
                          item.room == "Underwater" ? "2nd Floor" : "Basement"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="booking__book">
            <!-- HERE WILL GOES HTML CODE FOR CALENDAR VUE -->
            <div v-if="roomChoosed" class="main__container">
              <div class="grid__date-box">
                <input
                  v-model="selectedDate"
                  class="grid__date date-input"
                  ref="helper"
                  type="date"
                  id="date_input"
                  name="date"
                  :min="minDate"
                />
              </div>
              <div class="grid">
                <span
                  @click="booked.isBooked[1] ? null : selectItem('1')"
                  :class="{ booked: booked.isBooked[1] }"
                  id="a1"
                  class="grid__item"
                  >08:00 - 08:15</span
                >
                <span
                  @click="booked.isBooked[2] ? null : selectItem('2')"
                  :class="{ booked: booked.isBooked[2] }"
                  id="a2"
                  class="grid__item"
                  >08:15 - 08:30</span
                >
                <span
                  @click="booked.isBooked[3] ? null : selectItem('3')"
                  :class="{ booked: booked.isBooked[3] }"
                  id="a3"
                  class="grid__item"
                  >08:30 - 08:45</span
                >
                <span
                  @click="booked.isBooked[4] ? null : selectItem('4')"
                  :class="{ booked: booked.isBooked[4] }"
                  id="a4"
                  class="grid__item"
                  >08:45 - 09:00</span
                >
                <span
                  @click="booked.isBooked[5] ? null : selectItem('5')"
                  :class="{ booked: booked.isBooked[5] }"
                  id="a5"
                  class="grid__item"
                  >09:00 - 09:15</span
                >
                <span
                  @click="booked.isBooked[6] ? null : selectItem('6')"
                  :class="{ booked: booked.isBooked[6] }"
                  id="a6"
                  class="grid__item"
                  >09:15 - 09:30</span
                >
                <span
                  @click="booked.isBooked[7] ? null : selectItem('7')"
                  :class="{ booked: booked.isBooked[7] }"
                  id="a7"
                  class="grid__item"
                  >09:30 - 09:45</span
                >
                <span
                  @click="booked.isBooked[8] ? null : selectItem('8')"
                  :class="{ booked: booked.isBooked[8] }"
                  id="a8"
                  class="grid__item"
                  >09:45 - 10:00</span
                >
                <span
                  @click="booked.isBooked[9] ? null : selectItem('9')"
                  :class="{ booked: booked.isBooked[9] }"
                  id="a9"
                  class="grid__item"
                  >10:00 - 10:15</span
                >
                <span
                  @click="booked.isBooked[10] ? null : selectItem('10')"
                  :class="{ booked: booked.isBooked[10] }"
                  id="a10"
                  class="grid__item"
                  >10:15 - 10:30</span
                >
                <span
                  @click="booked.isBooked[11] ? null : selectItem('11')"
                  :class="{ booked: booked.isBooked[11] }"
                  id="a11"
                  class="grid__item"
                  >10:30 - 10:45</span
                >
                <span
                  @click="booked.isBooked[12] ? null : selectItem('12')"
                  :class="{ booked: booked.isBooked[12] }"
                  id="a12"
                  class="grid__item"
                  >10:45 - 11:00</span
                >
                <span
                  @click="booked.isBooked[13] ? null : selectItem('13')"
                  :class="{ booked: booked.isBooked[13] }"
                  id="a13"
                  class="grid__item"
                  >11:00 - 11:15</span
                >
                <span
                  @click="booked.isBooked[14] ? null : selectItem('14')"
                  :class="{ booked: booked.isBooked[14] }"
                  id="a14"
                  class="grid__item"
                  >11:15 - 11:30</span
                >
                <span
                  @click="booked.isBooked[15] ? null : selectItem('15')"
                  :class="{ booked: booked.isBooked[15] }"
                  id="a15"
                  class="grid__item"
                  >11:30 - 11:45</span
                >
                <span
                  @click="booked.isBooked[16] ? null : selectItem('16')"
                  :class="{ booked: booked.isBooked[16] }"
                  id="a16"
                  class="grid__item"
                  >11:45 - 12:00</span
                >
                <span
                  @click="booked.isBooked[17] ? null : selectItem('17')"
                  :class="{ booked: booked.isBooked[17] }"
                  id="a17"
                  class="grid__item"
                  >12:00 - 12:15</span
                >
                <span
                  @click="booked.isBooked[18] ? null : selectItem('18')"
                  :class="{ booked: booked.isBooked[18] }"
                  id="a18"
                  class="grid__item"
                  >12:15 - 12:30</span
                >
                <span
                  @click="booked.isBooked[19] ? null : selectItem('19')"
                  :class="{ booked: booked.isBooked[19] }"
                  id="a19"
                  class="grid__item"
                  >12:30 - 12:45</span
                >
                <span
                  @click="booked.isBooked[20] ? null : selectItem('20')"
                  :class="{ booked: booked.isBooked[20] }"
                  id="a20"
                  class="grid__item"
                  >12:45 - 13:00</span
                >
                <span
                  @click="booked.isBooked[21] ? null : selectItem('21')"
                  :class="{ booked: booked.isBooked[21] }"
                  id="a21"
                  class="grid__item"
                  >13:00 - 13:15</span
                >
                <span
                  @click="booked.isBooked[22] ? null : selectItem('22')"
                  :class="{ booked: booked.isBooked[22] }"
                  id="a22"
                  class="grid__item"
                  >13:15 - 13:30</span
                >
                <span
                  @click="booked.isBooked[23] ? null : selectItem('23')"
                  :class="{ booked: booked.isBooked[23] }"
                  id="a23"
                  class="grid__item"
                  >13:30 - 13:45</span
                >
                <span
                  @click="booked.isBooked[24] ? null : selectItem('24')"
                  :class="{ booked: booked.isBooked[24] }"
                  id="a24"
                  class="grid__item"
                  >13:45 - 14:00</span
                >
                <span
                  @click="booked.isBooked[25] ? null : selectItem('25')"
                  :class="{ booked: booked.isBooked[25] }"
                  id="a25"
                  class="grid__item"
                  >14:00 - 14:15</span
                >
                <span
                  @click="booked.isBooked[26] ? null : selectItem('26')"
                  :class="{ booked: booked.isBooked[26] }"
                  id="a26"
                  class="grid__item"
                  >14:15 - 14:30</span
                >
                <span
                  @click="booked.isBooked[27] ? null : selectItem('27')"
                  :class="{ booked: booked.isBooked[27] }"
                  id="a27"
                  class="grid__item"
                  >14:30 - 14:45</span
                >
                <span
                  @click="booked.isBooked[28] ? null : selectItem('28')"
                  :class="{ booked: booked.isBooked[28] }"
                  id="a28"
                  class="grid__item"
                  >14:45 - 15:00</span
                >
                <span
                  @click="booked.isBooked[29] ? null : selectItem('29')"
                  :class="{ booked: booked.isBooked[29] }"
                  id="a29"
                  class="grid__item"
                  >15:00 - 15:15</span
                >
                <span
                  @click="booked.isBooked[30] ? null : selectItem('30')"
                  :class="{ booked: booked.isBooked[30] }"
                  id="a30"
                  class="grid__item"
                  >15:15 - 15:30</span
                >
                <span
                  @click="booked.isBooked[31] ? null : selectItem('31')"
                  :class="{ booked: booked.isBooked[31] }"
                  id="a31"
                  class="grid__item"
                  >15:30 - 15:45</span
                >
                <span
                  @click="booked.isBooked[32] ? null : selectItem('32')"
                  :class="{ booked: booked.isBooked[32] }"
                  id="a32"
                  class="grid__item"
                  >15:45 - 16:00</span
                >
                <span
                  @click="booked.isBooked[33] ? null : selectItem('33')"
                  :class="{ booked: booked.isBooked[33] }"
                  id="a33"
                  class="grid__item"
                  >16:00 - 16:15</span
                >
                <span
                  @click="booked.isBooked[34] ? null : selectItem('34')"
                  :class="{ booked: booked.isBooked[34] }"
                  id="a34"
                  class="grid__item"
                  >16:15 - 16:30</span
                >
                <span
                  @click="booked.isBooked[35] ? null : selectItem('35')"
                  :class="{ booked: booked.isBooked[35] }"
                  id="a35"
                  class="grid__item"
                  >16:30 - 16:45</span
                >
                <span
                  @click="booked.isBooked[36] ? null : selectItem('36')"
                  :class="{ booked: booked.isBooked[36] }"
                  id="a36"
                  class="grid__item"
                  >16:45 - 17:00</span
                >
                <span
                  @click="booked.isBooked[37] ? null : selectItem('37')"
                  :class="{ booked: booked.isBooked[37] }"
                  id="a37"
                  class="grid__item"
                  >17:00 - 17:15</span
                >
                <span
                  @click="booked.isBooked[38] ? null : selectItem('38')"
                  :class="{ booked: booked.isBooked[38] }"
                  id="a38"
                  class="grid__item"
                  >17:15 - 17:30</span
                >
                <span
                  @click="booked.isBooked[39] ? null : selectItem('39')"
                  :class="{ booked: booked.isBooked[39] }"
                  id="a39"
                  class="grid__item"
                  >17:30 - 17:45</span
                >
                <span
                  @click="booked.isBooked[40] ? null : selectItem('40')"
                  :class="{ booked: booked.isBooked[39] }"
                  id="a40"
                  class="grid__item"
                  >17:45 - 18:00</span
                >
              </div>
              <button @click="createBooking" class="grid__btn">
                Book my meeting
              </button>
            </div>
            <!-- HERE WILL GOES HTML CODE FOR CALENDAR VUE -->

            <div ref="scrollHere" id="scrollHere">.</div>
            <h2 v-if="!roomChoosed" class="booking__title">
              Which room would you like to book?
            </h2>
            <h2 v-if="roomChoosed" class="booking__title">
              Please select a date for your meeting {{ roomActive }}
            </h2>
            <span v-if="roomChoosed" @click="goBack" class="booking__arrow"
              >&#10140;</span
            >

            <!-- DESKTOP -->
            <div v-if="!showElement" class="booking__meeting-items">
              <div
                v-if="onEarthDisplay"
                @click="onEarthHandler"
                v-bind:class="{
                  'animate-grow': onEarthActive,
                  'booking__meeting-active1': onEarthActive,
                }"
                class="booking__meeting-item booking__meeting-item1"
              >
                <div v-if="onEarthActive" class="booking__filter"></div>
                <div class="booking__filter-helper"></div>
                <h3 class="booking__meeting-title">On Earth</h3>
              </div>

              <div
                v-if="underwaterDisplay"
                @click="underwaterHandler"
                v-bind:class="{
                  'animate-grow': underwaterActive,
                  'booking__meeting-active2': underwaterActive,
                }"
                class="booking__meeting-item booking__meeting-item2"
              >
                <div v-if="underwaterActive" class="booking__filter"></div>
                <div class="booking__filter-helper"></div>
                <h3 class="booking__meeting-title">Underwater</h3>
              </div>

              <div
                v-if="inSpaceDisplay"
                @click="inSpaceHandler"
                v-bind:class="{
                  'animate-grow': inSpaceActive,
                  'booking__meeting-active3': inSpaceActive,
                }"
                class="booking__meeting-item booking__meeting-item3"
              >
                <div v-if="inSpaceActive" class="booking__filter"></div>
                <div class="booking__filter-helper"></div>
                <h3 class="booking__meeting-title">In Space</h3>
              </div>

              <div
                v-if="theJungleDisplay"
                @click="theJungleHandler"
                v-bind:class="{
                  'animate-grow': theJungleActive,
                  'booking__meeting-active4': theJungleActive,
                }"
                class="booking__meeting-item booking__meeting-item4"
              >
                <div v-if="theJungleActive" class="booking__filter"></div>
                <div class="booking__filter-helper"></div>
                <h3 class="booking__meeting-title">The Jungle</h3>
              </div>
            </div>

            <!-- MOBILE -->
            <div
              v-if="showElement"
              @touchstart.once="loginMaster"
              class="booking__meeting-items"
            >
              <div class="mobile__helper-row">
                <div
                  v-if="onEarthDisplay"
                  @click="onEarthHandler"
                  v-bind:class="{
                    'animate-grow-mobile': onEarthActive,
                    'booking__meeting-active1': onEarthActive,
                  }"
                  class="booking__meeting-item booking__meeting-item1"
                >
                  <div v-if="onEarthActive" class="booking__filter"></div>
                  <h3 v-if="!roomChoosed" class="booking__meeting-title">
                    On Earth
                  </h3>
                </div>

                <div
                  v-if="underwaterDisplay"
                  @click="underwaterHandler"
                  v-bind:class="{
                    'animate-grow-mobile': underwaterActive,
                    'booking__meeting-active2': underwaterActive,
                  }"
                  class="booking__meeting-item booking__meeting-item2"
                >
                  <div v-if="underwaterActive" class="booking__filter"></div>
                  <h3 v-if="!roomChoosed" class="booking__meeting-title">
                    Underwater
                  </h3>
                </div>
              </div>

              <div class="mobile__helper-row mobile__helper-row-bis">
                <div
                  v-if="inSpaceDisplay"
                  @click="inSpaceHandler"
                  v-bind:class="{
                    'animate-grow-mobile': inSpaceActive,
                    'booking__meeting-active3': inSpaceActive,
                  }"
                  class="booking__meeting-item booking__meeting-item3"
                >
                  <div v-if="inSpaceActive" class="booking__filter"></div>
                  <h3 v-if="!roomChoosed" class="booking__meeting-title">
                    In Space
                  </h3>
                </div>

                <div
                  v-if="theJungleDisplay"
                  @click="theJungleHandler"
                  v-bind:class="{
                    'animate-grow-mobile': theJungleActive,
                    'booking__meeting-active4': theJungleActive,
                  }"
                  class="booking__meeting-item booking__meeting-item4"
                >
                  <div v-if="theJungleActive" class="booking__filter"></div>
                  <h3 v-if="!roomChoosed" class="booking__meeting-title">
                    The Jungle
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <!--  ADMIN PANEL     v-if="this.userRole == 'manager'"     -->
          <div v-if="this.userRole == 'tochange'" class="admin__panel">
            <h3 class="admin__title">Admin Panel</h3>
            <div class="admin__content">
              <div class="admin__grid-room admin__grid-item">
                <span>ROOM</span>
                <button @click="createRoom">Create all rooms</button>
                <button @click="readRoom">Read all rooms</button>
                <button @click="deleteAllRoom">Delete all rooms</button>
              </div>

              <div class="admin__grid-booking admin__grid-item">
                <span>BOOKING</span>
                <button @click="readBooking">Read all booking</button>
                <button @click="deleteBooking">Delete all booking</button>
              </div>

              <div class="admin__grid-slot admin__grid-item">
                <span>SLOT</span>
                <button @click="creatingSlot">Create all slots</button>
                <button @click="readSlot">Read all slots</button>
                <button @click="deleteSlot">Delete all slots</button>
              </div>

              <div class="admin__grid-company admin__grid-item">
                <span>COMPANY</span>
                <label for="">Name</label>
                <input v-model="adminPanel.companyName" type="text" />
                <label for="">Credit</label>
                <input v-model.number="adminPanel.companyCredit" type="text" />
                <label for="">Monthly Credit</label>
                <input
                  v-model.number="adminPanel.companyCreditPerMonth"
                  type="text"
                />
                <button @click="createCompany" class="admin__btn-helper">
                  Create Company
                </button>
                <button @click="readCompany">Read all Company</button>
                <button @click="deleteCompany">Delete all Company</button>
              </div>

              <div class="admin__grid-user admin__grid-item">
                <span>USER</span>
                <label for="">Name</label>
                <input v-model="adminPanel.userName" type="text" />
                <label for="">Username</label>
                <input v-model="adminPanel.userUsername" type="text" />
                <label for="">Email</label>
                <input v-model="adminPanel.userEmail" type="text" />
                <label for="">Role</label>
                <input v-model="adminPanel.userRole" type="text" />
                <label for="">Company</label>
                <input v-model="adminPanel.userCompany" type="text" />
                <button class="admin__btn-helper" @click="createUser">
                  Create User
                </button>
                <button @click="readAllUser">Read all User</button>
                <button @click="deleteUser">Delete all User</button>
              </div>

              <div class="admin__grid-credit admin__grid-item">
                <span>CREDIT</span>
                <button @click="creditGen">Credit Generation</button>
              </div>

              <div class="admin__grid-key admin__grid-item">
                <span>ADMIN KEY</span>
                <input v-model="adminPanel.key" type="text" />
              </div>
            </div>
          </div>

          <!-------------------------->
          <!------ C NEED TO GO UP MAN PANEL ------>
          <!-------------------------->
          <div class="calendar__overview">
            <h3 class="calendar__overview-title">Calendar Overview</h3>
            <input
              v-model="selectedDateBis"
              class="calendar__overview-date"
              type="date"
              @change="calendarDisplay"
              :min="minDate"
            />
            <div class="calendar__overview-grid">
              <div class="calendar__overview-item">
                <p class="calendar__overview-room">On Earth</p>
                <div
                  v-for="(slot, index) in slotHelper"
                  :key="slot.id"
                  class="calendar__overview-col"
                >
                  <p class="calendar__overview-slot">{{ slot }}</p>
                  <div
                    :class="{ bookedBis: calendarEarth[index + 1] }"
                    class="calendar__cell calendar__earth"
                  ></div>
                </div>
              </div>

              <div class="calendar__overview-item">
                <p class="calendar__overview-room">Underwater</p>
                <div
                  v-for="(slot, index) in slotHelper"
                  :key="slot.id"
                  class="calendar__overview-col"
                >
                  <p class="calendar__overview-slot">{{ slot }}</p>
                  <div
                    :class="{ bookedBis: calendarWater[index + 1] }"
                    class="calendar__cell calendar__cell"
                  ></div>
                </div>
              </div>

              <div class="calendar__overview-item">
                <p class="calendar__overview-room">In Space</p>
                <div
                  v-for="(slot, index) in slotHelper"
                  :key="slot.id"
                  class="calendar__overview-col"
                >
                  <p class="calendar__overview-slot">{{ slot }}</p>
                  <div
                    :class="{ bookedBis: calendarSpace[index + 1] }"
                    class="calendar__cell calendar__cell"
                  ></div>
                </div>
              </div>

              <div class="calendar__overview-item">
                <p class="calendar__overview-room">The Jungle</p>
                <div
                  v-for="(slot, index) in slotHelper"
                  :key="slot.id"
                  class="calendar__overview-col"
                >
                  <p class="calendar__overview-slot">{{ slot }}</p>
                  <div
                    :class="{ bookedBis: calendarJungle[index + 1] }"
                    class="calendar__cell calendar__cell"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <!-------------------------->
          <!------ CALENDAR NEED TO GO UP MAN PANEL ------>
          <!-------------------------->

          <!--  MANAGER PANEL     -->
          <div
            v-if="this.userRole == 'manager' || this.userRole == 'admin'"
            class="manager__panel"
          >
            <h3 class="manager__title">Manager Panel</h3>
            <div class="manager__content">
              <div class="manager__tags">
                <div class="manager__tag">
                  <p class="manager__tag-employee">Employee</p>
                </div>
                <div class="manager__tag">
                  <p class="manager__tag-current">Current</p>
                </div>
                <div class="manager__tag">
                  <p class="manager__tag-new">New Role</p>
                </div>
                <div class="manager__tag">
                  <div class="manager__tag-box">
                    <div class="manager__tag-helper">
                      <p>
                        User: This user can see his meetings <br />Booker: This
                        user can see his meetings and book a meeting
                        <br />Manager: This user can view his meetings, book a
                        meeting and manage his employees role
                      </p>
                    </div>
                    <p class="manager__tag-info">?</p>
                  </div>
                </div>
              </div>

              <div class="manager__items-box">
                <div
                  v-for="(item, index) in managerPanel[0]"
                  :key="item.id"
                  class="manager__items"
                >
                  <div class="manager__item">
                    <p class="manager__item-employee">{{ item }}</p>
                  </div>
                  <div class="manager__item">
                    <p class="manager__item-current">
                      {{ managerPanel[1][index] }}
                    </p>
                  </div>
                  <div class="manager__item">
                    <select v-model="managerPanelHelper[index]" name="" id="">
                      <option selected disabled value=""></option>
                      <option
                        v-if="managerPanel[1][index] !== 'user'"
                        value="user"
                      >
                        user
                      </option>
                      <option
                        v-if="managerPanel[1][index] !== 'booker'"
                        value="booker"
                      >
                        booker
                      </option>
                      <option
                        v-if="managerPanel[1][index] !== 'manager'"
                        value="manager"
                      >
                        manager
                      </option>
                    </select>
                  </div>
                  <div class="manager__item">
                    <p @click="updateRole(index)" class="manager__item-btn">
                      Update
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-------------------------->

          <!--
  </div>
  </div>
-->
        </authenticator>
      </div>
    </div>
  </div>
</template>

<script>
import { Authenticator } from "@aws-amplify/ui-vue";
import { Auth, Hub } from "aws-amplify";
import NavVue from "../components/NavVue.vue";
import { DataStore } from "aws-amplify";
import { Room, Booking, Slot, User, Company } from "@/models";
import { Predicates } from "aws-amplify";
import "@aws-amplify/ui-vue/styles.css";

export default {
  name: "BookingPage",
  data() {
    return {
      user: "",
      slotHelper: [
        "08:00 - 08:15",
        "08:15 - 08:30",
        "08:30 - 08:45",
        "08:45 - 09:00",
        "09:00 - 09:15",
        "09:15 - 09:30",
        "09:30 - 09:45",
        "09:45 - 10:00",
        "10:00 - 10:15",
        "10:15 - 10:30",
        "10:30 - 10:45",
        "10:45 - 11:00",
        "11:00 - 11:15",
        "11:15 - 11:30",
        "11:30 - 11:45",
        "11:45 - 12:00",
        "12:00 - 12:15",
        "12:15 - 12:30",
        "12:30 - 12:45",
        "12:45 - 13:00",
        "13:00 - 13:15",
        "13:15 - 13:30",
        "13:30 - 13:45",
        "13:45 - 14:00",
        "14:00 - 14:15",
        "14:15 - 14:30",
        "14:30 - 14:45",
        "14:45 - 15:00",
        "15:00 - 15:15",
        "15:15 - 15:30",
        "15:30 - 15:45",
        "15:45 - 16:00",
        "16:00 - 16:15",
        "16:15 - 16:30",
        "16:30 - 16:45",
        "16:45 - 17:00",
        "17:00 - 17:15",
        "17:15 - 17:30",
        "17:30 - 17:45",
        "17:45 - 18:00",
      ],
      slotHelperStart: [
        "08:00",
        "08:15",
        "08:30",
        "08:45",
        "09:00",
        "09:15",
        "09:30",
        "09:45",
        "10:00",
        "10:15",
        "10:30",
        "10:45",
        "11:00",
        "11:15",
        "11:30",
        "11:45",
        "12:00",
        "12:15",
        "12:30",
        "12:45",
        "13:00",
        "13:15",
        "13:30",
        "13:45",
        "14:00",
        "14:15",
        "14:30",
        "14:45",
        "15:00",
        "15:15",
        "15:30",
        "15:45",
        "16:00",
        "16:15",
        "16:30",
        "16:45",
        "17:00",
        "17:15",
        "17:30",
        "17:45",
        "18:00",
      ],
      slotHelperEnd: [
        "08:15",
        "08:30",
        "08:45",
        "09:00",
        "09:15",
        "09:30",
        "09:45",
        "10:00",
        "10:15",
        "10:30",
        "10:45",
        "11:00",
        "11:15",
        "11:30",
        "11:45",
        "12:00",
        "12:15",
        "12:30",
        "12:45",
        "13:00",
        "13:15",
        "13:30",
        "13:45",
        "14:00",
        "14:15",
        "14:30",
        "14:45",
        "15:00",
        "15:15",
        "15:30",
        "15:45",
        "16:00",
        "16:15",
        "16:30",
        "16:45",
        "17:00",
        "17:15",
        "17:30",
        "17:45",
        "18:00",
      ],
      roomName: "Test Room",
      roomPrice: 20,
      res: [
        "a1",
        "a2",
        "a3",
        "a4",
        "a5",
        "a6",
        "a7",
        "a8",
        "a9",
        "a10",
        "a11",
        "a12",
        "a13",
        "a14",
        "a15",
        "a16",
        "a17",
        "a18",
        "a19",
        "a20",
        "a21",
        "a22",
        "a23",
        "a24",
        "a25",
        "a26",
        "a27",
        "a28",
        "a29",
        "a30",
        "a31",
        "a32",
        "a33",
        "a34",
        "a35",
        "a36",
        "a37",
        "a38",
        "a39",
        "a40",
      ],
      selected: [],
      room: "Underwater",
      selectedDate: new Date().toISOString().substr(0, 10),
      selectedDateBis: new Date().toISOString().substr(0, 10),
      userEmail: "",
      userName: "",
      userCompany: "",
      userRole: "",
      userCredit: 0,
      userBooking: [],
      userNextBooking: "",
      isCurrentHourBeforeTargetTime: "",
      currentUser: null,

      selecteParsed: [],
      today: new Date().toISOString().substr(0, 10),
      todayTest: new Date(),
      underwater: [],
      underwaterHelper: [],
      inSpace: [],
      onEarth: [],
      theJungle: [],
      masterData: [],
      booked: {
        isBooked: [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ],
      },

      calendarEarth: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],

      calendarJungle: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],

      calendarSpace: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],

      calendarWater: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],

      managerPanel: [[], []],
      managerPanelHelper: [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],

      booking: {},

      isModalBookingOpen: false,
      isModalNextOpen: false,
      isModalUpcomingOpen: false,

      mediaQuery: window.matchMedia("(max-width: 1070px)"),
      windowWidth: window.innerWidth,

      awaitHelper: false,
      upcomingHelper: 0,

      allBooking: [],

      nextBooking: {
        room: "",
        date: "",
        start: "",
        end: "",
        duration: "",
        company: "",
        location: "",
      },

      adminPanel: {
        key: "",
        companyName: "",
        companyCredit: "",
        companyCreditPerMonth: "",
        userName: "",
        userUsername: "",
        userEmail: "",
        userRole: "",
        userCompany: "",
      },

      nextEarth: false,
      nextSpace: false,
      nextWater: false,
      nextJungle: false,

      nextEmpty: false,
      allEmpty: false,

      roomActive: "",
      roomChoosed: false,

      onEarthActive: false,
      underwaterActive: false,
      inSpaceActive: false,
      theJungleActive: false,

      onEarthDisplay: true,
      underwaterDisplay: true,
      inSpaceDisplay: true,
      theJungleDisplay: true,

      mobileRoleHelper: false,
    };
  },
  components: {
    Authenticator,
    NavVue,
  },

  created() {
    window.addEventListener("resize", this.onResize);
  },

  unmounted() {
    document.body.style.backgroundColor = "white";
    window.removeEventListener("resize", this.onResize);

    Auth.signOut();
  },

  computed: {
    minDate() {
      const today = new Date();
      const isoDate = today.toISOString().split("T")[0];
      return isoDate;
    },

    showElement() {
      return this.windowWidth <= 1170;
    },
  },

  methods: {
    /////////////////////////////////////////////

    async cancelNextBooking() {
      const toDelete = await DataStore.query(Booking, `${this.nextBooking.id}`);

      const [hours, minutes] = toDelete.start.trim().split(":").map(Number);
      const milliseconds = (hours * 60 + minutes) * 60 * 1000;

      const currentTime = new Date();
      const millisecondsNow =
        currentTime.getHours() * 60 * 60 * 1000 +
        currentTime.getMinutes() * 60 * 1000;

      if (toDelete.date == new Date().toISOString().slice(0, 10)) {
        if (milliseconds < millisecondsNow) {
          alert(
            "Please note! Impossible to cancel a meeting that has already taken place"
          );
          this.bookingNextHandler();
          return;
        } else if (milliseconds - 7200000 < millisecondsNow) {
          alert(
            "Please note! Impossible to cancel a meeting with less than 2 hours to go"
          );
          this.bookingNextHandler();
          return;
        }
      }

      const [original] = await DataStore.query(Company, (c) =>
        c.name.eq(this.userCompany)
      );

      await DataStore.save(
        Company.copyOf(original, (updated) => {
          updated.credit += toDelete.price;
        })
      );

      DataStore.delete(toDelete);
      this.bookingNextHandler();
      this.myCompanyBooking();
      this.calendarDisplay();
      this.updateCreditBalance();

      alert(
        "Your meeting has been cancelled and your credits have been refunded"
      );

      this.bookingNextHandler();
    },

    async cancelUpcomingBooking() {
      const toDelete = await DataStore.query(
        Booking,
        `${this.allBooking[this.upcomingHelper + 1].id}`
      );

      const [hours, minutes] = toDelete.start.trim().split(":").map(Number);
      const milliseconds = (hours * 60 + minutes) * 60 * 1000;

      const currentTime = new Date();
      const millisecondsNow =
        currentTime.getHours() * 60 * 60 * 1000 +
        currentTime.getMinutes() * 60 * 1000;

      if (toDelete.date == new Date().toISOString().slice(0, 10)) {
        if (milliseconds < millisecondsNow) {
          alert(
            "Please note! Impossible to cancel a meeting that has already taken place"
          );
          this.bookingUpcomingHandler();
          return;
        } else if (milliseconds - 7200000 < millisecondsNow) {
          alert(
            "Please note! Impossible to cancel a meeting with less than 2 hours to go"
          );
          this.bookingUpcomingHandler();
          return;
        }
      }

      const [original] = await DataStore.query(Company, (c) =>
        c.name.eq(this.userCompany)
      );

      await DataStore.save(
        Company.copyOf(original, (updated) => {
          updated.credit += toDelete.price;
        })
      );

      DataStore.delete(toDelete);
      this.bookingUpcomingHandler();
      this.myCompanyBooking();
      this.calendarDisplay();
      this.updateCreditBalance();
      alert(
        "Your meeting has been cancelled and your credits have been refunded"
      );
    },

    async updateRole(i) {
      const [original] = await DataStore.query(User, (c) =>
        c.name.eq(this.managerPanel[0][i])
      );

      await DataStore.save(
        User.copyOf(original, (updated) => {
          updated.role = this.managerPanelHelper[i];
        })
      );
      alert("Congratulations! roles have been updated");
    },

    async loginMaster() {
      try {
        const user = await this.getUser();
        const userCompany = await this.getUserCompany(user);
        await this.myCompanyBooking(userCompany);
        // Do something with the results
      } catch (error) {
        console.error(error);
      }
    },

    ////////////////////////////////////////////////

    onResize() {
      this.windowWidth = window.innerWidth;
    },

    bookingModalHandler() {
      this.isModalBookingOpen == true
        ? (this.isModalBookingOpen = false)
        : (this.isModalBookingOpen = true);
    },

    bookingNextHandler() {
      this.isModalNextOpen == true
        ? (this.isModalNextOpen = false)
        : (this.isModalNextOpen = true);
    },

    bookingUpcomingHandler(index) {
      this.upcomingHelper = index;
      this.isModalUpcomingOpen == true
        ? (this.isModalUpcomingOpen = false)
        : (this.isModalUpcomingOpen = true);
    },

    mobileRoleHandler() {
      this.mobileRoleHelper == true
        ? (this.mobileRoleHelper = false)
        : (this.mobileRoleHelper = true);
    },

    async creditGen() {
      if (this.adminPanel.key !== "codecode") {
        alert(
          "You need a valid Admin Key to interact with the Admin Panel, please contact WorkDistrict Marketing Team."
        );
        return;
      }

      const [original1] = await DataStore.query(Company, (c) =>
        c.name.eq("Network Circle")
      );
      await DataStore.save(
        Company.copyOf(original1, (updated) => {
          updated.credit = 9999;
        })
      );

      const [original2] = await DataStore.query(Company, (c) =>
        c.name.eq("Thorizon")
      );
      await DataStore.save(
        Company.copyOf(original2, (updated) => {
          updated.credit = 160;
        })
      );

      const [original3] = await DataStore.query(Company, (c) =>
        c.name.eq("Marel")
      );
      await DataStore.save(
        Company.copyOf(original3, (updated) => {
          updated.credit = 80;
        })
      );

      const [original4] = await DataStore.query(Company, (c) =>
        c.name.eq("Stichting FGK")
      );
      await DataStore.save(
        Company.copyOf(original4, (updated) => {
          updated.credit = 16;
        })
      );

      const [original5] = await DataStore.query(Company, (c) =>
        c.name.eq("Mistymission")
      );
      await DataStore.save(
        Company.copyOf(original5, (updated) => {
          updated.credit = 16;
        })
      );

      const [original6] = await DataStore.query(Company, (c) =>
        c.name.eq("Laureen Bosker")
      );
      await DataStore.save(
        Company.copyOf(original6, (updated) => {
          updated.credit = 16;
        })
      );

      const [original7] = await DataStore.query(Company, (c) =>
        c.name.eq("Hollandsche Vastgoed Partners")
      );
      await DataStore.save(
        Company.copyOf(original7, (updated) => {
          updated.credit = 32;
        })
      );
    },

    async onLogin() {
      await this.getUser();
    },

    async myCompanyBooking() {
      this.allBooking = [];
      this.nextEmpty = false;
      this.allEmpty = false;

      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      const isoDate = `${year}-${month}-${day}`;

      this.deleteOldBooking();

      const read = await DataStore.query(Booking, (c) => c.user.eq(this.user));

      for (let i = read.length - 1; i >= 0; i--) {
        if (read[i].date < isoDate) {
          read.splice(i, 1);
        }
      }

      if (read.length == 0) {
        this.nextEmpty = true;
        this.allEmpty = true;
        return;
      } else if (read.length == 1) {
        this.allEmpty = true;
      }

      read.sort((a, b) => {
        // Sort by date property
        if (a.date < b.date) {
          return -1;
        } else if (a.date > b.date) {
          return 1;
        } else {
          // If dates are equal, sort by slots[0] property
          if (a.slots[0] < b.slots[0]) {
            return -1;
          } else if (a.slots[0] > b.slots[0]) {
            return 1;
          } else {
            return 0;
          }
        }
      });

      this.allBooking = [];
      read.forEach((element) => this.allBooking.push(element));

      /*
this.allBooking.forEach((e, i) => {
  if (e.room == 'In the Jungle' || e.room == 'On Earth' || e.room == 'in Space') {
  this.allBooking[i].location = 'Basement'
} else if ( this.nextBooking.room == 'Underwater') {
  this.allBooking[i].location = '2nd floor'
}
});
*/

      const duration = read[0].slots.length * 15;
      this.nextBooking.room = read[0].room;
      this.nextBooking.date = read[0].date;
      this.nextBooking.start = read[0].start;
      this.nextBooking.end = read[0].end;
      this.nextBooking.duration = `${duration} minutes`;
      this.nextBooking.company = read[0].company;
      this.nextBooking.id = read[0].id;

      if (
        this.nextBooking.room == "In the Jungle" ||
        this.nextBooking.room == "On Earth" ||
        this.nextBooking.room == "In Space"
      ) {
        this.nextBooking.location = "Basement";
      } else if (this.nextBooking.room == "Underwater") {
        this.nextBooking.location = "2nd floor";
      }

      this.nextEarth = false;
      this.nextSpace = false;
      this.nextWater = false;
      this.nextJungle = false;

      if (this.nextBooking.room == "On Earth") {
        this.nextEarth = true;
      } else if (this.nextBooking.room == "In Space") {
        this.nextSpace = true;
      } else if (this.nextBooking.room == "Underwater") {
        this.nextWater = true;
      } else if (this.nextBooking.room == "In the Jungle") {
        this.nextJungle = true;
      }

      let i = 0;
      if (this.userRole == "manager" || this.userRole == "admin") {
        i == 0 ? this.managerPanelFunc() : null;
        i++;
      }
    },

    async managerPanelFunc() {
      const read = await DataStore.query(User, (c) =>
        c.company.eq(this.userCompany)
      );

      this.managerPanel[0] = [];
      this.managerPanel[1] = [];

      read.forEach((e) => {
        this.managerPanel[0].push(e.name);
        this.managerPanel[1].push(e.role);
      });
    },

    async managerPanelUpdate() {
      this.managerPanel.employee.forEach(
        async function (e, i) {
          const [original] = await DataStore.query(User, (c) => c.name.eq(e));

          await DataStore.save(
            User.copyOf(original, (updated) => {
              updated.role = this.managerPanel.role[i];
            })
          );
        }.bind(this)
      );
      this.managerPanelFunc();
      alert("Congratz! roles have been changed");
    },

    //////////////////////////         FROM BOOKING VUE          ///////////////////////////////////////////////

    onEarthHandler() {
      this.onEarthActive = true;
      this.roomChoosed = true;
      (this.roomActive = "On Earth"),
        (this.onEarthDisplay = true),
        (this.underwaterDisplay = false),
        (this.inSpaceDisplay = false),
        (this.theJungleDisplay = false);
      this.masterFunc();
    },
    underwaterHandler() {
      this.underwaterActive = true;
      this.roomChoosed = true;
      this.roomActive = "Underwater";
      (this.onEarthDisplay = false),
        (this.underwaterDisplay = true),
        (this.inSpaceDisplay = false),
        (this.theJungleDisplay = false);
      if (this.windowWidth < 643) {
        document.getElementById("scrollHere").scrollIntoView();
      }
      this.masterFunc();
    },
    inSpaceHandler() {
      this.inSpaceActive = true;
      this.roomChoosed = true;
      this.roomActive = "In Space";
      (this.onEarthDisplay = false),
        (this.underwaterDisplay = false),
        (this.inSpaceDisplay = true),
        (this.theJungleDisplay = false);
      if (this.windowWidth < 643) {
        document.getElementById("scrollHere").scrollIntoView();
      }
      this.masterFunc();
    },
    theJungleHandler() {
      this.theJungleActive = true;
      this.roomChoosed = true;
      this.roomActive = "In the Jungle";
      (this.onEarthDisplay = false),
        (this.underwaterDisplay = false),
        (this.inSpaceDisplay = false),
        (this.theJungleDisplay = true);
      if (this.windowWidth < 643) {
        document.getElementById("scrollHere").scrollIntoView();
      }
      this.masterFunc();
    },

    goBack() {
      this.theJungleActive = false;
      this.inSpaceActive = false;
      this.underwaterActive = false;
      this.onEarthActive = false;
      this.theJungleDisplay = true;
      this.inSpaceDisplay = true;
      this.underwaterDisplay = true;
      this.onEarthDisplay = true;
      this.roomChoosed = false;
      this.roomActive = "";
      this.selectedDate = new Date().toISOString().substr(0, 10);
    },

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////         FROM CALENDAR VUE             ///////////////////////////////////////////////
    selectItem(id) {
      if (this.selected.includes(id)) {
        this.removeElementFromSelected(id);
        document.getElementById("a" + id).classList.remove("selected");
      } else {
        this.selected.push(id);
        document.getElementById("a" + id).classList.add("selected");
      }
    },

    removeElementFromSelected(p) {
      const index = this.selected.indexOf(p);
      if (index !== -1) {
        this.selected.splice(index, 1);
      }
    },

    areConsecutiveNumbers() {
      this.selected.sort(function (a, b) {
        return a - b;
      });
      this.selectedParsed = [...this.selected];
      for (let i = 0; i < this.selected.length; i++) {
        this.selectedParsed[i] = parseInt(this.selected[i]);
      }
      for (let i = 1; i < this.selected.length; i++) {
        if (this.selectedParsed[i] !== this.selectedParsed[i - 1] + 1) {
          return false;
        }
      }
      return true;
    },

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////         USER             ///////////////////////////////////////////////

    async createUser() {
      if (this.adminPanel.key !== "codecode") {
        alert(
          "You need a valid Admin Key to interact with the Admin Panel, please contact WorkDistrict Marketing Team."
        );
        return;
      }

      await DataStore.save(
        new User({
          name: this.adminPanel.userName,
          username: this.adminPanel.userUsername,
          email: this.adminPanel.userEmail,
          role: this.adminPanel.userRole,
          company: this.adminPanel.userCompany,
        })
      );
    },

    async readAllUser() {
      if (this.adminPanel.key !== "codecode") {
        alert(
          "You need a valid Admin Key to interact with the Admin Panel, please contact WorkDistrict Marketing Team."
        );
        return;
      }

      const read = await DataStore.query(User);
      console.log(read);
      return read;
    },

    async getUser() {
      Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then((user) => {
          this.user = user.username;
        })
        .catch((err) => console.log(err));
    },

    async getUserCompany() {
      const temp = await DataStore.query(User, (c) => c.username.eq(this.user));

      this.userName = temp[0].name;
      this.userCompany = temp[0].company;
      this.userRole = temp[0].role;
      //this.userEmail = temp[0].email

      this.updateCreditBalance();
    },

    async updateCreditBalance() {
      const tempo = await DataStore.query(Company, (c) =>
        c.name.eq(this.userCompany)
      );
      this.userCredit = tempo[0].credit;
    },

    async readUser() {
      const user = this.user;

      const read = await DataStore.query(User, (c) => c.username.eq(user)); // .contains(user) ???

      return read;
    },

    async deleteUser() {
      if (this.adminPanel.key !== "codecode") {
        alert(
          "You need a valid Admin Key to interact with the Admin Panel, please contact WorkDistrict Marketing Team."
        );
        return;
      }

      await DataStore.delete(User, Predicates.ALL);
    },

    ////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////         MEETING ROOM             ///////////////////////////////////////////////
    async createRoom() {
      if (this.adminPanel.key !== "codecode") {
        alert(
          "You need a valid Admin Key to interact with the Admin Panel, please contact WorkDistrict Marketing Team."
        );
        return;
      }

      await DataStore.save(
        new Room({
          name: "Underwater",
          location: "second floor",
          pricePerSlot: 1,
        })
      );

      await DataStore.save(
        new Room({
          name: "On Earth",
          location: "basement",
          pricePerSlot: 1,
        })
      );

      await DataStore.save(
        new Room({
          name: "In the Jungle",
          location: "basement",
          pricePerSlot: 1,
        })
      );

      await DataStore.save(
        new Room({
          name: "In Space",
          location: "basement",
          pricePerSlot: 1,
        })
      );
    },

    async readRoom() {
      if (this.adminPanel.key !== "codecode") {
        alert(
          "You need a valid Admin Key to interact with the Admin Panel, please contact WorkDistrict Marketing Team."
        );
        return;
      }

      const read = await DataStore.query(Room);
      console.log(read);
      return read;
    },

    async deleteAllRoom() {
      if (this.adminPanel.key !== "codecode") {
        alert(
          "You need a valid Admin Key to interact with the Admin Panel, please contact WorkDistrict Marketing Team."
        );
        return;
      }

      if (this.adminPanel.key !== "codecode") {
        alert(
          "You need a valid Admin Key to interact with the Admin Panel, please contact WorkDistrict Marketing Team."
        );
        return;
      }

      await DataStore.delete(Room, Predicates.ALL);
    },
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////        BOOKING             ///////////////////////////////////////////////////////

    async readBooking() {
      if (this.adminPanel.key !== "codecode") {
        alert(
          "You need a valid Admin Key to interact with the Admin Panel, please contact WorkDistrict Marketing Team."
        );
        return;
      }

      const read = await DataStore.query(Booking);
      console.log(read);
      return read;
    },

    async deleteBooking() {
      if (this.adminPanel.key !== "codecode") {
        alert(
          "You need a valid Admin Key to interact with the Admin Panel, please contact WorkDistrict Marketing Team."
        );
        return;
      }

      await DataStore.delete(Booking, Predicates.ALL);
    },

    async deleteOldBooking() {
      const today = new Date().toISOString().slice(0, 10);
      const bookingsToDelete = await DataStore.query(Booking, (b) =>
        b.date.lt(today)
      );
      for (const booking of bookingsToDelete) {
        await DataStore.delete(Booking, booking.id);
      }
    },

    async createBooking() {
      if (
        this.userRole !== "admin" &&
        this.userRole !== "manager" &&
        this.userRole !== "booker"
      ) {
        alert(
          "You do not have persmission to book a meeting room, please get in touch with your manager to request permission"
        );
        this.goBack();
        return;
      }

      if (this.selectedDate === undefined || this.selectedDate === "") {
        alert("Please select a date for your meeting");
        return;
      }
      if (this.selected.length == 0) {
        alert("Please select a time slot for your meeting ");
        return;
      } else if (this.areConsecutiveNumbers() === true) {
        console.log("console.log");
      } else if (this.selectedDate < this.today) {
        alert("Please select a valid day for your meeting");
      } else {
        alert("Please select a consecutive time slot for your meeting");
        return;
      }

      this.booking.company = "";
      this.booking.room = "";
      this.booking.priceHelper = "";
      this.booking.priceRoom = 0;
      this.booking.date = "";
      this.booking.slotN = 0;
      this.booking.startTime = "";
      this.booking.endTime = "";
      this.booking.price = 0;
      this.booking.credit = 0;
      this.booking.creditAmount = "";

      // ACCESSING DB DATAS AND STORE THEM INTO LOCAL VAR

      // BOOKING HELPER
      const read = await DataStore.query(User, (c) => c.username.eq(this.user));
      this.booking.company = read[0].company;
      this.booking.room = this.roomActive;
      this.booking.priceHelper = await DataStore.query(Room, (c) =>
        c.name.eq(this.booking.room)
      );
      this.booking.priceRoom = this.booking.priceHelper[0].pricePerSlot;
      this.booking.date = this.selectedDate;
      this.booking.slotN = this.selected.length;
      this.booking.startTime = this.slotHelperStart[this.selectedParsed[0] - 1];
      this.booking.endTime =
        this.slotHelperStart[this.selectedParsed[this.selected.length - 1]];
      this.booking.price = this.booking.slotN * this.booking.priceRoom;
      this.booking.credit = await DataStore.query(Company, (c) =>
        c.name.eq(this.booking.company)
      );
      this.booking.creditAmount = this.booking.credit[0].credit;
      // BOOKING HELPER

      this.bookingModalHandler();
    },

    async createBookingBis() {
      // CHECK FOR ENOUGH CREDIT
      if (this.booking.creditAmount >= this.booking.price) {
        // REGISTER BOOKING
        await DataStore.save(
          new Booking({
            room: this.booking.room,
            date: this.booking.date,
            start: this.booking.startTime,
            end: this.booking.endTime,
            company: this.booking.company,
            price: this.booking.price,
            slots: this.selectedParsed,
            user: this.user,
          })
        );

        // REMOVE CREDIT FROM COMPANY

        const [original] = await DataStore.query(Company, (c) =>
          c.name.eq(this.userCompany)
        );

        await DataStore.save(
          Company.copyOf(original, (updated) => {
            updated.credit -= this.booking.price;
          })
        );
      } else {
        // ERROR NOT ENOUGH CREDIT
        alert(
          "You dont have enough credits to book this meeting, please get in touch with Workdistrict marketing team"
        );
        return;
      }

      this.awaitHelper = true;
      this.myCompanyBooking();
      this.goBack();
      this.bookingModalHandler();
      this.updateCreditBalance();
      this.calendarDisplay();
      this.selected = [];
      alert("Congratulations, your meeting room has been reserved");
    },

    reduceDate() {
      this.selectedDate = new Date().toISOString().substr(0, 10);
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////       SLOT             ///////////////////////////////////////////////////////

    creatingSlot() {
      if (this.adminPanel.key !== "codecode") {
        alert(
          "You need a valid Admin Key to interact with the Admin Panel, please contact WorkDistrict Marketing Team."
        );
        return;
      }

      let i = 0;
      while (i < 40) {
        this.createSlot(
          this.slotHelper[i],
          i + 1,
          this.slotHelperStart[i],
          this.slotHelperEnd[i]
        );
        i++;
      }
    },

    async createSlot(name, ids, start, end) {
      await DataStore.save(
        new Slot({
          name: name,
          ids: ids,
          start: start,
          end: end,
        })
      );
    },

    async readSlot() {
      if (this.adminPanel.key !== "codecode") {
        alert(
          "You need a valid Admin Key to interact with the Admin Panel, please contact WorkDistrict Marketing Team."
        );
        return;
      }

      const read = await DataStore.query(Slot);
      console.log(read);
      return read;
    },

    async deleteSlot() {
      if (this.adminPanel.key !== "codecode") {
        alert(
          "You need a valid Admin Key to interact with the Admin Panel, please contact WorkDistrict Marketing Team."
        );
        return;
      }

      await DataStore.delete(Slot, Predicates.ALL);
    },
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////////        UTILITY LOG            ////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////////        COMPANY            ////////////////////////////////////////////////

    async readCompany() {
      if (this.adminPanel.key !== "codecode") {
        alert(
          "You need a valid Admin Key to interact with the Admin Panel, please contact WorkDistrict Marketing Team."
        );
        return;
      }

      const read = await DataStore.query(Company);
      console.log(read);
      return read;
    },

    async deleteCompany() {
      if (this.adminPanel.key !== "codecode") {
        alert(
          "You need a valid Admin Key to interact with the Admin Panel, please contact WorkDistrict Marketing Team."
        );
        return;
      }

      await DataStore.delete(Company, Predicates.ALL);
    },

    async setCompany() {
      await DataStore.query(User, (c) => c.username.eq(this.user));
    },

    async createCompany() {
      if (this.adminPanel.key !== "codecode") {
        alert(
          "You need a valid Admin Key to interact with the Admin Panel, please contact WorkDistrict Marketing Team."
        );
        return;
      }

      await DataStore.save(
        new Company({
          name: this.adminPanel.companyName,
          credit: this.adminPanel.companyCredit,
          creditPerMonth: this.adminPanel.companyCreditPerMonth,
        })
      );
    },

    async calendarDisplay() {
      this.calendarJungle.forEach((e, i) => {
        this.calendarJungle[i] = false;
      });

      this.calendarEarth.forEach((e, i) => {
        this.calendarEarth[i] = false;
      });

      this.calendarSpace.forEach((e, i) => {
        this.calendarSpace[i] = false;
      });

      this.calendarWater.forEach((e, i) => {
        this.calendarWater[i] = false;
      });

      const read = await DataStore.query(Booking, (c) => {
        return c.date.eq(this.selectedDateBis);
      });

      read.forEach((e) => {
        if (e.room == "Underwater") {
          e.slots.forEach((e) => {
            this.calendarWater[e] = true;
          });
        } else if (e.room == "In the Jungle") {
          e.slots.forEach((e) => {
            this.calendarJungle[e] = true;
          });
        } else if (e.room == "On Earth") {
          e.slots.forEach((e) => {
            this.calendarEarth[e] = true;
          });
        } else if (e.room == "In Space") {
          e.slots.forEach((e) => {
            this.calendarSpace[e] = true;
          });
        }
      });
    },

    async masterFunc() {
      // RESET INITIAL VALUE
      this.booked.isBooked = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ];

      // QUERY ALL BOOKING FOR THE SELECTED DATE
      const masterData = await DataStore.query(Booking, (c) => {
        return c.date.eq(this.selectedDate);
      });

      this.masterData = masterData;

      // REMOVE ALL BOOKING WHEN THE ROOM DOESNT MATCH THE ACTIVE ROOM
      for (let i = 0; i < this.masterData.length; i++) {
        const element = this.masterData[i];
        if (element.room !== this.roomActive) {
          this.masterData.splice(i, 1);
          i--;
        }
      }

      let temp = [];
      if (this.masterData.length > 0) {
        for (let i = 0; i < this.masterData.length; i++) {
          temp.push(this.masterData[i].slots);
        }
      }

      let test = [];

      for (let key in temp) {
        for (let i = 0; i < temp[key].length; i++) {
          test.push(temp[key][i]);
        }
      }

      let y = 0;
      let final = [];
      while (y < 99) {
        if (typeof test[y] == undefined) {
          break;
        }
        final.push(test[y]);
        y++;
      }

      let finalTest = [];
      let isBooked = this.booked.isBooked;

      test.forEach((e) => {
        finalTest.push(e);
      });

      finalTest.forEach(function (e) {
        isBooked[e] = true;
      });
    },

    async sync() {
      await DataStore.start();

      DataStore.configure({
        models: {
          Room,
          Company,
          Booking,
          Slot,
          User,
        },
      });
    },
  },

  watch: {
    selectedDate() {
      this.selected = [];
      const spanElements = document.querySelectorAll("span");
      spanElements.forEach((element) => {
        element.classList.remove("selected");
      });

      this.masterFunc();
    },
  },

  mounted() {
    document.body.style.backgroundColor = "var(--color-bg)";
    document.body.style.overflowX = "hidden";

    Auth.signOut();

    this.sync();
    this.loginMaster();

    Hub.listen("auth", (data) => {
      const { payload } = data;
      if (payload.event === "signIn") {
        setTimeout(this.loginMaster, 400);
      }
      this.loginMaster();
      this.calendarDisplay();
      this.managerPanelFunc();
    });
  },

  updated() {
    this.myCompanyBooking();
  },
};
</script>

<style>
.hide {
  display: none;
}

.auth-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.temp {
  padding: 2rem 8rem 6rem 8rem;
  background-color: var(--color-bg);
  width: 100%;
  height: auto;
}

.booking__filter-helper {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.56);
  width: 100%;
  height: 100%;
}

.box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.main__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: absolute;
  width: 100%;
  top: 12rem;
  z-index: 2;
}

.grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 1rem;
}

.grid__item {
  background-color: transparent;
  color: white;
  width: 8rem;
  height: 5rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid white;
}

.grid__item:hover {
  background-color: rgba(200, 205, 209, 0.588);
}

.selected {
  background-color: var(--color-magenta);
}

.selected:hover {
  background-color: var(--color-magenta);
}

.grid__btn {
  cursor: pointer;
  font-size: 2rem;
  background-color: var(--color-magenta);
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 4px;
  transition: all 0.3s;
  transform: translateY(3.2rem);
}

.grid__btn:hover {
  transform: scale(0.96) translateY(3.2rem);
  background-color: var(--color-magenta-light);
}

.grid__date {
  width: 30rem;
  height: 4rem;
  padding: 0.6rem 1.2rem;
  border: 2px solid white;
  border-radius: 4px;
  margin-top: 1rem;
}

.booked {
  background-color: red !important;
  cursor: not-allowed !important;
}

.bookedBis {
  background-color: var(--color-magenta) !important;
}

/* UI BOOKING HERO */

.booking__hero {
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 4rem;
  margin-bottom: 7.8rem;
  justify-content: space-between;
}

/* LEFT */
.booking__hero-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.booking__welcome {
  display: flex;
  align-items: center;
  align-self: flex-start;
  flex-direction: column;
  margin-bottom: 6rem;
  margin-top: 8rem;
}

.booking__credit {
  align-self: flex-start;
  padding-left: 2rem;
  font-size: 1.2rem;
  font-style: italic;
  margin-top: 1.4rem;
}

.booking__h1 {
  font-size: 5rem;
  line-height: 1;
}

.booking__h3 {
  font-size: 2.6rem;
  margin-bottom: 4rem;
}

.booking__next-meeting {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.booking__next-item {
  display: flex;
  align-items: center;
  background-color: rgba(37, 36, 36, 0.853);
  padding: 2.6rem 1rem 2.6rem 1.6rem;
  width: 100%;
  border-left: 8px solid var(--color-magenta);
  border-radius: 6px;
  gap: 4rem;
  cursor: pointer;
}

.booking__next-img {
  width: 38rem;
  height: 27rem;
  width: 50%;
  height: auto;
  border-radius: 6px;
}

.booking__next-content {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-self: flex-start;
}

.booking__next-company {
  text-transform: uppercase;
  font-size: 1.1rem;
  margin-top: 2.4rem;
  letter-spacing: 2px;
  font-weight: 500;
}

.booking__next-room {
  font-size: 2.5rem;
  margin-bottom: 1.4rem;
}

.booking__next-date {
  font-size: 1.4rem;
}

.booking__next-start {
  font-size: 1.4rem;
}

.booking__next-end {
  font-size: 1.4rem;
}

.booking__next-duration {
  font-size: 1.4rem;
}

.booking__next-location {
  font-size: 1.4rem;
}

/* RIGHT */

.booking__hero-right {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  width: 50%;
}

.booking__all-meeting {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.booking__all-content {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
}

.booking__all-tags {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding-left: 2rem;
  text-align: center;
}

.booking__all-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 10rem;
  text-transform: uppercase;
  font-size: 1rem;
}

.booking__all-items {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background-color: rgba(37, 36, 36, 0.853);
  border-radius: 4px;
  border-left: 4px solid var(--color-magenta);
  padding: 1rem;
  padding-left: 2rem;
  width: 100%;
  height: 6rem;
  cursor: pointer;
}

.booking__all-item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-size: 1.2rem;
}

/* UI BOOKING HERO */

.booking__book {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.booking__title {
  font-size: 5rem;
  align-self: center;
  margin-bottom: 4rem;
  line-height: 1;
}

.booking__meeting-title {
  position: absolute;
  bottom: 0.4rem;
  left: 1rem;
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: 500;
}

.booking__meeting-items {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
}

.booking__meeting-item {
  width: 25%;
  height: 30rem;
  border: 2px solid white;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
  align-items: center;
  background-size: cover;
  backdrop-filter: brightness(0.5);
  background-position: center;
}

.booking__meeting-item1 {
  background-image: url("../assets/images/office/workdistrict-meeting-room-earth-mid.webp");
}

.booking__meeting-item2 {
  background-image: url("../assets/images/office/workdistrict-meeting-room-water-mid.webp");
}

.booking__meeting-item3 {
  background-image: url("../assets/images/office/workdistrict-coworking-space-room-mid.webp");
}

.booking__meeting-item4 {
  background-image: url("../assets/images/office/workdistrict-meeting-room-jungle-mid.webp");
}

.booking__meeting-active1 {
  background-image: url("../assets/images/office/workdistrict-meeting-room-earth-big.webp");
}

.booking__meeting-active2 {
  background-image: url("../assets/images/office/workdistrict-meeting-room-water-big.webp");
}

.booking__meeting-active3 {
  background-image: url("../assets/images/office/workdistrict-coworking-space-room-big.webp");
}

.booking__meeting-active4 {
  background-image: url("../assets/images/office/workdistrict-meeting-room-jungle-big.webp");
}

.booking__filter {
  backdrop-filter: brightness(0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.booking__arrow {
  transform: rotate(180deg);
  align-self: flex-start;
  font-size: 5rem;
  cursor: pointer;
  position: absolute;
  top: 5rem;
  left: 5rem;
}

/* MANAGER PANEL */

.manager__panel {
  margin-top: 12rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.4rem;
}

.manager__title,
.admin__title {
  font-size: 5rem;
}

.manager__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgba(37, 36, 36, 0.853);
  border-radius: 6px;
  height: auto;
  padding-top: 6rem;
  padding-bottom: 3.4rem;
  font-size: 2.2rem;
  gap: 6rem;
  position: relative;
}

.manager__items-box {
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.manager__tags,
.manager__items {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 4rem;
}

.manager__tag,
.manager__item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.manager__item select {
  width: 12rem;
}

.manager__tag-info {
  background-color: white;
  color: black;
  padding: 0.1rem 1.2rem;
  border-radius: 1000px;
  font-weight: 600;
  margin-left: 0.4rem;
}

.manager__tag-box {
  cursor: pointer;
}

.manager__tag-helper {
  position: absolute;
  border: 2px solid white;
  border-radius: 6px;
  top: 4rem;
  right: 4rem;
  display: none;
  background-color: var(--color-bg);
  text-align: left;
}

/* HEEERE */
.manager__tag-box:hover .manager__tag-helper {
  display: inline;
}

.role__helper {
  position: absolute;
  top: 8rem;
  right: 10%;
  width: 30%;
  border: 1px solid white;
  border-radius: 4px;
  background-color: var(--color-bg);
  padding: 1rem 3rem;
  font-size: 2rem;
  display: none;
}

.manager__item-btn {
  background-color: var(--color-magenta);
  padding: 1.4rem 2.8rem;
  text-decoration: none;
  border: none;
  cursor: pointer;
  color: white;
  border-radius: 4px;
  transition: 0.3s;
  font-size: 1.6rem;
}

.manager__item-btn:hover {
  background-color: var(--color-magenta-light);
  transform: scale(0.96);
}

.manager__item-employee,
.manager__tag-employee,
.manager__tag-current,
.manager__tag-new {
  line-height: 1;
}

/* ADMIN PANEL */

.admin__btn-helper {
  margin-bottom: 4rem !important;
}

.admin__panel {
  margin-top: 12rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.4rem;
}

.admin__content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(7, 1fr);
  flex-direction: column;
  width: 100%;
  border-radius: 6px;
  height: 80rem;
  padding-top: 6rem;
  font-size: 2.2rem;
  gap: 4rem;
  position: relative;
}

.admin__content {
  height: 80rem;
}

.admin__grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid white;
  background-color: rgba(37, 36, 36, 0.853);
}

.admin__grid-room {
  grid-area: 1 / 1 / 5 / 2;
  gap: 1.5rem;
}

.admin__grid-booking {
  grid-area: 5 / 1 / 8 / 2;
  gap: 1.5rem;
}

.admin__grid-company {
  grid-area: 1 / 2 / 8 / 3;
}

.admin__grid-user {
  grid-area: 1 / 3 / 8 / 4;
}

.admin__grid-credit {
  grid-area: 1 / 4 / 3 / 5;
}

.admin__grid-key {
  grid-area: 6 / 4 / 8 / 5;
}

.admin__grid-slot {
  grid-area: 4 / 4 / 5 / 5;
}

.admin__grid-item > button {
  background-color: var(--color-magenta);
  border: none;
  padding: 0.6rem 1.2rem;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 1.2rem;
}

.admin__grid-item > button:hover {
  background-color: var(--color-magenta-light);
}

.admin__grid-item > input {
  margin-bottom: 2rem;
}

.admin__grid-item > span {
  margin-bottom: 2rem;
  font-weight: 600;
}

.animate-grow {
  animation: grow 0.4s ease-out;
  width: 80%;
  height: 60rem;
}

.animate-grow-mobile {
  animation: grow-mobile 0.4s ease-out;
  width: 100%;
  height: 60rem;
}

@keyframes grow {
  from {
    width: 15%;
    height: 30rem;
  }

  to {
    width: 80%;
    height: 60rem;
  }
}

@keyframes grow-mobile {
  from {
    width: 15%;
    height: 30rem;
  }

  to {
    width: 100%;
    height: 60rem;
  }
}

#scrollHere {
  color: transparent;
}

.booking__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-bg);
  border-radius: 6px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.modal__title {
  font-size: 4rem;
  margin-bottom: 0.8rem;
}

.modal__close-box {
  position: absolute;
  right: 4rem;
  top: 2rem;
  border: 3px solid white;
  border-radius: 50px;
  padding: 3.1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.modal__close {
  font-size: 5rem;
  line-height: 0;
  transform: translateY(-0.4rem);
  font-weight: 500;
}

.modal__review {
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 3rem;
  padding-left: 3rem;
  width: 40%;
  gap: 2.6rem;
  border-left: 8px solid var(--color-magenta);
  background-color: rgba(37, 36, 36, 0.853);
}

.modal__img {
  width: 35rem;
  border-radius: 6px;
}

.modal__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.3rem;
  font-size: 1.5rem;
}

.modal__company {
  text-transform: uppercase;
  font-size: 1.6rem;
  letter-spacing: 1px;
}

.modal__startend-box {
  display: flex;
  justify-content: flex-start;
  gap: 4rem;
}

.modal__price-box {
  display: flex;
  justify-content: flex-start;
  gap: 4rem;
}

.modal__confirm {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6rem;
}

.modal__yes-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-magenta);
  padding: 1.5rem 3rem;
  border-radius: 4px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 2.4rem;
  transition: all 0.3s;
}

.modal__yes-box:hover {
  background-color: var(--color-magenta-light);
  transform: scale(0.96);
}

.modal__no-box {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 1.6rem;
  transition: all 0.3s;
}

.modal__no-box:hover {
  text-decoration: underline;
}

.modal__room {
  font-size: 2.4rem;
  margin-bottom: 1.2rem;
}

/* ANCHOR */
.calendar__overview {
  width: 100%;
  height: auto;
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-collapse: collapse;
}

.calendar__overview-title {
  font-size: 5rem;
  font-weight: 400;
  margin-bottom: 2.4rem;
}

.calendar__overview-date {
  margin-bottom: 3rem;
  font-size: 1rem;
  height: 4rem;
  width: 30rem;
  padding: 0.6rem 1.2rem;
  border: none;
  border-radius: 4px;
}

.calendar__overview-room {
  font-size: 2.4rem;
  padding: 1rem;
}

.calendar__overview-grid {
  display: flex;
  width: 100%;
  background-color: rgba(37, 36, 36, 0.853);
  border-collapse: collapse;
}

.calendar__overview-item {
  border: 1px solid white;
  border-collapse: collapse;
  height: 100%;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-collapse: collapse;
}

.calendar__overview-col {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  width: 100%;
  border-collapse: collapse;
}

.calendar__cell {
  background-color: rgba(0, 128, 0, 0);
  width: 100%;
  height: 3rem;
}

.calendar__overview-slot {
  width: 12rem;
  padding: 0.8rem 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

[data-amplify-authenticator] {
  --amplify-components-button-primary-background-color: var(--color-magenta);
  --amplify-components-button-primary-hover-background-color: var(
    --color-magenta-light
  );
  --amplify-components-button-primary-focus-background-color: var(
    --color-magenta
  );
  --amplify-components-button-primary-active-background-color: var(
    --color-magenta
  );
  --amplify-components-button-link-color: var(--color-bg);
  --amplify-components-button-link-hover-color: var(--color-magenta-light);
  --amplify-components-button-link-focus-color: var(--color-magenta);
  --amplify-components-button-link-active-color: var(---color-magenta);
  --amplify-components-button-link-active-background-color: transparent;
  --amplify-components-button-link-focus-background-color: transparent;
  --amplify-components-button-link-hover-background-color: transparent;

  --amplify-components-authenticator-container-width-max: 40rem;
}

@media (max-width: 800) {
  [data-amplify-authenticator] {
    --amplify-components-authenticator-container-width-max: 20rem;
  }
}

@media (max-width: 1800px) {
  .modal__review {
    width: 50%;
  }
}

@media (max-width: 1620px) {
  .booking__all-tag {
    width: 8rem;
  }
}

@media (max-width: 1500px) {
  .modal__review {
    width: 60%;
  }
}

@media (max-width: 1380px) {
  .booking__all-tag {
    width: 7rem;
  }

  .booking__next-item {
    gap: 2.2rem;
  }

  .booking__next-content {
    gap: 0.5rem;
  }

  .booking__next-room {
    font-size: 2.2rem;
    margin-bottom: 0.6rem;
  }

  .booking__next-date {
    font-size: 1.3rem;
  }

  .booking__next-start {
    font-size: 1.3rem;
  }

  .booking__next-end {
    font-size: 1.3rem;
  }

  .booking__next-duration {
    font-size: 1.3rem;
  }

  .booking__next-location {
    font-size: 1.3rem;
  }
}

@media (max-width: 1250px) {
  .booking__all-tag {
    width: 6.4rem;
  }

  .booking__all-tag {
    font-size: 0.8rem;
  }

  .booking__next-item {
    gap: 1.6rem;
  }

  .booking__next-content {
    gap: 0.4rem;
  }

  .modal__review {
    width: 70%;
  }

  .booking__next-room {
    font-size: 2rem;
    margin-bottom: 0.4rem;
  }

  .booking__next-date {
    font-size: 1.2rem;
  }

  .booking__next-start {
    font-size: 1.2rem;
  }

  .booking__next-end {
    font-size: 1.2rem;
  }

  .booking__next-duration {
    font-size: 1.2rem;
  }

  .booking__next-location {
    font-size: 1.2rem;
  }
}

@media (max-width: 1170px) {
  .booking__welcome {
    text-align: center;
  }

  .booking__hero {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 6rem;
  }

  .booking__hero-right {
    align-self: center;
    justify-self: center;
    width: 100%;
  }

  .booking__all-tag-box,
  .booking__all-item {
    width: 8rem;
  }

  .booking__next-item {
    margin-bottom: 6rem;
    width: 50rem;
  }

  .h3_util {
    margin-bottom: 1.9rem;
    margin-top: 2rem;
  }

  .booking__title {
    font-size: 4rem;
    line-height: 1.1;
    text-align: center;
    padding-left: 7rem;
    padding-right: 7rem;
    margin-bottom: 3rem;
  }

  .booking__hero-left {
    width: 100%;
    margin-top: 6rem;
  }

  .booking__meeting-items {
    flex-direction: column;
    gap: 3.1rem;
    align-items: center;
    height: 80rem;
  }

  .mobile__helper-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 3rem;
    justify-content: center;
  }

  .booking__meeting-item {
    width: 100%;
    height: 35rem;
  }

  .booking__meeting-active1,
  .booking__meeting-active2,
  .booking__meeting-active3,
  .booking__meeting-active4 {
    height: 70rem !important;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(8, 1fr);
    gap: 2.2rem;
    justify-content: space-around;
    align-items: center;
    margin-top: 2.2rem;
  }

  .grid-item {
    width: min-content;
  }

  .grid__btn {
    margin-top: 1.4rem;
    transform: translateY(-0.4rem);
  }

  .grid__btn:hover {
    transform: scale(0.96) translateY(-0.4rem);
  }

  .booking__arrow {
    left: 0;
  }

  .grid__date {
    transform: translateY(-2.8rem);
  }

  .booking__meeting-title {
    font-size: 2.6rem;
  }
}

@media (max-width: 1000px) {
  .modal__review {
    width: 80%;
  }
}

@media (max-width: 850px) {
  .modal__review {
    width: 90%;
  }
}

@media (max-width: 770px) {
  .booking__meeting-active1,
  .booking__meeting-active2,
  .booking__meeting-active3,
  .booking__meeting-active4 {
    display: none;
  }

  .calendar__overview-room {
    font-size: 1.4rem;
  }

  .calendar__overview-col {
    position: relative;
  }

  .calendar__overview-slot {
    position: absolute;
    transform: translate(0, 0);
    background-color: transparent;
    left: 0;
    top: 0;
    z-index: 2;
  }
}

@media (max-width: 720px) {
  .grid__date {
    transform: translateY(1.6rem);
  }

  .grid {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(10, 1fr);
    gap: 1.2rem;
  }

  .modal__review {
    width: 95%;
  }

  .modal__title {
    font-size: 3rem;
  }

  .role__helper-box {
    display: none;
  }

  .manager__panel {
    width: 100%;
  }

  .manager__content {
    width: 67rem !important;
  }
}

@media (min-width: 720px) {
  .role__helper-box-mobile {
    display: none;
  }
}

@media (max-width: 680px) {
  .manager__content {
    width: 63rem !important;
  }
}

@media (max-width: 642px) {
  .booking__title {
    padding-left: 0rem;
    padding-right: 0rem;
    font-size: 2.6rem;
    width: 100%;
  }

  .manager__title {
    font-size: 2.6rem;
    line-height: 1;
  }

  .booking__h1 {
    padding-left: 0;
    padding-right: 0;
    font-size: 4rem;
  }

  .booking__all-tag-box,
  .booking__all-item {
    width: 7rem;
    font-size: 1rem;
  }

  .mobile__helper-row {
    flex-direction: column;
  }

  .booking__meeting-items {
    transform: translateY(35rem);
  }

  .booking__meeting-item {
    width: 120%;
    transform: translateX(-8%);
  }

  .modal__review {
    flex-direction: column;
    width: 75%;
  }

  .modal__yes-box {
    font-size: 1.8;
    padding: 1rem 2rem;
  }

  .modal__no-box {
    font-size: 1.3rem;
  }

  .modal__confirm {
    gap: 2rem;
  }

  .modal__close-box {
    display: none;
  }

  .booking__modal {
    gap: 3.2rem;
  }

  .manager__panel {
    transform: translateY(65rem);
    z-index: -1;
  }

  .admin__panel {
    display: none;
  }

  .calendar__overview {
    transform: translateY(65rem);
  }

  .calendar__overview-title {
    font-size: 2.6rem;
    line-height: 0.9;
  }

  .calendar__overview-grid {
    width: 54rem !important;
  }

  .manager__content {
    width: 57rem !important;
  }
}

@media (max-width: 580px) {
  .tag__helper {
    display: none;
  }

  .modal__title {
    width: 80%;
    font-size: 2.6rem;
    text-align: center;
    line-height: 1.1;
  }

  .calendar__overview-grid {
    width: 52rem !important;
  }

  .manager__content {
    width: 52rem !important;
  }

  .manager__item-employee,
  .manager__item-current {
    font-size: 1.8rem;
  }

  .manager__tag-employee,
  .manager__tag-current,
  .manager__tag-new {
    font-size: 2rem;
  }

  .manager__item select {
    width: 8rem;
    font-size: 1.4rem;
  }

  .manager__item-btn {
    font-size: 1.4rem;
    padding: 0.8rem 1.6rem;
  }
}

@media (max-width: 542px) {
  .booking__credit {
    margin-top: 2.4rem;
  }

  .booking__welcome {
    width: 100%;
  }

  .booking__h1 {
    font-size: 3.6rem;
  }

  .booking__next-img {
    width: 18rem;
  }

  .booking__next-item {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    padding-right: 0.6rem;
    width: 42rem;
    height: 20rem;
  }

  .booking__next-room {
    font-size: 1.6rem;
    margin-bottom: 0.4rem;
  }

  .booking__next-date {
    font-size: 0.9rem;
  }

  .booking__next-start {
    font-size: 0.9rem;
  }

  .booking__next-end {
    font-size: 0.9rem;
  }

  .booking__next-duration {
    font-size: 0.9rem;
  }

  .booking__next-location {
    font-size: 0.9rem;
  }

  .calendar__overview-grid {
    width: 48rem !important;
  }

  .manager__content {
    width: 47rem !important;
  }
}

@media (max-width: 480px) {
  .booking__all-tag-box,
  .booking__all-item {
    width: 5rem;
    font-size: 0.8rem;
  }

  .booking__all-tag-box,
  .booking__all-item {
    width: 5.2rem;
    font-size: 0.9rem;
  }

  .calendar__overview-grid {
    width: 43rem !important;
  }

  .calendar__overview-slot {
    font-size: 0.9rem;
    padding-left: 0;
    padding-right: 0;
  }

  .calendar__overview-room {
    font-size: 1.2rem;
    padding: 0.2rem;
  }

  .manager__content {
    width: 43rem !important;
  }

  .manager__item-employee,
  .manager__item-current {
    font-size: 1.6rem;
  }

  .manager__tag-employee,
  .manager__tag-current,
  .manager__tag-new {
    font-size: 1.8rem;
  }

  .manager__item select {
    width: 7rem;
    font-size: 1.2rem;
  }

  .manager__item-btn {
    font-size: 1.2rem;
    padding: 0.6rem 1.3rem;
  }
}

@media (max-width: 440px) {
  .booking__next-item {
    width: 40rem;
  }

  .calendar__overview-grid {
    width: 40rem !important;
  }

  .calendar__overview-slot {
    left: -1rem;
  }

  .manager__content {
    width: 40rem !important;
    gap: 2.6rem;
  }

  .manager__tag-employee,
  .manager__tag-current,
  .manager__tag-new {
    font-size: 1.6rem;
  }

  .manager__item-employee,
  .manager__item-current {
    font-size: 1.4rem;
  }

  .manager__items {
    gap: 3rem;
  }

  .manager__item select {
    width: 6rem;
    font-size: 1rem;
  }

  .manager__tag-box {
    font-size: 1.8rem;
  }

  .manager__tag-info {
    font-size: 1.8rem;
    padding: 0 0.9rem;
  }
}

@media (max-width: 410px) {
  .booking__next-item {
    width: 38rem;
  }

  .calendar__overview-grid {
    width: 38rem !important;
  }

  .manager__content {
    width: 38rem !important;
    gap: 2.4rem;
  }

  .manager__items {
    gap: 2rem;
  }
}

@media (max-width: 390px) {
  .booking__title {
    font-size: 2.2rem;
  }

  .booking__all-tag-box,
  .booking__all-item {
    width: 5rem;
    font-size: 0.8rem;
  }

  .booking__next-item {
    width: 36rem;
  }

  .calendar__overview-grid {
    width: 34rem !important;
  }

  .calendar__overview-slot {
    left: -2rem;
  }

  .manager__content {
    width: 35rem !important;
    gap: 2.2rem;
  }

  .calendar__overview-title {
    text-align: center;
  }

  .manager__items {
    gap: 1rem;
  }
}

input:focus {
  outline: none !important;
}
</style>
